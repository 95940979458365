import { combineReducers } from "@reduxjs/toolkit";
import { guardReducer } from "../../utils/redux";
import { Action } from "./constants";
import { IReduxState, IAction } from "./reducers.interfaces";
import { defaults } from "./defaults";

const uiReducer = guardReducer(
    Action,
    defaults.ui,
    (state = defaults.ui, action: IAction): IReduxState["ui"] => {
        switch (action.type) {
            case Action.NAVIGATE:
                return {
                    ...state,
                    currentScreen: action.payload.currentScreen,
                };
            case Action.SELECT_SIZE:
                return {
                    ...state,
                    selectedSize: action.payload.selectedSize,
                };

            case Action.SELECT_HEIGHT:
                return {
                    ...state,
                    selectedHeight: action.payload.selectedHeight,
                };
            case Action.SELECT_WEIGHT:
                return {
                    ...state,
                    selectedWeight: action.payload.selectedWeight,
                };
            case Action.SELECT_OPTION:
                return {
                    ...state,
                    [`selected${action.payload.optionType}`]:
                        action.payload.selectedValue,
                };
            case Action.LOADED_RESULTS:
                return {
                    ...state,
                    loadedResults: action.payload.loadedResults,
                };
        }
        return state;
    },
);

export const reducers = combineReducers({
    ui: uiReducer,
});

import React from "react";
import { IReviewsProductTypeID } from "../../../models/nominals";
import { ISearchFacet } from "../../../models/reviews.interfaces";
import { ProductTypeFilterSection } from "./ProductTypeFilterSection";
import { SourceFilterSection } from "./SourceFilterSection";
import { TextFilter } from "./TextFilter";
import { RatingFilter } from "./RatingFilter";

export interface IProps {
    showProducts: boolean;
    showSources: boolean;
    displayedProductTypeIDWhitelist: IReviewsProductTypeID[];
    maxKeywordsNumber?: number;
    starHasStroke?: boolean;
    isCarouselComponentType?: boolean;
    isCollapsedView?: boolean;
    isCondensedView?: boolean;
}

export const Filter = (props: { facet: ISearchFacet } & IProps) => {
    switch (props.facet.type) {
        case "text":
            return (
                <TextFilter
                    placeholder={gettext("SEARCH KEYWORDS")}
                    maxKeywordsNumber={props.maxKeywordsNumber}
                />
            );

        case "rating":
            if (props.isCarouselComponentType) {
                return null;
            }
            return <RatingFilter starHasStroke={props.starHasStroke} />;

        case "product_id":
            if (!props.showProducts) {
                return null;
            }
            return (
                <ProductTypeFilterSection
                    displayedProductTypeIDWhitelist={
                        props.displayedProductTypeIDWhitelist
                    }
                    isCarouselComponentType={props.isCarouselComponentType}
                    isCollapsedView={props.isCollapsedView}
                    isCondensedView={props.isCondensedView}
                />
            );

        case "source_id":
            if (!props.showSources) {
                return null;
            }
            return <SourceFilterSection />;
    }

    return null;
};

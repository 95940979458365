import React from "react";
import { connect } from "react-redux";
import { TStateMapper } from "../../reducers.interfaces";
import { defaults } from "../defaults";
import { IProduct } from "../../../models/catalogue.interfaces";
import { MasonryGrid } from "../../../common/MasonryGrid";
import { SortDirection } from "../models";
import { GridFilterConfig } from "../filters";
import { GridSorterConfig, PriceGridSorter } from "../sorters";
import { IReduxState } from "../reducers.interfaces";
import { IProductCardType } from "../models.interfaces";
import { ProductCard } from "./ProductCard";
import { SOProductCard } from "./SOProductCard";

import styles from "./GridContent.module.scss";

interface IOwnProps {
    gridVariant: string;
    cardType: IProductCardType;
    filters: GridFilterConfig[];
    sorters?: GridSorterConfig[];
    disableHoverOverlay?: boolean;
    buildExternalCallout?: (rootProduct: IProduct) => React.ReactNode;
}

interface IReduxProps {
    products: IProduct[];
    filterState: IReduxState["filters"];
    sortMethod: string | null;
}

interface IProps extends IOwnProps, IReduxProps {}

interface IState {}

export class GridContentComponent extends React.PureComponent<IProps, IState> {
    private getSorter(): GridSorterConfig {
        let sorter: GridSorterConfig | undefined;
        if (this.props.sorters) {
            sorter = this.props.sorters.find(
                (s) => s.id === this.props.sortMethod,
            );
        }
        if (!sorter && this.props.sorters) {
            sorter = this.props.sorters[0];
        }
        if (!sorter) {
            sorter = new PriceGridSorter({
                id: "price-low-to-high",
                label: "Price Low to High",
                direction: SortDirection.ASC,
            });
        }
        return sorter;
    }

    private getFilteredProducts(): IProduct[] {
        const filteredProducts = Array.from(
            this.props.filters.reduce<Iterable<IProduct>>(
                (products, nextFilter) => {
                    const selectedValues = new Set(
                        this.props.filterState[
                            nextFilter.filterID
                        ]?.selectedValues,
                    );
                    return nextFilter.applyFilter(selectedValues, products);
                },
                this.props.products,
            ),
        );
        const sorter = this.getSorter();
        return sorter.sort(filteredProducts);
    }

    render() {
        const products = this.getFilteredProducts();
        return (
            <>
                {this.props.gridVariant === "sleep-outfitters" && (
                    <header>
                        <div className={styles.resultsLabel}>
                            {products.length}{" "}
                            {products.length === 1 ? "result" : "results"}
                        </div>
                        {this.props.filterState.brand &&
                            this.props.filterState.brand.selectedValues.length >
                                0 && (
                                <h2 className={styles.brandTitle}>
                                    {this.props.filterState.brand.selectedValues.join(
                                        ", ",
                                    )}
                                </h2>
                            )}
                    </header>
                )}
                <MasonryGrid
                    className={styles.container}
                    itemSelector={".product-grid-card"}
                    entities={products}
                    getTileID={(product) => `${product.id}`}
                    buildTile={(product, ref) =>
                        this.props.gridVariant === "sleep-outfitters" ? (
                            <SOProductCard
                                forwardedRef={ref}
                                key={`${product.id}`}
                                gridVariant={this.props.gridVariant}
                                cardType={this.props.cardType}
                                rootProduct={product}
                                buildExternalCallout={
                                    this.props.buildExternalCallout
                                }
                            />
                        ) : (
                            <ProductCard
                                forwardedRef={ref}
                                key={`${product.id}`}
                                gridVariant={this.props.gridVariant}
                                cardType={this.props.cardType}
                                rootProduct={product}
                                disableHoverOverlay={
                                    this.props.disableHoverOverlay
                                }
                                buildExternalCallout={
                                    this.props.buildExternalCallout
                                }
                            />
                        )
                    }
                    emptyState={
                        <div>
                            <p className={styles.emptyMessageHeading}>
                                {gettext(
                                    "WE CAN’T FIND A PRODUCT THAT MATCHES YOUR SEARCH.",
                                )}
                            </p>
                            <p className={styles.emptyMessageBody}>
                                {gettext(
                                    "Sorry about that! Please try your search again with another set of filters.",
                                )}
                            </p>
                        </div>
                    }
                    options={{
                        horizontalOrder: true,
                        gutter:
                            this.props.gridVariant === "sleep-outfitters"
                                ? 22
                                : 0,
                    }}
                    forceEqualHeight={true}
                />
            </>
        );
    }
}

const mapStateToProps: TStateMapper<"productgrid2", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.productgrid2 || defaults;
    return {
        ...ownProps,
        products: state.products,
        filterState: state.filters,
        sortMethod: state.sortMethod,
    };
};

export const GridContent = connect(mapStateToProps)(GridContentComponent);

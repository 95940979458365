import * as t from "io-ts";

export const FinanceValuePropIcon = t.interface({
    id: t.number,
    title: t.string,
    url: t.string,
});

export const FinanceValueProp = t.interface({
    title: t.string,
    subtitle: t.string,
    icon: FinanceValuePropIcon,
});

export const FinanceValuePropOrderable = t.interface({
    prop: FinanceValueProp,
});

export const FinanceValuePropOrderables = t.array(FinanceValuePropOrderable);

import React from "react";

interface IDimensions {
    width: number;
    height: number;
}

interface IBodyResizeEvent {
    type: "width" | "height" | "both";
    prevSize: IDimensions;
    newSize: IDimensions;
}

export type OnResizeHandler = (event: IBodyResizeEvent) => void;

interface IProps {
    onResize: OnResizeHandler;
}

interface IState {
    prevSize: IDimensions;
}

export class WindowResizeSensor extends React.Component<IProps, IState> {
    state: IState = {
        prevSize: {
            width: document.body.offsetWidth,
            height: document.body.offsetHeight,
        },
    };

    private readonly trackResize = (): void => {
        const newSize: IDimensions = {
            width: document.body.offsetWidth,
            height: document.body.offsetHeight,
        };
        const changeType = this.getChangeType(this.state.prevSize, newSize);
        if (changeType) {
            this.props.onResize({
                type: changeType,
                prevSize: this.state.prevSize,
                newSize: newSize,
            });
        }
        this.setState({
            prevSize: newSize,
        });
    };

    componentDidMount() {
        window.addEventListener("resize", this.trackResize);
        this.trackResize();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.trackResize);
    }

    private getChangeType(
        prevSize: IDimensions,
        newSize: IDimensions,
    ): IBodyResizeEvent["type"] | null {
        const changedWidth = prevSize.width !== newSize.width;
        const changedHeight = prevSize.height !== newSize.height;
        if (changedWidth && changedHeight) {
            return "both";
        }
        if (changedWidth) {
            return "width";
        }
        if (changedHeight) {
            return "height";
        }
        return null;
    }

    render() {
        return null;
    }
}

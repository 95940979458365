import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import SVG from "react-inlinesvg";
import { TStateMapper, TDispatchMapper } from "../../reducers.interfaces";
import { IWriteReviewTemplateField } from "../../../models/reviews.interfaces";
import { IFormUUID } from "../../../models/nominals";
import { FormRadioSelect } from "../../../forms/FormRadioSelect";
import { Dispatchers } from "../dispatchers";
import { getWriteReviewFormState } from "../selectors";
import { WriteReviewFormDescriptionCopy } from "../elements/WriteReviewFormDescriptionCopy";
import iconStar from "../../../../img/icons/star.svg";

interface IOwnProps {
    formUUID: IFormUUID;
    title: string;
    brand?: string;
    name: string;
    field: IWriteReviewTemplateField;
}

interface IReduxProps {
    value: string;
    errors: string[];
    showErrors: boolean;
}

interface IDispatchProps {
    dispatchers: Dispatchers;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {}

class WriteReviewFormStarsFieldComponent extends React.PureComponent<
    IProps,
    IState
> {
    private readonly onChange = (e: React.FormEvent<HTMLInputElement>) => {
        this.props.dispatchers.onReviewFormTextChange({
            formUUID: this.props.formUUID,
            fieldName: this.props.name,
            fieldValue: e.currentTarget.value,
        });
    };

    render() {
        const labels: [number][] = [[1], [2], [3], [4], [5]];
        const choices = labels.map(([rating]) => {
            return {
                value: `${rating}`,
                label: (
                    <SVG
                        className="wrf-rating__star"
                        src={iconStar}
                        id={`star-rating-icon-${rating}`}
                        role="img"
                        aria-label={`Rating of ${rating} star${
                            rating > 1 ? "s" : ""
                        }`}
                    />
                ),
            };
        });
        const containerClasses = classNames({
            "wrf-rating": true,
            [`wrf-rating--${this.props.value}`]: true,
        });
        const label = gettext("What star rating would you give this product?");
        return (
            <div className={containerClasses}>
                <h3 className="wrf-rating__title">{this.props.title}</h3>
                <WriteReviewFormDescriptionCopy brand={this.props.brand} />
                <FormRadioSelect
                    name={this.props.name}
                    id={`${this.props.name}-${this.props.formUUID}`}
                    required={this.props.field.is_required}
                    label={label}
                    legend={label}
                    labelPlacement={"static"}
                    choices={choices}
                    onChange={this.onChange}
                    value={this.props.value}
                    errors={this.props.errors}
                    showErrorMessages={this.props.showErrors}
                />
            </div>
        );
    }
}

const mapStateToProps: TStateMapper<"reviews", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const formState = getWriteReviewFormState(rootState.reviews, ownProps);
    return {
        value: formState?.valuesText[ownProps.name] ?? "",
        errors: formState.errors[ownProps.name] ?? [],
        showErrors: formState.showErrors,
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        dispatchers: dispatchers,
    };
};

export const WriteReviewFormStarsField = connect(
    mapStateToProps,
    mapDispatchToProps,
)(WriteReviewFormStarsFieldComponent);

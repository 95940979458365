import React from "react";

export const FortivaDeniedMessage = () => (
    <>
        <p>
            {gettext(
                "Unfortunately, Fortiva Retail Credit was unable to approve your application for credit at this time.",
            )}
        </p>
        <p>
            {gettext(
                "You will receive written notification of this denial in the mail within 30 days.",
            )}
        </p>
    </>
);

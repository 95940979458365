import React from "react";

interface IProps {}

interface IState {}

export class WriteReviewFormDisclaimers extends React.PureComponent<
    IProps,
    IState
> {
    render() {
        return (
            <div className="write-review-form__disclaimer">
                <h4>{gettext("By submitting, I agree with the following:")}</h4>
                <ul className="write-review-form__disclaimer-list">
                    <li>
                        <a
                            className="write-review-form__disclaimer-link"
                            href="http://www.powerreviews.com/legal/privacy_policy_en_US.html"
                            target="_blank"
                            rel="noreferrer"
                        >
                            {gettext("Privacy Policy")}
                        </a>
                    </li>
                    <li>{gettext("I am age 13 or older")}</li>
                </ul>
            </div>
        );
    }
}

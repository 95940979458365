import React from "react";
import SVG from "react-inlinesvg";
import { IBasketLine } from "../../../models/catalogue.interfaces";
import { getDinero } from "../../../utils/money";
import {
    IBasketLineBaseProps,
    BasketLineImage,
    BasketLineTitle,
    BasketLinePrice,
} from "./BasketLineBase";
import { Actions } from "../actions";

import styles from "./BasketLineMinimal.module.scss";
import iconTrashCan from "../../../../img/icons/trash-can.svg";

interface IProps extends IBasketLineBaseProps<IBasketLine> {
    removeBasketLine: Actions["removeBasketLine"];
    updateBasketLineQuantity: Actions["updateBasketLineQuantity"];
    onRemoveLine: (event?: React.MouseEvent<HTMLElement>) => void;
    onUpdateLineQuantity: (event: React.FormEvent<HTMLSelectElement>) => void;
}

interface IState {}

export class BasketLineMinimalEnhanced extends React.Component<IProps, IState> {
    private buildQuantitySelector() {
        const line = this.props.line;
        if (!line.quantity) {
            return null;
        }
        const maxQty = 10;
        const options = [];
        for (let i = 0; i <= maxQty; i++) {
            options.push(
                <option key={i} value={i}>
                    {i}
                </option>,
            );
        }
        return (
            <div className={styles.qtyContainer}>
                <label className={styles.qtyLabel} htmlFor="product_quantity">
                    {gettext("Qty:")} &nbsp;
                </label>
                <select
                    value={line.quantity}
                    onChange={this.props.onUpdateLineQuantity}
                    id="product_quantity"
                >
                    {options}
                </select>
            </div>
        );
    }

    private buildRemoveButton() {
        return (
            <button
                aria-label={gettext("Remove")}
                onClick={this.props.onRemoveLine}
                className={`${styles.button} al-basket-line__button--remove`}
            >
                <SVG
                    className={styles.icon}
                    src={iconTrashCan}
                    aria-hidden="true"
                />
            </button>
        );
    }

    render() {
        return (
            <li>
                <div className={styles.line} role="group" aria-label="product">
                    <BasketLineImage product={this.props.line.product} />
                    <div className={styles.titleContainer}>
                        <BasketLineTitle
                            product={this.props.line.product}
                            additionalCSSClass={styles.title}
                        />
                        {this.buildQuantitySelector()}
                    </div>
                    <div className={styles.pricingContainer}>
                        {this.props.line.price_excl_tax_excl_discounts && (
                            <BasketLinePrice
                                line={this.props.line}
                                unitPrice={this.props.line.product.price}
                                linePriceInclDiscounts={getDinero(
                                    this.props.line
                                        .line_price_excl_tax_incl_discounts,
                                )}
                                postDiscountAddons={
                                    this.props.line.product.price
                                        .post_discount_addons
                                }
                                quantity={this.props.line.quantity}
                                isMinimal={true}
                            />
                        )}
                        {this.buildRemoveButton()}
                    </div>
                </div>
            </li>
        );
    }
}

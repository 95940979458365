import React from "react";
import { Trans } from "../../../common/Trans";
import { useAppDispatch } from "../../reducers";
import { openApplyModal, closeModal } from "../reducers";
import { strings } from "../../../localization";
import styles from "./index.module.scss";

export const FinancingPreQualDenied = () => {
    const dispatch = useAppDispatch();
    return (
        <div className={styles.root}>
            <h2 className={styles.heading}>{gettext("We’re Sorry.")}</h2>
            <p className={styles.copy}>
                <Trans
                    fmtString={gettext(
                        `We did not find a pre-qualified offer for you at this time.
                        However, you can still apply for the ${strings.get(
                            "FINANCING_CARD_NAME",
                        )} by
                        selecting <B>Continue to Application</B>.`,
                    )}
                    data={{
                        SUP: (content) => <sup key={1}>{content}</sup>,
                        B: (content) => <strong key={2}>{content}</strong>,
                    }}
                />
            </p>
            <div className={styles.buttonContainer}>
                <button
                    className="button"
                    onClick={(e) => {
                        e.preventDefault();
                        dispatch(openApplyModal({}));
                    }}
                >
                    {gettext("Continue to Application")}
                </button>
                <button
                    className="button button--secondary"
                    onClick={(e) => {
                        e.preventDefault();
                        dispatch(closeModal());
                    }}
                >
                    {gettext("No, Thanks")}
                </button>
            </div>
        </div>
    );
};

import React from "react";
import SVG from "react-inlinesvg";
import iconXClose from "../../../../img/icons/x-close.svg";

interface IProps {
    closeButtonRef: (elem: HTMLButtonElement | null) => void;
    onCloseButtonClick: () => void;
    prefixNameCapped: string;
}

interface IState {}

export class ConfiguratorPanelHeadAlternative extends React.PureComponent<
    IProps,
    IState
> {
    render() {
        return (
            <div className="configurator__panel--head">
                <h5>
                    {interpolate(gettext("%s"), [this.props.prefixNameCapped])}
                </h5>
                <button
                    ref={this.props.closeButtonRef}
                    aria-label={gettext("Close")}
                    onClick={this.props.onCloseButtonClick}
                >
                    <SVG aria-hidden="true" src={iconXClose} />
                </button>
            </div>
        );
    }
}

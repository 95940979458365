import React from "react";
import { connect } from "react-redux";
import SVG from "react-inlinesvg";
import { TStateMapper } from "../../reducers.interfaces";
import { IAPIPrice } from "../../../models/prices.interfaces";
import { RawHTML } from "../../../common/RawHTML";
import { defaults } from "../defaults";
import { UncontrolledPopover } from "../../../common/Popover";
import { IPLCInfoPanel } from "../models.interfaces";
import { upgradedVariantPriceSelector } from "../selectors";
import { format } from "../../../utils/format";
import { PriceType, getProductPrice } from "../../../utils/catalogue";
import { CSSTransition } from "react-transition-group";
import { focusElement } from "../../../utils/keyboardFocus";
import { MOBILE_WIDTH_THRESHOLD_ALTERNATIVE } from "../constants";
import {
    disableBodyScroll,
    enableBodyScroll,
    clearAllBodyScrollLocks,
} from "body-scroll-lock";

// Load styles
import "./PLCInfoPanel.scss";
import iconArrowLeftBlueAlternate from "../../../../img/icons/arrow-left-blue--alternate.svg";

interface IOwnProps {
    buttonText: string | React.ReactNode;
    content: IPLCInfoPanel;
    onAfterOpen?: () => void;
}

interface IReduxProps {
    price: IAPIPrice | null;
}

interface IProps extends IOwnProps, IReduxProps {}

interface IState {
    isOpen: boolean;
}

const BEM_NAME = "plc-info-panel";

class PLCInfoPanelComponent extends React.PureComponent<IProps, IState> {
    private readonly closeBtnElem = React.createRef<HTMLButtonElement>();
    private readonly popover = React.createRef<UncontrolledPopover>();
    private readonly popoverDiv = React.createRef<HTMLDivElement>();

    public state: IState = {
        isOpen: false,
    };

    private readonly onAfterOpen = () => {
        this.setState(
            {
                isOpen: true,
            },
            () => {
                if (window.innerWidth < MOBILE_WIDTH_THRESHOLD_ALTERNATIVE) {
                    disableBodyScroll(this.popoverDiv.current as HTMLElement);
                } else {
                    enableBodyScroll(this.popoverDiv.current as HTMLElement);
                }

                if (this.closeBtnElem.current) {
                    focusElement(this.closeBtnElem.current);
                }
                if (this.props.onAfterOpen) {
                    this.props.onAfterOpen();
                }
            },
        );
    };

    private readonly onRequestClose = () => {
        // `enableBodyScroll` should run before setState `isPanelOpen: false`,
        // so inline style `position: fixed` from `<body>` will be removed first before removing `.configurator-panel-open` from `<body>`.
        // This way can avoid the issue of the page jumping to the top when panel is opened/closed
        enableBodyScroll(this.popoverDiv.current as HTMLElement);
        this.setState(
            {
                isOpen: false,
            },
            () => {
                setTimeout(() => {
                    if (this.popover.current) {
                        this.popover.current.requestClose();
                    }
                }, 400);
            },
        );
    };

    componentWillUnmount() {
        clearAllBodyScrollLocks();
    }

    render() {
        const price = this.props.price
            ? getProductPrice(this.props.price, {
                  priceType: PriceType.COSMETIC_EXCL_TAX,
                  includePostDiscountAddons: true,
                  quantity: this.props.price.quantity,
              })
            : null;
        return (
            <span className={BEM_NAME}>
                <UncontrolledPopover
                    ref={this.popover}
                    triggerContent={this.props.buttonText}
                    triggerBtnProps={{
                        "aria-label": gettext("Learn More"),
                        "className": `${BEM_NAME}__open-button`,
                    }}
                    popperOptions={{
                        placement: "left",
                    }}
                    modalProps={{
                        className: `${BEM_NAME}__modal`,
                        overlayClassName: `${BEM_NAME}__overlay`,
                        closeBtnProps: {
                            className: `${BEM_NAME}__close-button`,
                        },
                        disableHeightExpansion: true,
                    }}
                    disablePopper={true}
                    onParentSelector={() =>
                        document.querySelector(
                            ".plc-configurator",
                        ) as HTMLElement
                    }
                    onAfterOpen={this.onAfterOpen}
                >
                    <CSSTransition
                        key="plc-info-panel"
                        in={this.state.isOpen}
                        timeout={100}
                        classNames="plc-info-panel__content-"
                        unmountOnExit={true}
                    >
                        <div
                            aria-modal="true"
                            className={`${BEM_NAME}__content`}
                            role="dialog"
                            ref={this.popoverDiv}
                        >
                            <header>
                                <button
                                    aria-label="Info Panel - Back"
                                    onClick={this.onRequestClose}
                                    ref={this.closeBtnElem}
                                >
                                    <SVG src={iconArrowLeftBlueAlternate} />
                                    Back
                                </button>
                                <div className={`${BEM_NAME}__current-price`}>
                                    {price ? format.money(price) : ""}
                                </div>
                            </header>
                            <RawHTML html={this.props.content.body} />
                        </div>
                    </CSSTransition>
                </UncontrolledPopover>
            </span>
        );
    }
}

const mapStateToProps: TStateMapper<"configurator", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.configurator || defaults;
    return {
        price: upgradedVariantPriceSelector(state),
        // Direct Props
        ...ownProps,
    };
};

export const PLCInfoPanel = connect(mapStateToProps)(PLCInfoPanelComponent);

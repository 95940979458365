import creditCardType from "credit-card-type";
import { parseISO as parseISODate, format as formatDate } from "date-fns";
import { IMonths, isoMonths, IAPR, isoAPR } from "../models/nominals";
import { FinancingPlanMeta } from "./financing";
import { isDinero } from "./guards";
import { getDinero } from "./money";
import { getInteger } from "./numbers";

const MONEY_FORMAT = "$0,0.00";
const ROUNDED_MONEY_FORMAT = "$0,0";
const DECIMAL_FORMAT = "0.00";

export type TBasicStringFormatter = (
    value: string | number | Dinero.Dinero | null | undefined,
) => string;

/**
 * Convert a string value into a boolean.
 * Based on https://docs.python.org/3/distutils/apiref.html?highlight=distutils.util#distutils.util.strtobool
 */
export const strToBool = (value: string) => {
    switch (value.toLowerCase()) {
        case "y":
        case "yes":
        case "t":
        case "true":
        case "on":
        case "1":
            return true;

        case "n":
        case "no":
        case "f":
        case "false":
        case "off":
        case "0":
            return false;

        default:
            console.error(
                `Received invalid string representation of a boolean value: ${value}`,
            );
            return false;
    }
};

/**
 * Format a number as an integer
 */
export const integer: TBasicStringFormatter = (value): string => {
    if (isDinero(value)) {
        return value.toFormat("0");
    }
    const num = getInteger(value, null);
    if (num === null) {
        return "";
    }
    return `${num}`;
};

/**
 * Format a number as a decimal with two-decimal places.
 */
export const decimal: TBasicStringFormatter = (value) => {
    return getDinero(value).toFormat(DECIMAL_FORMAT);
};

/**
 * Format a number as a dollar amount with thousand-separators and two-decimal places.
 */
export const money: TBasicStringFormatter = (value) => {
    // If the given value is falsy, display a dash. However if the value is actually
    // the number 0 (which is also falsy), display "$0.00".
    if (value === undefined || value === null || value === "") {
        return "-";
    }
    return getDinero(value).toFormat(MONEY_FORMAT);
};

/**
 * Format a number as a dollar amount with thousand-separators and no decimal places.
 */
export const roundedMoney: TBasicStringFormatter = (value) => {
    // If the given value is falsy, display a dash. However if the value is actually
    // the number 0 (which is also falsy), display "$0.00".
    if (value === undefined || value === null || value === "") {
        return "-";
    }
    return getDinero(value).toFormat(ROUNDED_MONEY_FORMAT);
};

/**
 * Format a number as a shipping-cost. This is normally a decimal with thousand-separators
 * and two-decimal places, but is the the string "FREE" with the input number is "0.00".
 */
export const shippingCost: TBasicStringFormatter = (value) => {
    const cost = getDinero(value);
    if (cost.isZero()) {
        return "FREE";
    }
    return money(cost);
};

/**
 * Format a string as a masked SSN.
 */
export const maskedSSN: TBasicStringFormatter = (value) => {
    if (!value) {
        return "";
    }
    return `###-##-${value.toString().slice(-4)}`;
};

/**
 * Format a Wells Fargo account number for display
 */
export const wellsFargoAccountNumber: TBasicStringFormatter = (num) => {
    if (!num) {
        return "";
    }
    num = num.toString().replace(/\s/g, "");
    let out = "";
    for (let i = 1; i <= num.length; i++) {
        out += num[i - 1];
        if (i % 4 === 0) {
            out += " ";
        }
    }
    return out.trim();
};

/**
 * Format form input into groups of digits.
 */
export const digitGroupings = (
    regexp: RegExp,
    separator: string,
    separatorInsertionPoints: number[],
    oldValue: string,
    newValue: string,
) => {
    const parts = newValue.match(regexp);
    if (!parts) {
        return newValue;
    }
    parts.shift();
    newValue = parts
        .filter((part) => {
            return part;
        })
        .join(separator);
    if (
        oldValue.length < newValue.length &&
        separatorInsertionPoints.indexOf(newValue.length) !== -1
    ) {
        newValue += separator;
    }
    return newValue;
};

/**
 * Format the input credit card number for display with spaces between number groups.
 */
export const creditCardNumber = (cardNumber: string, cardType: string) => {
    const card = creditCardType.getTypeInfo(cardType);

    if (!card || !card.gaps) {
        return cardNumber;
    }

    const offsets = [0].concat(card.gaps).concat([cardNumber.length]);
    const components: string[] = [];

    for (let i = 0; offsets[i] < cardNumber.length; i++) {
        const start = offsets[i];
        const end = Math.min(offsets[i + 1], cardNumber.length);
        components.push(cardNumber.substring(start, end));
    }

    return components.join(" ");
};

/**
 * Parse a date object from a string and then format it as another format
 *
 * See date-fns docs for formatting options: https://date-fns.org/v1.29.0/docs/format
 */
export const formatDateString = (isoDate: string, outputFormat: string) => {
    const date = parseISODate(isoDate);
    return formatDate(date, outputFormat);
};

export const formatPhoneNumber = (phoneNumberString: string): string => {
    const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return ["(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return "";
};

export const formatNumber = (num: number): string => {
    const formatter = new Intl.NumberFormat("en-US");
    return formatter.format(num);
};

export const formatPercentage = (
    percentage: number,
    minPrecision = 0,
    maxPrecision = 2,
): string => {
    const formatter = new Intl.NumberFormat("en-US", {
        style: "percent",
        minimumFractionDigits: minPrecision,
        maximumFractionDigits: maxPrecision,
    });
    return formatter.format(percentage / 100);
};

export const formatAPR = (
    apr: IAPR,
    minPrecision = 0,
    maxPrecision = 2,
): string => {
    const aprFloat = parseFloat(isoAPR.unwrap(apr));
    return formatPercentage(aprFloat, minPrecision, maxPrecision);
};

export const formatTermLength = (term: IMonths): string => {
    const formatter = new Intl.NumberFormat("en-US");
    return formatter.format(isoMonths.unwrap(term));
};

/**
 * Format the fields of financing plan for string interpolation.
 */
export const formatFinancingPlan = (
    plan: FinancingPlanMeta | null,
): Omit<{ [T in keyof FinancingPlanMeta]: string }, "default"> => {
    if (!plan) {
        return {
            threshold: "",
            length: "",
            apr: "",
            superscript: "",
            description: "",
        };
    }
    return {
        threshold: money(plan.threshold),
        length: formatTermLength(plan.length),
        apr: formatAPR(plan.apr),
        superscript: plan.superscript,
        description: plan.description,
    };
};

// Export Public API
export type TBasicStringFormatters =
    | "integer"
    | "decimal"
    | "money"
    | "shippingCost"
    | "maskedSSN"
    | "wellsFargoAccountNumber";

export const basicStringFormatters: {
    [T in TBasicStringFormatters]: TBasicStringFormatter;
} = {
    integer,
    decimal,
    money,
    shippingCost,
    maskedSSN,
    wellsFargoAccountNumber,
};

export const format = {
    ...basicStringFormatters,
    digitGroupings,
    creditCardNumber,
};
export default format;

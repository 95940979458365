import React from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import { ConfiguratorTypes } from "../../../constants";
import { TStateMapper, TDispatchMapper } from "../../reducers.interfaces";
import { defaults } from "../defaults";
import { Dispatchers } from "../dispatchers";
import styles from "./QuantitySelector.module.scss";

interface IOwnProps {
    configuratorType?: ConfiguratorTypes;
}

interface IReduxProps {
    quantity: number;
}

interface IDispatchProps {
    onQuantityChange: (e: React.FormEvent<HTMLSelectElement>) => void;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {}

class QuantitySelectorComponent extends React.PureComponent<IProps, IState> {
    render() {
        const outerClasses = classNames({
            [styles.root]: true,
            "configurator__select-container": true,
            "configurator__select-container--qty": true,
            [`configurator__select-container--qty--${this.props.configuratorType}`]:
                true,
        });
        const prefixClasses = classNames({
            [styles.prefix]: true,
            "configurator__prefix": true,
            "configurator__prefix--qty": true,
            [`configurator__prefix--qty--${this.props.configuratorType}`]: true,
        });
        const selectClasses = classNames({
            [styles.select]: true,
            "configurator__select": true,
            "configurator__select--qty": true,
            [`configurator__select--qty--${this.props.configuratorType}`]: true,
        });
        const options = Array<number>(5)
            .fill(0)
            .map((_, i) => {
                return i + 1;
            })
            .map((i) => {
                return (
                    <option key={i} value={i}>
                        {i}
                    </option>
                );
            });
        return (
            <div className={outerClasses}>
                <label htmlFor="quantity" className={prefixClasses}>
                    {gettext("Qty")}
                </label>
                <select
                    id="quantity"
                    className={selectClasses}
                    value={this.props.quantity}
                    onChange={this.props.onQuantityChange}
                >
                    <optgroup label={gettext("Qty")}>{options}</optgroup>
                </select>
            </div>
        );
    }
}

const mapStateToProps: TStateMapper<"configurator", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.configurator || defaults;
    return {
        quantity: state.ui.quantity,
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        onQuantityChange: (event) => {
            event.preventDefault();
            const qty = parseInt(event.currentTarget.value, 10);
            dispatchers.setQuantity(qty);
        },
    };
};

export const QuantitySelector = connect(
    mapStateToProps,
    mapDispatchToProps,
)(QuantitySelectorComponent);

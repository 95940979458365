import { createBrowserHistory, LocationDescriptor } from "history";
import { Dispatch } from "@reduxjs/toolkit";
import { PathReporter } from "io-ts/lib/PathReporter";
import { isRight } from "fp-ts/lib/Either";
import { NumberFromString } from "io-ts-types/lib/NumberFromString";
import { IProduct } from "../../models/catalogue.interfaces";
import { IProductID, isoProductID } from "../../models/nominals";
import { IPLCProductCategorySelector } from "./models.interfaces";
import { Dispatchers } from "./dispatchers";

export interface IHistoryState {
    root?: IProduct;
    variant?: IProduct;
    selectedUpgradeID?: IProductID;
    categorySelectors?: IPLCProductCategorySelector[];
}

const UPGRADE_ID_PARAM = "upgrade";

// Set-up history API
// Remove the /v/1234/ (the variant ID) from the base path
const baseURL = window.location.pathname.replace(/\/(v\/\d+\/?)$/, "/");

console.log(`[Configurator] Setting history base URL to ${baseURL}`);

export const history = createBrowserHistory({
    basename: baseURL,
    forceRefresh: false,
});

/**
 * React to history change events
 */
export const startHistoryListener = (dispatch: Dispatch) => {
    history.listen((location, action) => {
        console.info(
            `[Configurator] Received history update: ${action} ${location.pathname}${location.search}${location.hash}`,
        );
        // Update Redux to match the new history location
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        const state: IHistoryState | undefined | null = location.state as any;
        if (state && state.root && state.variant) {
            const dispatchers = new Dispatchers(dispatch);
            dispatchers.setSelectedVariant(
                state.root,
                state.variant,
                state.categorySelectors,
            );
        }
    });
};

/**
 * Update the PDP Page's URL to include the ID of the currently selected Variant. This is to allow
 * linking to a particular variant of a product.
 */
export const pushPDPVariantURLChange = (
    root: IProduct | null,
    variant: IProduct | null,
    selectedUpgradeID: IProductID | null,
    categorySelectors?: IPLCProductCategorySelector[],
) => {
    const newPath = variant ? `/v/${variant.id}/` : "/";
    const newURLParams = new URLSearchParams(window.location.search);
    if (selectedUpgradeID) {
        newURLParams.set(UPGRADE_ID_PARAM, `${selectedUpgradeID}`);
    } else {
        newURLParams.delete(UPGRADE_ID_PARAM);
    }
    const newSearch = `?${newURLParams.toString()}`;
    const historyState: IHistoryState = {
        root: root || undefined,
        variant: variant || undefined,
        selectedUpgradeID: selectedUpgradeID || undefined,
        categorySelectors: categorySelectors,
    };
    const entry: LocationDescriptor = {
        pathname: newPath,
        search: newSearch,
        state: historyState,
    };
    const pathMatchesExpected = newPath === history.location.pathname;
    const searchMatchesExpected = newSearch === history.location.search;
    // If nothing has changed, don't do anything
    if (
        pathMatchesExpected &&
        searchMatchesExpected &&
        history.location.state !== null
    ) {
        return;
    }
    // If this is just a state change, replace the current state. Otherwise,
    // push it onto the history stack.
    if (
        (pathMatchesExpected && searchMatchesExpected) ||
        history.location.pathname === "/"
    ) {
        history.replace(entry);
    } else {
        history.push(entry);
    }
};

/**
 * Get the configurator selected upgrade ID from the query string
 */
export const getSelectedUpgradeIDFromURL = (): IProductID | null => {
    const urlParams = new URLSearchParams(window.location.search);
    const rawUpgradeID = urlParams.get(UPGRADE_ID_PARAM);
    if (!rawUpgradeID) {
        return null;
    }
    const maybeUpgradeID = NumberFromString.decode(rawUpgradeID);
    if (isRight(maybeUpgradeID)) {
        return isoProductID.wrap(maybeUpgradeID.right);
    }
    console.log(PathReporter.report(maybeUpgradeID).join("\n\n"));
    return null;
};

import React from "react";
import { connect } from "react-redux";
import SVG from "react-inlinesvg";
import {
    IReviewsProductTypeID,
    IReviewsProductID,
    IReviewsProductVariantID,
    IFormUUID,
    IWebPageURL,
} from "../../../models/nominals";
import {
    IWriteReviewTemplate,
    IReviewsProductVariantChoices,
    IReviewsProduct,
} from "../../../models/reviews.interfaces";
import { notEmpty, unique } from "../../../utils/functional";
import { urls } from "../../../utils/urls";
import { TStateMapper, TDispatchMapper } from "../../reducers.interfaces";
import { IWriteReviewFormState } from "../reducers.interfaces";
import { Dispatchers } from "../dispatchers";
import { Actions } from "../actions";
import { defaultState } from "../defaults";
import {
    WriteReviewFormMode,
    SubmitReviewStatus,
    REVIEW_VARIANT_NOT_SELECTED,
    NOT_SURE_VARIANT_CHOICE_SLUG,
} from "../constants";
import {
    getWriteReviewFormState,
    getWriteReviewTemplateForForm,
    getWriteReviewFormHTMLID,
    getWriteReviewFormProducts,
    getWriteReviewFormVariantChoices,
} from "../selectors";
import { Form } from "../../../forms/Form";
import { FormSubmit } from "../../../forms/FormSubmit";
import { WriteReviewFormHeader } from "./WriteReviewFormHeader";
import { WriteReviewFormProductSelect } from "./WriteReviewFormProductSelect";
import { WriteReviewFormFields } from "./WriteReviewFormFields";
import { WriteReviewFormDisclaimers } from "./WriteReviewFormDisclaimers";
import iconXClose from "../../../../img/icons/x-close.svg";

interface IOwnProps {
    formUUID: IFormUUID;
    formTitle?: string;
    brand?: string;
    displayedProductTypeIDWhitelist?: IReviewsProductTypeID[];
    defaultProductTypeID?: IReviewsProductTypeID;
    defaultProductID?: IReviewsProductID;
    defaultProductVariantID?: IReviewsProductVariantID;
    onReviewSubmit?: () => void;
    returnURL?: IWebPageURL;
}

interface IReduxProps {
    products: IReviewsProduct[];
    formState: IWriteReviewFormState;
    variantChoices: IReviewsProductVariantChoices[];
    writeReviewTemplateProductID: IReviewsProductID | null;
    writeReviewTemplate: IWriteReviewTemplate;
}

interface IDispatchProps {
    dispatchers: Dispatchers;
    actions: Actions;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {}

class WriteReviewFormContainer extends React.Component<IProps, IState> {
    private readonly onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const isMultiReview = false;

        // if submitting blank variant for a product with variant choices, submit "not-selected", which trhows error
        const formState = this.props.formState;
        if (
            formState.selectedVariant === "" &&
            this.props.variantChoices.length > 1
        ) {
            formState.selectedVariant = REVIEW_VARIANT_NOT_SELECTED;
        }

        // if submitting "not-sure" variant for a product with variant choices, submit ""
        if (formState.selectedVariant === NOT_SURE_VARIANT_CHOICE_SLUG) {
            formState.selectedVariant = "";
        }

        // Attempt to submit review
        const submitStatus = await this.props.actions.submitReview(
            this.props.formUUID,
            this.props.writeReviewTemplate,
            formState,
            isMultiReview,
        );

        switch (submitStatus) {
            // Product selection errors?
            case SubmitReviewStatus.FAIL_MISSING_PRODUCT_SELECTION:
            case SubmitReviewStatus.FAIL_MISSING_VARIANT_SELECTION:
                this.scrollToProductSelect();
                break;

            // Success?
            case SubmitReviewStatus.SUCCESS:
                if (this.props.onReviewSubmit) {
                    this.props.onReviewSubmit();
                }
                break;
        }
    };

    private readonly onClose = () => {
        if (this.props.returnURL) {
            // Simulate a button click to return page
            urls.navigateToURL(this.props.returnURL);
            return;
        }
        this.props.dispatchers.setWriteReviewFormMode(
            this.props.formUUID,
            WriteReviewFormMode.CLOSED,
        );
    };

    componentDidMount() {
        this.setDefaultProductSelection();
        this.setDefaultVariantSelection();

        // If we have defaults, scroll past the selection portion of the form
        if (this.props.defaultProductID || this.props.defaultProductVariantID) {
            const formDiv = document.querySelector(".wrf-rating__title");
            if (formDiv) {
                // Without the setTimeout, the page scroll stop at the wrong position(higher position) on Safari.
                // This issue occurs on only Safari. It looks scrollIntoView is fired before the client loaded
                // so setTimeout is added to fire scrollIntoView after the component loaded.
                setTimeout(() => {
                    formDiv.scrollIntoView(true);
                }, 50);
            }
        }
    }

    componentDidUpdate() {
        this.setDefaultProductSelection();
        this.setDefaultVariantSelection();
    }

    private setDefaultProductSelection() {
        // If we already have a product selected, do nothing.
        if (
            this.props.writeReviewTemplateProductID ||
            this.props.products.length <= 0
        ) {
            return;
        }

        // Select the default product if we have one.
        if (this.props.defaultProductID) {
            this.props.dispatchers.setReviewFormSelectedProduct(
                this.props.formUUID,
                this.props.defaultProductID,
            );
            return;
        }

        // Since selected product implies ProductType, only set the default product type if we don't have a default product
        if (this.props.defaultProductTypeID) {
            this.props.dispatchers.setReviewFormSelectedProductType(
                this.props.formUUID,
                this.props.products,
                this.props.defaultProductTypeID,
            );
            return;
        }

        // If there's only a single product type for the given choices, return the first product for that type.
        const productTypes = this.props.products
            .map((p) => {
                return p.product_type;
            })
            .filter(unique)
            .filter(notEmpty);
        if (productTypes.length === 1) {
            this.props.dispatchers.setReviewFormSelectedProductType(
                this.props.formUUID,
                this.props.products,
                productTypes[0],
            );
            return;
        }
    }

    private setDefaultVariantSelection() {
        // If we already have a variant selected, do nothing.
        if (
            !this.props.writeReviewTemplateProductID ||
            this.props.products.length <= 0 ||
            this.props.formState.selectedVariant
        ) {
            return;
        }

        // Set the default product variant, if given
        if (this.props.defaultProductVariantID) {
            const variantSlug = this.props.products.reduce((memo, product) => {
                const variant = product.variants.find((v) => {
                    return v.id === this.props.defaultProductVariantID;
                });
                return variant ? variant.slug : memo;
            }, "");
            if (variantSlug) {
                this.props.dispatchers.onReviewFormVariantChange({
                    formUUID: this.props.formUUID,
                    selectedVariant: variantSlug,
                });
            }
        }
    }

    private scrollToProductSelect() {
        const productSelectDiv = document.querySelector(
            ".wrf-product-select-form",
        );
        productSelectDiv?.scrollIntoView({ behavior: "smooth" });
    }

    render() {
        if (this.props.formState.mode !== WriteReviewFormMode.FORM_OPEN) {
            return null;
        }
        return (
            <div
                id={getWriteReviewFormHTMLID(this.props.formUUID)}
                className="write-review-form"
                data-form-uuid={this.props.formUUID}
            >
                <button
                    className="write-review-form__close"
                    onClick={this.onClose}
                    aria-label={gettext("close")}
                >
                    <SVG
                        className="write-review-form__close-icon"
                        src={iconXClose}
                        role="img"
                        aria-hidden="true"
                    />
                </button>
                <WriteReviewFormHeader
                    formTitle={
                        this.props.formTitle || gettext("Write a Review")
                    }
                />
                <Form onSubmit={this.onSubmit} noValidate={true}>
                    <WriteReviewFormProductSelect
                        formUUID={this.props.formUUID}
                        displayedProductTypeIDWhitelist={
                            this.props.displayedProductTypeIDWhitelist
                        }
                    />
                    <WriteReviewFormFields
                        formUUID={this.props.formUUID}
                        displayedProductTypeIDWhitelist={
                            this.props.displayedProductTypeIDWhitelist
                        }
                        brand={this.props.brand}
                    />
                    <WriteReviewFormDisclaimers />
                    <FormSubmit
                        className="button button--rock-blue write-review-form__cta"
                        type="submit"
                        value={gettext("Submit your review")}
                    />
                </Form>
            </div>
        );
    }
}

const mapStateToProps: TStateMapper<"reviews", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.reviews || defaultState;
    // Find review form
    const formTemplate = getWriteReviewTemplateForForm(state, ownProps);
    return {
        ...ownProps,
        products: getWriteReviewFormProducts(state, ownProps),
        formState: getWriteReviewFormState(state, ownProps),
        variantChoices: getWriteReviewFormVariantChoices(state, ownProps),
        writeReviewTemplateProductID: formTemplate
            ? formTemplate.productID
            : null,
        writeReviewTemplate: formTemplate.template,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    const actions = new Actions(dispatchers);
    return {
        dispatchers: dispatchers,
        actions: actions,
    };
};

export const WriteReviewForm = connect(
    mapStateToProps,
    mapDispatchToProps,
)(WriteReviewFormContainer);

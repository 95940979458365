import React from "react";
import { IReview } from "../../../models/reviews.interfaces";

interface IProps {
    review: IReview;
}

export const ReviewSource = (props: IProps) => {
    return (
        <div>
            <div className="customer-review__source-title">
                <strong>{gettext("Source:")}</strong>
            </div>
            {props.review.source_name}
        </div>
    );
};

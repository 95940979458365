import React from "react";
import { UncontrolledPopover } from "../../../common/Popover";
import { RichText } from "../../../common/RichText";
import { IImageURL } from "../../../models/nominals";
import styles from "./ConfiguratorReviewAggregation.module.scss";

interface IProps {
    reviewAggregationCopy: string;
    modalContent: string | null;
    checkmarkIconURL?: IImageURL | null | undefined;
    starsImageURL?: IImageURL | null | undefined;
}

export const ConfiguratorReviewAggregation = (props: IProps) => {
    const buildReviewCopy = () => {
        return (
            <>
                <RichText
                    html={props.reviewAggregationCopy}
                    className={styles.copy}
                />
                {props.checkmarkIconURL && (
                    <img
                        src={`${props.checkmarkIconURL}`}
                        aria-hidden="true"
                        alt=""
                    />
                )}
            </>
        );
    };
    const buildReviewCopyAndModalContent = () => {
        return (
            <UncontrolledPopover
                triggerContent={buildReviewCopy()}
                triggerBtnProps={{ className: styles.button }}
                popperOptions={{
                    modifiers: [
                        {
                            name: "offset",
                            options: {
                                offset: [0, 12],
                            },
                        },
                        {
                            name: "flip",
                            options: {
                                fallbackPlacements: ["top", "bottom"],
                            },
                        },
                    ],
                    placement: "bottom",
                }}
                modalProps={{
                    className: styles.modalContent,
                    contentLabel: gettext("Reviews"),
                    bodyOpenClassName: styles.bodyModalOpen,
                    style: {
                        overlay: {
                            backgroundColor: "transparent",
                        },
                    },
                }}
            >
                {props.starsImageURL && (
                    <img
                        src={`${props.starsImageURL}`}
                        aria-hidden="true"
                        alt=""
                    />
                )}
                {props.modalContent && <RichText html={props.modalContent} />}
            </UncontrolledPopover>
        );
    };
    return (
        <div className="configurator__review-aggregation--container">
            {!props.modalContent
                ? buildReviewCopy()
                : buildReviewCopyAndModalContent()}
        </div>
    );
};

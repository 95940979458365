import React from "react";
import { IProduct } from "../../../models/catalogue.interfaces";
import { Link } from "../../../common/Link";
import { ICompareTile } from "../../../models/product-compare.interfaces";
import { getShopNowURL } from "../selectors";

interface IProps {
    tile: ICompareTile;
    selectedVariant: IProduct | null;
}

export const ShopNowLink = (props: IProps) => {
    if (!props.selectedVariant) {
        return null;
    }
    const url = getShopNowURL(props.tile, props.selectedVariant);
    return (
        <Link href={url} className="button al-comparisongrid-shopnow">
            {gettext("Shop Now")}
            <span className="ada-screenreader-only">
                {interpolate(
                    gettext("for %(productName)s"),
                    {
                        productName: props.selectedVariant?.title || "",
                    },
                    true,
                )}
            </span>
        </Link>
    );
};

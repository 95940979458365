import React from "react";
import classNames from "classnames";
import {
    ILocation,
    IRetailStoreWithDistance,
} from "tsi-common-react/src/models/location.interfaces";
import { ISyncStoreID } from "tsi-common-react/src/models/nominals";
import { LocationInput } from "tsi-common-react/src/apps/common/containers/LocationInput";
import { MarkerMap } from "tsi-common-react/src/apps/retail/containers/MarkerMap";
import { Link } from "tsi-common-react/src/common/Link";
import { FilterDistanceSelect } from "tsi-common-react/src/apps/retail/containers/FilterDistanceSelect";
import { formatDistanceWithSuffix } from "tsi-common-react/src/apps/retail/helpers";
import { formatPhoneNumber } from "tsi-common-react/src/utils/format";
import openLinkIconBlue from "../../img/retail-locator/open-link-icon-blue.png";
import iconRlPin from "../../img/icons/icon-rl-pin.png";

interface ILocationProps {
    storeNumber: number;
    store: IRetailStoreWithDistance;
    isFocused: boolean;
    onSelect: (storeID: ISyncStoreID) => void;
}

interface IContainerProps {
    stores: IRetailStoreWithDistance[];
    focusedStoreID: ISyncStoreID | null;
    location: ILocation | null;
    onBeforeInfoWindowOpen: (
        info: google.maps.InfoWindow,
        store: IRetailStoreWithDistance,
    ) => void;
    onInfoWindowClose: () => void;
}

const RetailLocation = (props: ILocationProps) => {
    // encode destination address for Directions link
    const destinationAddress = encodeURI(
        props.store.address +
            " " +
            props.store.address2 +
            " " +
            props.store.city +
            "," +
            props.store.state +
            " " +
            props.store.postal,
    );
    const articleClasses = classNames({
        "list-focus": props.isFocused,
    });
    const directionsURL = `https://www.google.com/maps/dir/?api=1&destination=${destinationAddress}`;
    let websiteLinkElement: JSX.Element | null = null;

    if (props.store.web_sealy) {
        websiteLinkElement = (
            <div className="loc-website">
                <Link
                    href={props.store.web_sealy}
                    target="_blank"
                    className="loc-website"
                    rel="nofollow noreferrer"
                >
                    Website
                    <img src={openLinkIconBlue} alt="Website" />
                </Link>
            </div>
        );
    }

    return (
        <li>
            <button
                className={articleClasses}
                onClick={() => {
                    props.onSelect(props.store.external_id);
                }}
                aria-current={props.isFocused}
            >
                <div className="list-label">{props.storeNumber}</div>
                <div className="list-details">
                    <div className="list-content">
                        <h4 className="loc-name">{props.store.name}</h4>
                        <div className="loc-addr">{props.store.address}</div>
                        <div className="loc-addr2">{props.store.address2}</div>
                        <div className="loc-addr3">
                            <span className="loc-city">{props.store.city}</span>
                            ,&nbsp;
                            <span className="loc-state">
                                {props.store.state}
                            </span>
                            &nbsp;
                            <span className="loc-postal">
                                {props.store.postal}
                            </span>
                        </div>
                        <div className="loc-phone">
                            <a href={`tel:+${props.store.phone}`}>
                                {formatPhoneNumber(props.store.phone)}
                            </a>
                        </div>
                        <div className="loc-dist">
                            {formatDistanceWithSuffix(props.store.distance, 2)}
                        </div>
                        <div className="loc-directions">
                            <a
                                href={directionsURL}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {gettext("Directions")}
                            </a>
                        </div>
                        {websiteLinkElement}
                    </div>
                </div>
            </button>
        </li>
    );
};

export class RetailLocatorBlock extends React.Component<
    IContainerProps,
    Record<string, never>
> {
    private readonly mapElem = React.createRef<MarkerMap>();

    private readonly getStoreNumber = (store: IRetailStoreWithDistance) => {
        return this.props.stores.indexOf(store) + 1;
    };

    render() {
        const locationInputID = "retail-locator-location-input";
        const distanceInputID = "retail-locator-distance-input";
        return (
            <>
                <div className="retailers__hero">
                    <div className="container bh-sl-container">
                        <div className="retailers__form">
                            <h3 className="retailers__headline">
                                {gettext("Find a Retailer")}
                            </h3>
                            <div className="retailers__form-container bh-sl-form-container">
                                <form
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                    }}
                                >
                                    <div className="retailers__form-input form-input">
                                        <label
                                            className="retailers__form-label retailers__form-label--address legal"
                                            htmlFor={locationInputID}
                                        >
                                            <LocationInput
                                                inputFormat="short"
                                                inputProps={{
                                                    id: locationInputID,
                                                    className:
                                                        "retailers__form-address",
                                                    name: "bh-sl-address",
                                                    placeholder:
                                                        gettext(
                                                            "Zip or City, State",
                                                        ),
                                                }}
                                            />
                                        </label>
                                        <label
                                            className="retailers__form-label retailers__form-label--distance legal"
                                            htmlFor={distanceInputID}
                                        >
                                            <FilterDistanceSelect
                                                selectProps={{
                                                    id: distanceInputID,
                                                    className:
                                                        "retailers__form-label-arrow",
                                                    name: "bh-sl-maxdistance",
                                                    title: gettext(
                                                        "Retailer Distance",
                                                    ),
                                                }}
                                            />
                                        </label>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    id="bh-sl-map-container"
                    className="container bh-sl-map-container"
                >
                    <MarkerMap
                        ref={this.mapElem}
                        stores={this.props.stores}
                        markerOptions={(store: IRetailStoreWithDistance) => {
                            return {
                                icon: iconRlPin,
                                label: {
                                    text: `${this.getStoreNumber(store)}`,
                                    color: "#000",
                                    className: "marker-label",
                                    fontWeight: "light",
                                },
                            };
                        }}
                        elemProps={{
                            id: "bh-sl-map",
                            className: "bh-sl-map",
                        }}
                        onBeforeInfoWindowOpen={
                            this.props.onBeforeInfoWindowOpen
                        }
                        onInfoWindowClose={this.props.onInfoWindowClose}
                    />
                    <div className="bh-sl-loc-list">
                        <ul className="list">
                            {this.props.stores.map((store, i) => (
                                <RetailLocation
                                    {...this.props}
                                    key={`${store.external_id}`}
                                    storeNumber={i + 1}
                                    store={store}
                                    isFocused={
                                        this.props.focusedStoreID ===
                                        store.external_id
                                    }
                                    onSelect={(storeID) => {
                                        this.mapElem.current?.onActivateMarker(
                                            storeID,
                                        );
                                    }}
                                />
                            ))}
                            {this.props.stores.length <= 0 && (
                                <li>
                                    <div className="bh-sl-noresults-title">
                                        {gettext("No results")}
                                    </div>
                                    <br />
                                    <div className="bh-sl-noresults-desc">
                                        {gettext(
                                            "No locations were found with the given criteria. Please modify your selections or input.",
                                        )}
                                    </div>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            </>
        );
    }
}

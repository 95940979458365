import React from "react";
import ReactTooltip from "react-tooltip";
import { connect } from "react-redux";
import { format as formatDate } from "date-fns";
import { Trans } from "../../../common/Trans";
import { IDeliveryDatePredictionState } from "../reducers.interfaces";
import { TStateMapper } from "../../reducers.interfaces";
import { PredictedDeliveryLoader } from "./PredictedDeliveryLoader";
import {
    PredictedDeliveryDateStatus,
    PredictedDeliveryDateDisplayType,
} from "../constants";

import styles from "./PredictedDeliveryHelpText.module.scss";

interface IOwnProps {
    type: PredictedDeliveryDateDisplayType;
    additionalHelpText: string;
}

interface IReduxProps {
    prediction: IDeliveryDatePredictionState;
}

interface IProps extends IOwnProps, IReduxProps {}

interface IState {
    isTooltipOpen: boolean;
}

class PredictedDeliveryHelpTextComponent extends React.Component<
    IProps,
    IState
> {
    public state: IState = {
        isTooltipOpen: false,
    };

    render() {
        if (
            this.props.type === PredictedDeliveryDateDisplayType.DISABLED ||
            this.props.prediction.status ===
                PredictedDeliveryDateStatus.INITIAL ||
            this.props.prediction.status ===
                PredictedDeliveryDateStatus.ERROR ||
            this.props.prediction.status === PredictedDeliveryDateStatus.LOADING
        ) {
            return <span>{this.props.additionalHelpText}</span>;
        }
        const tipId = "predicted-delivery-date-help-text__tooltip";
        const displayDate =
            this.props.type === PredictedDeliveryDateDisplayType.SHOW_AGGRESSIVE
                ? this.props.prediction.data.date_aggressive
                : this.props.prediction.data.date_conservative;
        const postcode = this.props.prediction.data.postcode;
        return (
            <>
                <PredictedDeliveryLoader />
                <Trans
                    fmtString={gettext(
                        "Arrives to <Location></Location> as soon as <Date></Date>. <HelpText></HelpText>",
                    )}
                    data={{
                        Location: (_, idx) => (
                            <span
                                aria-describedby={tipId}
                                aria-expanded={this.state.isTooltipOpen}
                                className={styles.zip}
                                data-for={tipId}
                                data-tip={true}
                                key={idx}
                                tabIndex={0}
                            >
                                {postcode}
                            </span>
                        ),
                        Date: (_, idx) => (
                            <span key={idx}>
                                {formatDate(displayDate, "MMMM do")}
                            </span>
                        ),
                        HelpText: (_, idx) => (
                            <span key={idx}>
                                {this.props.additionalHelpText}
                            </span>
                        ),
                    }}
                />
                <ReactTooltip
                    aria-label={gettext("Question about your order?")}
                    class={styles.tooltip}
                    event={"mouseover focus"}
                    eventOff="scroll mousewheel mouseleave mouseout blur"
                    id={tipId}
                    place="top"
                    role="tooltip"
                    isCapture={true}
                >
                    <span>
                        Need to change your delivery ZIP Code? Please return to
                        the “Shipping” section to update your address.
                    </span>
                </ReactTooltip>
            </>
        );
    }
}

const mapStateToProps: TStateMapper<"checkout", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    return {
        prediction: rootState.checkout.data.predicted_delivery_date,
        ...ownProps,
    };
};

export const PredictedDeliveryHelpText = connect(mapStateToProps)(
    PredictedDeliveryHelpTextComponent,
);

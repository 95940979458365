import React from "react";
import classNames from "classnames";
import SVG from "react-inlinesvg";
import { IWebPageURL } from "../../../models/nominals";
import { ICompareTile } from "../../../models/product-compare.interfaces";
import { ITileID } from "../../../models/product-compare";
import { SelectedProduct } from "../elements/SelectedProduct";
import { getSelectedVariant } from "../selectors";
import styles from "./SelectedProductTray.module.scss";
import iconSliderLeft from "../../../../img/icons/slider-left.svg";
import iconSliderRight from "../../../../img/icons/slider-right.svg";

interface IProps {
    selectedTiles: ICompareTile[];
    compareAllURI: string;
    priceSelectValue: string;
    productComparePageURL: IWebPageURL | null;
    onRemoveButtonClick: (tileID: ITileID) => void;
    onCollapseToggle: (e: React.MouseEvent<HTMLButtonElement>) => void;
    isExpanded: boolean;
    ariaLabel: string;
    numProductsAvailable: number;
    ctaText: string;
}

interface IState {
    activeIndex: number;
    isMobile: boolean;
    numColumns: number;
}

export class SelectedProductTray extends React.Component<IProps, IState> {
    public MOBILE_WIDTH_THRESHOLD = 501;
    public state: IState = {
        activeIndex: 0,
        isMobile: false,
        numColumns: 4,
    };

    componentDidMount() {
        this.checkMobileState();
        window.addEventListener("resize", this.checkMobileState);

        this.setState({
            numColumns:
                this.props.numProductsAvailable > 4
                    ? 4
                    : this.props.numProductsAvailable,
        });
    }

    private readonly checkMobileState = () => {
        const mobile: boolean = this.isMobileWidth() ? true : false;

        this.setState({
            isMobile: mobile,
        });
    };

    private readonly isMobileWidth = () => {
        return window.innerWidth < this.MOBILE_WIDTH_THRESHOLD;
    };

    private readonly onCollapseToggle = (
        e: React.MouseEvent<HTMLButtonElement>,
    ) => {
        this.props.onCollapseToggle(e);
    };

    private readonly onNextClick = () => {
        if (this.state.activeIndex < 1) {
            this.setState({
                activeIndex: this.state.activeIndex + 1,
            });
        }
    };

    private readonly onPrevClick = () => {
        if (this.state.activeIndex > 0) {
            this.setState({
                activeIndex: this.state.activeIndex - 1,
            });
        }
    };

    private buildCompareNowURI(baseURL: IWebPageURL) {
        const tileIDs = this.props.selectedTiles.map(
            (tile) => tile.product_compare_tile.id,
        );
        const returnURL = encodeURIComponent(window.location.pathname);
        const ids = encodeURIComponent(tileIDs.join(","));
        return `${baseURL}?tiles=${ids}&return_url=${returnURL}`;
    }

    private buildAttributes() {
        if (this.props.selectedTiles.length > 0) {
            return {
                ariaHidden: false,
            };
        } else {
            return {
                ariaHidden: true,
            };
        }
    }

    private buildSelectedProducts() {
        const displayArrows = this.props.selectedTiles.length > 2;
        const selectedProducts = this.props.selectedTiles.map((tile) => {
            const variant = getSelectedVariant(
                tile,
                this.props.priceSelectValue,
            );
            return (
                <SelectedProduct
                    key={`${tile.product_compare_tile.id}`}
                    data={tile}
                    selectedVariant={variant}
                    onRemoveButtonClick={this.props.onRemoveButtonClick}
                    priceSelectValue={this.props.priceSelectValue}
                />
            );
        });
        while (selectedProducts.length < this.state.numColumns) {
            const placeholderClasses = classNames({
                [styles.placeholder]: true,
                [styles.placeholderCollapsed]: !this.props.isExpanded,
            });
            selectedProducts.push(
                <div key={Math.random()} className={placeholderClasses}>
                    <span className="ada-screenreader-only">
                        {gettext("Compare product placeholder")}
                    </span>
                </div>,
            );
        }

        if (this.state.isMobile) {
            const sliderStyle = {
                transform: `translateX(${
                    displayArrows ? this.state.activeIndex * -100 : 0
                }%)`,
                transition: "0.2s",
            };

            const leftButtonClass = classNames({
                "left-button": true,
                "active": this.state.activeIndex,
            });

            const rightButtonClass = classNames({
                "right-button": true,
                "active": !this.state.activeIndex,
            });

            const arrows: JSX.Element = (
                <div className={styles.slideControls}>
                    <button
                        aria-label={gettext("View Previous")}
                        onClick={this.onPrevClick}
                    >
                        <SVG
                            aria-hidden="true"
                            className={leftButtonClass}
                            src={iconSliderLeft}
                        />
                    </button>
                    <button
                        aria-label={gettext("View Next")}
                        onClick={this.onNextClick}
                    >
                        <SVG
                            aria-hidden="true"
                            className={rightButtonClass}
                            src={iconSliderRight}
                        />
                    </button>
                </div>
            );

            return (
                <>
                    <div className={styles.slideContainer} style={sliderStyle}>
                        {selectedProducts}
                    </div>
                    {displayArrows && arrows}
                </>
            );
        }

        return selectedProducts;
    }

    render() {
        const attrs = this.buildAttributes();
        const toggleButtonText =
            this.props.ariaLabel.charAt(0).toUpperCase() +
            this.props.ariaLabel.substring(1);
        let button: JSX.Element | null = null;
        if (this.props.productComparePageURL) {
            button = (
                <a
                    href={this.buildCompareNowURI(
                        this.props.productComparePageURL,
                    )}
                    className={`${styles.navButton} button al-comparetray-comparenow`}
                >
                    {gettext("Compare Now")}
                </a>
            );
        }
        //let columnClassName = `columns-${this.state.numColumns}`;

        const productTrayClass = classNames({
            [styles.hidden]: attrs.ariaHidden,
            [styles.root]: !attrs.ariaHidden,
            [styles.collapsed]: !this.props.isExpanded,
            [styles.numColumns2]: this.state.numColumns === 2,
            [styles.numColumns3]: this.state.numColumns === 3,
        });
        return (
            <section
                className={productTrayClass}
                aria-expanded={this.props.isExpanded}
                aria-hidden={attrs.ariaHidden}
            >
                {this.buildSelectedProducts()}
                <nav>
                    {button}
                    {this.props.productComparePageURL && (
                        <a
                            href={this.props.compareAllURI}
                            className="al-comparetray-compareall"
                        >
                            {gettext(this.props.ctaText)}
                        </a>
                    )}
                    <button
                        type="button"
                        aria-label={this.props.ariaLabel}
                        className={`al-comparetray-${this.props.ariaLabel}`}
                        onClick={this.onCollapseToggle}
                    >
                        {toggleButtonText}
                    </button>
                </nav>
            </section>
        );
    }
}

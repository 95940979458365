import React from "react";
import { connect } from "react-redux";
import { TStateMapper } from "../../reducers.interfaces";
import { IFormUUID } from "../../../models/nominals";
import { WriteReviewFormMode } from "../constants";
import { getWriteReviewFormMode } from "../selectors";

interface IOwnProps {
    formUUID: IFormUUID;
}

interface IReduxProps {
    isOpen: boolean;
}

interface IProps extends IOwnProps, IReduxProps {}

interface IState {}

class WriteReviewThankYouComponent extends React.PureComponent<IProps, IState> {
    render() {
        if (!this.props.isOpen) {
            return null;
        }
        return (
            <div className="review-thank-you" role="alert">
                <h2 className="review-thank-you__title">
                    {gettext("Thank You for Writing a Review")}
                </h2>
                <p>
                    {gettext(
                        "We appreciate your feedback and comments, and always enjoy hearing from you.",
                    )}
                </p>
                <p>
                    {gettext("Return to the ")}
                    <a
                        title={gettext("Home")}
                        href="/"
                        className="review-thank-you__home"
                        target="_self"
                    >
                        {gettext("homepage")}
                    </a>
                    <span>.</span>
                </p>
            </div>
        );
    }
}

const mapStateToProps: TStateMapper<"reviews", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const displayMode = getWriteReviewFormMode(rootState.reviews, ownProps);
    return {
        ...ownProps,
        isOpen: displayMode === WriteReviewFormMode.THANK_YOU_OPEN,
    };
};

export const WriteReviewThankYou = connect(mapStateToProps)(
    WriteReviewThankYouComponent,
);

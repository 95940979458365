import { roundedMoney } from "../../utils/format";
import {
    IProduct,
    IOptionSingleValue,
} from "../../models/catalogue.interfaces";
import { sortProductOptions } from "../../utils/sorting";
import { intersection } from "../../utils/sets";
import { notEmpty } from "../../utils/functional";
import { asMultiValueOption } from "../../utils/catalogue";
import { GridFilterSelectionMode, PriceComparator } from "./models";
import {
    ISingleBoundPriceRange,
    IDualBoundPriceRange,
    IPriceRange,
    IBooleanAttribute,
    IGridFilterOptions,
    IFilterOption,
} from "./models.interfaces";

const isSingleBoundRange = (
    range: IPriceRange,
): range is ISingleBoundPriceRange => {
    return range.type === "single_bound_price_range";
};

export abstract class GridFilterConfig {
    public readonly filterID: string;
    public readonly label: string;
    public readonly selectionMode: GridFilterSelectionMode;

    constructor(opts: IGridFilterOptions) {
        this.filterID = opts.filter_id;
        this.label = opts.label;
        this.selectionMode =
            opts.selection_mode === undefined
                ? GridFilterSelectionMode.SELECT_MANY
                : opts.selection_mode;
    }

    public abstract listFilterOptions(products: IProduct[]): IFilterOption[];

    public abstract applyFilter(
        selectedOptionIDs: Set<string>,
        products: Iterable<IProduct>,
    ): Generator<IProduct>;
}

/**
 * Product Grid Filter which filters based on the values of a single product attribute.
 */
export class AttributeValueGridFilter extends GridFilterConfig {
    public listFilterOptions(products: IProduct[]): IFilterOption[] {
        const options = new Set<IOptionSingleValue>();
        const collectOption = (product: IProduct) => {
            if (!product.availability.is_available_to_buy) {
                return;
            }
            const attr = product.attributes[this.filterID];
            if (attr && attr.value) {
                const value = asMultiValueOption(attr.value);
                value.forEach((v) => {
                    options.add(v);
                });
            }
        };
        for (const product of products) {
            collectOption(product);
            for (const child of product.children) {
                collectOption(child);
            }
        }
        const sortedOptions = sortProductOptions(this.filterID, [...options]);
        return sortedOptions.map((opt) => {
            return {
                id: opt,
                label: opt,
            };
        });
    }

    public *applyFilter(
        selectedOptionIDs: Set<string>,
        products: Iterable<IProduct>,
    ): Generator<IProduct> {
        for (const product of products) {
            // Build a set of all of the values for the filtered attribute on this product (and it's children)
            const attrValues = new Set(
                product.children
                    .filter((child) => child.availability.is_available_to_buy)
                    .reduce<string[]>((memo, child) => {
                        const rawVal =
                            child.attributes[this.filterID]?.value || [];
                        const val = Array.isArray(rawVal)
                            ? rawVal.map((v) => `${v}`)
                            : `${rawVal}`;
                        return memo.concat(val);
                    }, []),
            );
            if (product.availability.is_available_to_buy) {
                const rawVal = product.attributes[this.filterID]?.value || [];
                const values = Array.isArray(rawVal)
                    ? rawVal.map((v) => `${v}`)
                    : [`${rawVal}`];
                for (const val of values) {
                    attrValues.add(val);
                }
            }
            // Figure out if this product matches any of the selected values
            const overlap = intersection(selectedOptionIDs, attrValues);
            // Include the product if there's any overlap OR if nothing is selected
            if (overlap.size > 0 || selectedOptionIDs.size <= 0) {
                yield product;
            }
        }
    }
}

/**
 * Product Grid Filter which filters based on the Product Class
 */
export class ProductClassGridFilter extends GridFilterConfig {
    private readonly labels: Map<string, string>;

    constructor(opts: IGridFilterOptions & { labels?: Map<string, string> }) {
        super(opts);
        this.labels = opts.labels || new Map();
    }

    public listFilterOptions(products: IProduct[]): IFilterOption[] {
        const productClassSlugs = new Set<string>();
        const collectOption = (product: IProduct) => {
            if (product.availability.is_available_to_buy) {
                productClassSlugs.add(product.product_class_slug);
            }
        };
        for (const product of products) {
            collectOption(product);
            for (const child of product.children) {
                collectOption(child);
            }
        }
        const collator = new Intl.Collator();
        const options = Array.from(productClassSlugs)
            .map((slug) => {
                return {
                    id: slug,
                    label: this.labels.get(slug) || slug.replace("-", " "),
                };
            })
            .sort((a, b) => {
                return collator.compare(a.label, b.label);
            });
        return options;
    }

    public *applyFilter(
        selectedOptionIDs: Set<string>,
        products: Iterable<IProduct>,
    ): Generator<IProduct> {
        for (const product of products) {
            // Include the product if the product class matches OR if nothing is selected
            if (
                selectedOptionIDs.has(product.product_class_slug) ||
                selectedOptionIDs.size <= 0
            ) {
                yield product;
            }
        }
    }
}

/**
 * Product Grid Filter which filters based on several predefined product price ranges
 */
export class PriceRangeGridFilter extends GridFilterConfig {
    private readonly ranges: IPriceRange[];

    constructor(opts: IGridFilterOptions & { ranges: IPriceRange[] }) {
        super(opts);
        this.ranges = opts.ranges;
    }

    public listFilterOptions(): IFilterOption[] {
        return this.ranges.map((range) => {
            return {
                id: range.value.id,
                label: isSingleBoundRange(range)
                    ? this.getSingleBoundRangeLabel(range)
                    : this.getDualBoundRangeLabel(range),
            };
        });
    }

    public *applyFilter(
        selectedOptionIDs: Set<string>,
        products: Iterable<IProduct>,
    ): Generator<IProduct> {
        const selectedRanges = this.ranges.filter((r) =>
            selectedOptionIDs.has(r.value.id),
        );
        for (const product of products) {
            // If either nothing is selected, or the product matches one of the selected ranges, include it.
            if (
                selectedOptionIDs.size <= 0 ||
                this.productMatchesOneOfRanges(selectedRanges, product)
            ) {
                yield product;
            }
        }
    }

    private getSingleBoundRangeLabel(range: ISingleBoundPriceRange) {
        const params = {
            price: roundedMoney(range.value.amount),
        };
        switch (range.value.comparator) {
            case PriceComparator.LT:
            case PriceComparator.LTE:
                return interpolate(gettext("under %(price)s"), params, true);

            case PriceComparator.GT:
            case PriceComparator.GTE:
                return interpolate(gettext("over %(price)s"), params, true);
        }
    }

    private getDualBoundRangeLabel(range: IDualBoundPriceRange) {
        const params = {
            lowerPrice: roundedMoney(range.value.lower_bound.amount),
            higherPrice: roundedMoney(range.value.upper_bound.amount),
        };
        return interpolate(
            gettext("%(lowerPrice)s to %(higherPrice)s"),
            params,
            true,
        );
    }

    private productMatchesOneOfRanges(
        ranges: IPriceRange[],
        product: IProduct,
    ) {
        for (const range of ranges) {
            const matches = isSingleBoundRange(range)
                ? this.productMatchesSingleBoundRange(range, product)
                : this.productMatchesDualBoundRange(range, product);
            if (matches) {
                return true;
            }
        }
        return false;
    }

    private getProductMinMaxPrice(product: IProduct) {
        const prices: number[] = [];
        if (product.price.cosmetic_excl_tax) {
            prices.push(parseFloat(product.price.cosmetic_excl_tax));
        }
        for (const child of product.children) {
            if (child.price.cosmetic_excl_tax) {
                prices.push(parseFloat(child.price.cosmetic_excl_tax));
            }
        }
        const minPrice = Math.min(...prices);
        const maxPrice = Math.max(...prices);
        return {
            minPrice,
            maxPrice,
        };
    }

    private productMatchesSingleBoundRange(
        range: ISingleBoundPriceRange,
        product: IProduct,
    ): boolean {
        const { minPrice, maxPrice } = this.getProductMinMaxPrice(product);
        return this.checkPriceComparator(
            minPrice,
            maxPrice,
            range.value.comparator,
            range.value.amount,
        );
    }

    private productMatchesDualBoundRange(
        range: IDualBoundPriceRange,
        product: IProduct,
    ): boolean {
        const { minPrice, maxPrice } = this.getProductMinMaxPrice(product);
        const lowerBoundMatch = this.checkPriceComparator(
            minPrice,
            maxPrice,
            range.value.lower_bound.comparator,
            range.value.lower_bound.amount,
        );
        const upperBoundMatch = this.checkPriceComparator(
            minPrice,
            maxPrice,
            range.value.upper_bound.comparator,
            range.value.upper_bound.amount,
        );
        return lowerBoundMatch && upperBoundMatch;
    }

    private checkPriceComparator(
        productMinPrice: number,
        productMaxPrice: number,
        comparator: PriceComparator,
        thresholdAmount: number,
    ): boolean {
        switch (comparator) {
            case PriceComparator.LT:
                return productMinPrice < thresholdAmount;
            case PriceComparator.LTE:
                return productMinPrice <= thresholdAmount;
            case PriceComparator.GT:
                return productMaxPrice > thresholdAmount;
            case PriceComparator.GTE:
                return productMaxPrice >= thresholdAmount;
        }
    }
}

/**
 * Product Grid Filter which filters based on a set of boolean products attribute, where each filter option is a
 * single product attribute.
 */
export class BooleanAttributeGridFilter extends GridFilterConfig {
    private readonly attributes: IBooleanAttribute[];

    constructor(
        opts: IGridFilterOptions & { attributes: IBooleanAttribute[] },
    ) {
        super(opts);
        this.attributes = opts.attributes;
    }

    public listFilterOptions(): IFilterOption[] {
        return this.attributes.map((attr) => {
            return {
                id: attr.attribute,
                label: attr.label,
            };
        });
    }

    public *applyFilter(
        selectedOptionIDs: Set<string>,
        products: Iterable<IProduct>,
    ): Generator<IProduct> {
        for (const product of products) {
            if (
                selectedOptionIDs.size <= 0 ||
                this.productMatchesOneOfAttribute(product, selectedOptionIDs)
            ) {
                yield product;
            }
        }
    }

    private productMatchesOneOfAttribute(
        product: IProduct,
        attributeNames: Set<string>,
    ) {
        for (const attributeName of attributeNames) {
            const values = this.getProductAttributeValues(
                product,
                attributeName,
            );
            for (const value of values) {
                if (value) {
                    return true;
                }
            }
        }
        return false;
    }

    private getProductAttributeValues(
        product: IProduct,
        attributeName: string,
    ) {
        const values = [
            product.attributes[attributeName]?.value,
            ...product.children.map(
                (child) => child.attributes[attributeName]?.value,
            ),
        ];
        return values.filter(notEmpty);
    }
}

import React from "react";
import { strings } from "../../../localization";
import { useAppDispatch, useAppSelector } from "../../reducers";
import { closeModal } from "../reducers";
import { ModalFullApplyDenied } from "../models";
import { DeniedMessage } from "./DeniedMessage";

import styles from "./index.module.scss";
import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";

interface IProps {
    modalState: ModalFullApplyDenied;
}

export const FinancingFullDenied = (props: IProps) => {
    const dispatch = useAppDispatch();
    const purchasePrice = useAppSelector(
        (state) => state.financing.purchasePrice,
    );
    const buttonText =
        (purchasePrice
            ? strings.get("FINANCING_RETURN_TO_CHECKOUT")
            : strings.get("FINANCING_RETURN_TO_SITE")) || "";

    const onClose = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        dispatch(closeModal());
    };

    return (
        <div className={styles.wrapper}>
            <h3
                className={styles.title}
                dangerouslySetInnerHTML={{
                    __html: gettext(
                        "We’re Sorry.<br />Your application has been denied.",
                    ),
                }}
            ></h3>
            {props.modalState.result && (
                <DeniedMessage
                    backendType={props.modalState.result?.backend_type}
                    modalState={props.modalState}
                />
            )}
            <button
                className={concatClassNames(["button", styles.button])}
                onClick={onClose}
            >
                {buttonText}
            </button>
        </div>
    );
};

import React from "react";
import { strings } from "../../../localization";

interface IProps {}

interface IState {}

export class FinePrint extends React.Component<IProps, IState> {
    render() {
        const financeCopy = {
            __html: strings.get("FINANCING_CHECKOUT_FINE_PRINT") || "",
        };
        return (
            <div
                className="financing-form__fine-print"
                dangerouslySetInnerHTML={financeCopy}
            ></div>
        );
    }
}

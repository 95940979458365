import React from "react";
import SVG from "react-inlinesvg";
import classNames from "classnames";
import { connect } from "react-redux";
import {
    IReviewsProductTypeID,
    IReviewsProductID,
    IReviewsProductVariantID,
    isoReviewsProductTypeID,
    isoReviewsProductID,
    isoReviewsProductVariantID,
} from "../../../models/nominals";
import {
    IReviewQuery,
    IReviewQueryFacets,
    ISearchFacet,
    IReviewsProduct,
    ISearchFacetSubOption,
    ISearchFacetOption,
} from "../../../models/reviews.interfaces";
import { parseFacetValues } from "../../../utils/reviews";
import { notEmpty } from "../../../utils/functional";
import { TStateMapper, TDispatchMapper } from "../../reducers.interfaces";
import { Dispatchers } from "../dispatchers";
import { defaultState } from "../defaults";
import styles from "./AppliedFilters.module.scss";
import iconXClose from "../../../../img/icons/x-close.svg";

type FacetOptionDictionary = {
    [key: number]: ISearchFacetOption;
};

type VariantOption = {
    product_id: number;
    option: ISearchFacetSubOption;
};

type VariantDictionary = {
    [key: number]: VariantOption;
};

type ProductTypeDictionary = {
    [key: number]: string;
};

interface IOwnProps {}

interface IReduxProps {
    products: IReviewsProduct[];
    productTypeIDs: IReviewsProductTypeID[];
    facets: ISearchFacet[];
    facetValues: IReviewQuery;
}

interface IDispatchProps {
    clearFilters: Dispatchers["clearFilters"];
    updateFilterOptionValue: Dispatchers["updateFilterOptionValue"];
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {
    productIDs: IReviewsProductID[];
    variantIDs: IReviewsProductVariantID[];
    ratings: number[];
    sourceIDs: number[];
    showFilterTags: boolean;
}

class AppliedFiltersComponent extends React.PureComponent<IProps, IState> {
    state: IState = {
        productIDs: [],
        variantIDs: [],
        ratings: [],
        sourceIDs: [],
        showFilterTags: false,
    };

    protected products: FacetOptionDictionary = {};
    protected variants: VariantDictionary = {};
    protected sources: FacetOptionDictionary = {};
    protected productTypes: ProductTypeDictionary = {};

    static getDerivedStateFromProps(nextProps: IProps, prevState: IState) {
        // Parse and store product IDs and variant IDs
        return {
            ...prevState,
            productIDs: parseFacetValues(nextProps.facetValues.product_id),
            variantIDs: parseFacetValues(
                nextProps.facetValues.product_variant_id,
            ),
            ratings: parseFacetValues(nextProps.facetValues.rating).reverse(),
            sourceIDs: parseFacetValues(nextProps.facetValues.source_id),
        };
    }

    componentDidMount() {
        // Construct a lookup table for Product and Variant IDs
        const productFacet = this.props.facets.find((facet) => {
            return facet.type === "product_id";
        });
        if (productFacet) {
            productFacet.options.forEach((option) => {
                this.products[option.option_id] = option;

                option.sub_options.forEach((sub_option) => {
                    const variantOption: VariantOption = {
                        product_id: option.option_id,
                        option: sub_option,
                    };
                    this.variants[sub_option.option_id] = variantOption;
                });
            });
        }

        // Construct lookup table for sources
        const sourceFacet = this.props.facets.find((facet) => {
            return facet.type === "source_id";
        });
        if (sourceFacet) {
            sourceFacet.options.forEach((option) => {
                this.sources[option.option_id] = option;
            });
        }

        // Construct lookup table for ProductType IDs
        this.props.products.map((product) => {
            if (product.product_type && product.product_type_name) {
                this.productTypes[
                    isoReviewsProductTypeID.unwrap(product.product_type)
                ] = product.product_type_name;
            }
        });
    }

    private readonly toggleFilterTags = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        this.setState((state) => {
            return {
                ...state,
                showFilterTags: !state.showFilterTags,
            };
        });
    };

    private readonly buildAppliedFilterTitle = () => {
        let totalAppliedFilters = this.props.productTypeIDs.length;
        totalAppliedFilters +=
            this.state.productIDs.length + this.state.variantIDs.length;
        totalAppliedFilters +=
            this.state.ratings.length + this.state.sourceIDs.length;
        const appliedFilterClasses = classNames({
            title: true,
            active: this.state.showFilterTags,
        });
        return (
            <div className={styles.appliedFilters}>
                <button
                    className={appliedFilterClasses}
                    onClick={this.toggleFilterTags}
                >
                    Applied Filters {`(${totalAppliedFilters})`}
                </button>
                <button
                    className="clear-button"
                    onClick={this.props.clearFilters}
                >
                    {gettext("Clear All")}
                </button>
            </div>
        );
    };

    private readonly buildFilterTag = (
        filterType: IReviewQueryFacets,
        uniqueID: number,
        filterText: string,
    ) => {
        return (
            <div className="tag" key={uniqueID}>
                <span
                    className="text"
                    dangerouslySetInnerHTML={{ __html: filterText }}
                ></span>
                <button
                    className="remove-button"
                    onClick={() => {
                        this.props.updateFilterOptionValue(
                            filterType,
                            uniqueID,
                            false,
                        );
                    }}
                >
                    <SVG className="icon" src={iconXClose} role="img" />
                </button>
            </div>
        );
    };

    private readonly buildProductTypeTags = () => {
        return this.props.productTypeIDs
            .filter(notEmpty)
            .map((productTypeID) => {
                const productType =
                    this.productTypes[
                        isoReviewsProductTypeID.unwrap(productTypeID)
                    ];
                if (productType) {
                    return this.buildFilterTag(
                        "product_type_id",
                        isoReviewsProductTypeID.unwrap(productTypeID),
                        productType,
                    );
                }
                return null;
            });
    };

    private readonly buildProductFilterTags = () => {
        return this.state.productIDs.filter(notEmpty).map((productID) => {
            const product =
                this.products[isoReviewsProductID.unwrap(productID)];
            if (product) {
                return this.buildFilterTag(
                    "product_id",
                    isoReviewsProductID.unwrap(productID),
                    product.name.toString(),
                );
            }
            return null;
        });
    };

    private readonly buildProductVariantFilterTags = () => {
        return this.state.variantIDs.filter(notEmpty).map((variantID) => {
            const variant =
                this.variants[isoReviewsProductVariantID.unwrap(variantID)];
            if (variant) {
                const productName =
                    this.products[variant.product_id].group_name;
                const filterText =
                    `${productName} - ${variant.option.name}`.toString();
                return this.buildFilterTag(
                    "product_variant_id",
                    isoReviewsProductVariantID.unwrap(variantID),
                    filterText,
                );
            }
            return null;
        });
    };

    private readonly buildRatingFilterTags = () => {
        return this.state.ratings.map((ratingValue) => {
            return this.buildFilterTag(
                "rating",
                ratingValue,
                `${ratingValue} Star rating`.toString(),
            );
        });
    };

    private readonly buildSourceFilterTags = () => {
        return this.state.sourceIDs.map((sourceID) => {
            const source = this.sources[sourceID];
            if (source) {
                return this.buildFilterTag(
                    "source_id",
                    sourceID,
                    this.sources[sourceID].name.toString(),
                );
            }
            return null;
        });
    };

    private readonly buildFilterTags = () => {
        if (!this.state.showFilterTags) {
            return null;
        }
        return (
            <div className={styles.filterTags}>
                {this.buildProductTypeTags()}
                {this.buildProductFilterTags()}
                {this.buildProductVariantFilterTags()}
                {this.buildRatingFilterTags()}
                {this.buildSourceFilterTags()}
            </div>
        );
    };

    render() {
        return (
            <>
                {this.buildAppliedFilterTitle()}
                {this.buildFilterTags()}
            </>
        );
    }
}

const mapStateToProps: TStateMapper<"reviews", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.reviews || defaultState;
    return {
        products: state.data.products,
        productTypeIDs: state.ui.productTypeIDs,
        facets: state.data.facets,
        facetValues: state.ui.facetValues,
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        clearFilters: dispatchers.clearFilters,
        updateFilterOptionValue: dispatchers.updateFilterOptionValue,
    };
};

export const AppliedFilters = connect(
    mapStateToProps,
    mapDispatchToProps,
)(AppliedFiltersComponent);

import React from "react";
import { connect } from "react-redux";
import { IReviewQueryFacets } from "../../../models/reviews.interfaces";
import { TStateMapper, TDispatchMapper } from "../../reducers.interfaces";
import { Dispatchers } from "../dispatchers";
import { TextFilterDisplay } from "./TextFilterDisplay";
import styles from "./TextFilter.module.scss";

const FACET_TYPE: IReviewQueryFacets = "text";

interface IOwnProps {
    placeholder?: string;
    maxKeywordsNumber?: number;
}

interface IReduxProps {}

interface IDispatchProps {
    updateFilterOptionValue: Dispatchers["updateFilterOptionValue"];
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {
    currentText: string;
}

class TextFilterComponent extends React.PureComponent<IProps, IState> {
    state: IState = {
        currentText: "",
    };

    private readonly onChange = (e: React.FormEvent<HTMLInputElement>) => {
        this.setState({
            currentText: e.currentTarget.value,
        });
    };

    private readonly onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const query = this.state.currentText.trim();
        if (!query) {
            return;
        }
        this.props.updateFilterOptionValue(FACET_TYPE, query, true);
        this.setState({
            currentText: "",
        });
    };

    render() {
        return (
            <>
                <div
                    key="text"
                    className={`${styles.search} customer-review__side-filters__search`}
                >
                    <div
                        className={`${styles.searchBar} customer-review__side-filters__search-bar`}
                    >
                        <form
                            action=""
                            className={`${styles.searchForm} customer-review__side-filters__search-form`}
                            onSubmit={this.onSubmit}
                        >
                            <div className="floating-label using-placeholder-shown">
                                <span className="ada-screenreader-only">
                                    {gettext(
                                        "Activating this element will cause content on the page to be updated",
                                    )}
                                </span>
                                <input
                                    className={`${styles.searchText} customer-review__side-filters__search-text`}
                                    id="text"
                                    type="text"
                                    name="text"
                                    onChange={this.onChange}
                                    value={this.state.currentText}
                                    placeholder={" "}
                                />
                                <label htmlFor="text">
                                    {!this.props.placeholder
                                        ? gettext("Search Keywords")
                                        : this.props.placeholder}
                                </label>
                            </div>
                            <button
                                className={`${styles.searchSubmit} customer-review__side-filters__search-submit`}
                                type="submit"
                                aria-label={gettext("Search")}
                            >
                                <span
                                    aria-hidden="true"
                                    className={`${styles.searchIcon} customer-review__side-filters__search-submit__icon`}
                                ></span>
                            </button>
                        </form>
                    </div>
                </div>
                <TextFilterDisplay
                    maxKeywordsNumber={this.props.maxKeywordsNumber}
                />
            </>
        );
    }
}

const mapStateToProps: TStateMapper<"reviews", IReduxProps, IOwnProps> = (
    _rootState,
    ownProps,
) => {
    return {
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        updateFilterOptionValue: dispatchers.updateFilterOptionValue,
    };
};

export const TextFilter = connect(
    mapStateToProps,
    mapDispatchToProps,
)(TextFilterComponent);

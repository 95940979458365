import React from "react";
import { FinancingPreQualResponse } from "../../../models/financing";
import { money, formatAPR } from "../../../utils/format";

import styles from "./OfferDetails.module.scss";

export const OfferDetails = (props: { offer: FinancingPreQualResponse }) => (
    <div className={styles.container}>
        <dl>
            <div>
                <dt>{gettext("Credit Limit:")}</dt>
                <dd>{money(props.offer.credit_limit)}</dd>
            </div>
            <div>
                <dt>{gettext("Annual Percentage Rate:")}</dt>
                <dd>{formatAPR(props.offer.apr)}</dd>
            </div>
            <div>
                <dt>{gettext("Estimated Payoff:")}</dt>
                <dd>
                    {interpolate(gettext("%s Months*"), [
                        `${props.offer.full_duration}`,
                    ])}
                </dd>
            </div>
        </dl>
        <p>
            <strong>{gettext("*Estimated Payoff Disclosure:")}</strong>
            <br />
            {gettext(
                "Minimum purchase of $1000 or more required per transaction.",
            )}
        </p>
        <p>
            {gettext(
                `Estimated Payoff shows how long it will take to pay off your
                purchase if you make your minimum payments by the due date each
                month.`,
            )}
        </p>
    </div>
);

import React from "react";
import Modal from "react-modal";
import {
    IAddress,
    IBillingAddress,
    IShippingAddress,
} from "../../../models/address.interfaces";
import { IReduxFormState } from "../reducers.interfaces";
import { focusElement } from "../../../utils/keyboardFocus";
import SVG from "react-inlinesvg";

import styles from "./AddressSuggestionModal.module.scss";
import iconCircleClose from "../../../../img/icons/circle-close.svg";

interface IProps {
    form: IReduxFormState;
    onContinue: () => void;
    onEdit: () => void;
    showAddressWarningModal: boolean;
    addressPrefix: string;
}

interface IState {}

export class AddressWarningModal extends React.Component<IProps, IState> {
    public state: IState = {};

    render() {
        const modalStyle: Modal.Styles = {
            content: {
                position: "absolute",
                top: "50%",
                bottom: "auto",
                left: "50%",
                right: "auto",
                transform: "translate(-50%, -50%)",
                width: "400px",
                padding: "30px",
                overflow: "auto",
            },
        };

        const formAddress: Partial<IAddress> = [
            "first_name",
            "last_name",
            "line1",
            "line2",
            "line4",
            "state",
            "postcode",
        ].reduce((address, field) => {
            const key = (this.props.addressPrefix + "_" + field) as keyof (
                | IShippingAddress
                | IBillingAddress
            );
            return { ...address, [field]: this.props.form[key] };
        }, {});

        let givenLine2: JSX.Element | null = null;
        if (formAddress.line2) {
            givenLine2 = (
                <span className="address-suggestion-model__address-line">
                    {formAddress.line2}
                </span>
            );
        }

        return (
            <Modal
                aria={{ modal: true }}
                contentLabel={gettext("Address Verification")}
                style={modalStyle}
                isOpen={this.props.showAddressWarningModal}
                onRequestClose={() => {
                    this.props.onEdit();
                }}
                onAfterOpen={() => {
                    focusElement(`.${styles.closeButton}`);
                }}
                role="dialog"
            >
                <button
                    className={styles.closeButton}
                    aria-label={gettext("close")}
                    onClick={() => {
                        this.props.onEdit();
                    }}
                >
                    <SVG
                        aria-hidden="true"
                        className={styles.closeIcon}
                        src={iconCircleClose}
                        title={gettext("Close Icon")}
                    />
                </button>
                <div className="address-warning-model">
                    <h2 className={styles.header}>
                        {gettext("Please verify your address")}
                    </h2>
                    <p
                        dangerouslySetInnerHTML={{
                            __html: gettext(
                                "Our system doesn’t recognize this address.<br />Please confirm:",
                            ),
                        }}
                    ></p>
                    <ul>
                        <li>{gettext("The house number is accurate")}</li>
                        <li>{gettext("There are no special characters")}</li>
                        <li>{gettext("The city name is not abbreviated")}</li>
                    </ul>
                    <p className="address-suggestion-model__address">
                        <span className={styles.addressLine}>
                            {formAddress.first_name} {formAddress.last_name}
                        </span>
                        <span className={styles.addressLine}>
                            {formAddress.line1}
                        </span>
                        {givenLine2}
                        <span className={styles.addressLine}>
                            {formAddress.line4}, {formAddress.state}{" "}
                            {formAddress.postcode}
                        </span>
                    </p>
                    <button
                        className={`${styles.continueButton} button`}
                        onClick={this.props.onContinue}
                    >
                        <span>{gettext("CONTINUE AS IS")}</span>
                    </button>
                    <button
                        className={`${styles.editButton} button button--secondary`}
                        onClick={this.props.onEdit}
                    >
                        <span>{gettext("EDIT")}</span>
                    </button>
                </div>
            </Modal>
        );
    }
}

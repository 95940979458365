import React from "react";
import { IProductFlair } from "../models/product-compare.interfaces";
import { RichTextBlock } from "@thelabnyc/thelabui/src/components/RichText";
import classNames from "classnames";
import styles from "./flair.module.scss";

interface IProps {
    flair: IProductFlair;
    inline?: boolean;
}

export const Flair = (props: IProps) => {
    if (!props.flair.content) {
        return null;
    }
    let TagName: keyof React.ReactHTML = "div";
    if (props.inline) {
        TagName = "span";
    }

    const propsFlairStyle = props.flair.style ? props.flair.style : "";

    const flairClasses = classNames({
        [styles.base]: true,
        flair: true,
        [propsFlairStyle]: true,
    });

    return (
        <TagName className={flairClasses}>
            <TagName className="icon"></TagName>
            <RichTextBlock value={props.flair.content as string} />
        </TagName>
    );
};

export default Flair;

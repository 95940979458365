/* eslint-disable @typescript-eslint/no-shadow */
export enum Action {
    SELECT_BUDGET = "mattressmatch/SELECT_BUDGET",
    SELECT_WEIGHT = "mattressmatch/SELECT_WEIGHT",
    SELECT_HEIGHT = "mattressmatch/SELECT_HEIGHT",
    SELECT_OPTION = "mattressmatch/SELECT_OPTION",
    SELECT_SIZE = "mattressmatch/SELECT_SIZE",
    SELECT_COOLNESS = "mattressmatch/SELECT_COOLNESS",
    NAVIGATE = "mattressmatch/NAVIGATE",
    TOGGLE = "mattressmatch/TOGGLE",
    LOADED_RESULTS = "mattressmatch/LOADED_RESULTS",
}
export enum Mattress_Size {
    TWIN = "twin",
    FULL = "full",
    QUEEN = "queen",
    KING = "king",
    CAL_KING = "california king",
    CAL_SPLIT_KING = "california split king",
}
export enum Firmness {
    FIRM = "firm",
    MEDIUM = "medium",
    MEDIUM_HYBRID = "medium_hybrid",
    SOFT = "soft",
}
export enum Price_Level {
    BUDGET = "budget",
    BALANCED = "balanced",
    LIMITLESS = "limitless",
}
export enum Height {
    HEIGHT_5FT = "lt_5ft",
    HEIGHT_5_6FT = "eq_5_6ft",
    HEIGHT_6FT = "gt_6ft",
}
export enum Weight {
    WEIGHT_150LBS = "lt_150lbs",
    WEIGHT_150_200LBS = "eq_150_200lbs",
    WEIGHT_250LBS = "gt_250lbs",
}
export enum Coolness {
    NEVER = "never",
    SOMETIMES = "sometimes",
    ALWAYS = "always",
}
export const mattressSpecsFromName = (size: Mattress_Size) => {
    switch (size) {
        case Mattress_Size.TWIN:
            return { width: "38", height: "74" };
        case Mattress_Size.FULL:
            return { width: "53", height: "74" };
        case Mattress_Size.QUEEN:
            return {
                width: "60",
                height: "80",
            };
        case Mattress_Size.KING:
            return {
                width: "76",
                height: "80",
            };
        case Mattress_Size.CAL_KING:
            return {
                width: "72",
                height: "84",
            };
        case Mattress_Size.CAL_SPLIT_KING:
            return {
                width: "72",
                height: "84",
            };
    }
};
export enum RECOMMENDATIONS {
    BACK = "Back Sleepers",
    STOMACH = "Stomach Sleepers",
    SIDE = "Side Sleepers",
}
export const FIRMNESS = [
    {
        level: 1,
        value: Firmness.FIRM,
        label: gettext("Firm"),
        recommendations: [RECOMMENDATIONS.BACK],
        description:
            'Our firm feel features a solid, "on top of the bed" feeling all night long.',
        result: "If you prefer a firmer feel, a firm will provide pressure relief with solid support.",
    },
    {
        level: 2,
        value: Firmness.MEDIUM,
        label: gettext("Medium"),
        recommendations: [
            RECOMMENDATIONS.STOMACH,
            RECOMMENDATIONS.BACK,
            RECOMMENDATIONS.SIDE,
        ],
        description:
            "Our medium feel works to support and cradle your body all night long.",
        result: "If you prefer a medium feel, a medium will provide the conforming comfort you need while supporting your pressure points.",
    },
    {
        level: 3,
        value: Firmness.MEDIUM_HYBRID,
        label: gettext("Medium Hybrid"),
        recommendations: [RECOMMENDATIONS.STOMACH],
        description:
            "Our medium hybrid feel combines adaptive TEMPUR® Material and 1000+ premium coils for more effortless movement.",
        result: "If you prefer a softer feel, a medium-hybrid will provide soothing softness while supporting your pressure points.",
    },
    {
        level: 4,
        value: Firmness.SOFT,
        label: gettext("Soft"),
        recommendations: [RECOMMENDATIONS.SIDE],
        description:
            "Our softest feel allows the mattress to adjust easier to your shape for a conforming feel all night.",
        result: "If you prefer a softer feel, our soft will adjust easier to your shape for a conforming feel all night.",
    },
];
export const BUDGET = [
    {
        level: 1,
        value: Price_Level.BUDGET,
        icon: "budget-piggy",
        description:
            "We know you value your sleep, so we'll build your sleep solution with our iconic innovations.",
        label: "On a Budget",
    },
    {
        level: 2,
        value: Price_Level.BALANCED,
        icon: "budget-balance",
        description:
            "Getting sleep is essential, so we'll build your sleep solution with our more advanced models.",
        label: "A Good Balance",
    },
    {
        level: 3,
        value: Price_Level.LIMITLESS,
        icon: "budget-forever",
        description:
            "Sleep is your number one priority, so your sleep solution will feature our most premium options.",
        label: "Limitless",
    },
];
export const COOLNESS = [
    {
        level: 1,
        value: Coolness.NEVER,
        valid: false,
        label: "Never",
        icon: "cooling-no-heat",
        description: "",
    },
    {
        level: 2,
        value: Coolness.SOMETIMES,
        valid: false,
        label: "Sometimes",
        icon: "cooling-mild",
        description:
            "Warm sleeper? No problem. We’ll build your personalized sleep solution to feel up to 3° cooler.",
    },
    {
        level: 3,
        value: Coolness.ALWAYS,
        valid: true,
        label: "Always",
        icon: "cooling-hot",
        description:
            "Sleep hot? No problem. We’ll build your personalized sleep solution to feel up to 8° cooler.",
    },
];

export const HEIGHT = [
    { level: 1, value: Height.HEIGHT_5FT, label: "- 5FT", icon: "short" },
    { level: 2, value: Height.HEIGHT_5_6FT, label: "5-6 FT", icon: "height" },
    { level: 3, value: Height.HEIGHT_6FT, label: "6FT +", icon: "tall" },
];

export const WEIGHT = [
    {
        level: 1,
        value: Weight.WEIGHT_150LBS,
        label: "- 150 lbs",
        icon: "light",
    },
    {
        level: 2,
        value: Weight.WEIGHT_150_200LBS,
        label: "150-250 lbs",
        icon: "weight",
    },
    {
        level: 3,
        value: Weight.WEIGHT_250LBS,
        label: "250 lbs +",
        icon: "heavy",
    },
];

export enum Step {
    SIZE,
    FEEL,
    BODY_TYPE,
    COOL,
    BUDGET,
    RESULTS,
}

export const getStepNameFromIdx = (step: number): string => {
    switch (step) {
        case Step.SIZE:
            return "Size";
        case Step.FEEL:
            return "Firmness";
        case Step.BODY_TYPE:
            return "Body Type";
        case Step.COOL:
            return "Coolness";
        case Step.BUDGET:
            return "Budget";
        case Step.RESULTS:
            return "Results";
        default:
            return "";
    }
};
export const stepArrayIdxs = Object.keys(Step)
    .map((x) => parseInt(x, 10))
    .filter((x) => !isNaN(x));

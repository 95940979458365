import React from "react";

import styles from "./index.module.scss";
import { FinancingPortalClose } from "../FinancingPortal";
import SVG from "react-inlinesvg";
import lockIcon from "../../../../img/icons/lock-icon.svg";

export const FinancingModalHeader = (props: { title: string }) => {
    return (
        <header className={styles.root}>
            <div>
                <SVG
                    aria-hidden="true"
                    className={styles.headerImage}
                    src={lockIcon}
                    title={gettext(
                        "Credit Card Application icon, displayed as a lock",
                    )}
                />
                <h1 className={styles.heading}>{props.title}</h1>
            </div>
            <FinancingPortalClose className={styles.button}>
                {gettext("Close")}
            </FinancingPortalClose>
        </header>
    );
};

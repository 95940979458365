import React from "react";
import SVG from "react-inlinesvg";
import { ReviewSnippet } from "../interfaces";
import { CategoriesFooter } from "./CategoriesFooter";
import iconsStar from "../../../../img/icons/star.svg";
import iconsStarOutline from "../../../../img/icons/star-outline.svg";

interface Props {
    data: ReviewSnippet;
}

export const ReviewSnippetTile = React.forwardRef(
    (props: Props, primaryLinkRef: React.Ref<HTMLAnchorElement>) => {
        const review = props.data.value;
        return (
            <>
                <div className="post-review">
                    <section className="post-copy">
                        <div className="star-rating">
                            <div className="star-rating__stars">
                                <span
                                    className="ada-screenreader-only"
                                    aria-label="star rating"
                                >
                                    {interpolate(
                                        gettext("Rated %s out of 5 stars"),
                                        [review.rating.toString()],
                                    )}
                                </span>
                                {[1, 2, 3, 4, 5].map((num) => (
                                    <SVG
                                        key={num}
                                        role="presentation"
                                        className="star-rating__star"
                                        src={
                                            review.rating >= num
                                                ? iconsStar
                                                : iconsStarOutline
                                        }
                                        aria-hidden="true"
                                    />
                                ))}
                            </div>
                        </div>
                        <h2 className="post-review__title">{review.title}</h2>

                        <hr className="post-review__hr" />

                        <div
                            className="post-copy__body"
                            dangerouslySetInnerHTML={{ __html: review.content }}
                        />

                        <a
                            href={review.link_url}
                            ref={primaryLinkRef}
                            className="post-link"
                        >
                            {gettext("Read all reviews")}
                        </a>

                        <CategoriesFooter
                            categories={review.categories.map((category) => {
                                if (category.slug === "reviews") {
                                    return {
                                        ...category,
                                        override_url: review.link_url,
                                    };
                                } else {
                                    return category;
                                }
                            })}
                        />
                    </section>
                </div>
            </>
        );
    },
);

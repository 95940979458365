import React from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import { TStateMapper, TDispatchMapper } from "../../reducers.interfaces";
import { defaults } from "../defaults";
import { Dispatchers } from "../dispatchers";
import { isoProductID } from "../../../models/nominals";
import { IProduct } from "../../../models/catalogue.interfaces";
import { IModularConfiguratorOptionSet } from "../models.interfaces";
import { rootProductSelector } from "../selectors";
import { getDefaultRootProduct } from "../utils";

interface IOwnProps {
    optionSet: IModularConfiguratorOptionSet;
}

interface IReduxProps {
    rootProducts: IProduct[];
    selectedRootProduct: IProduct | null;
}

interface IDispatchProps {
    onSelectRootProduct: (product: IProduct) => void;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {}

class DropdownRootProductSelectorComponent extends React.PureComponent<
    IProps,
    IState
> {
    private readonly onChange = (e: React.FormEvent<HTMLSelectElement>) => {
        e.preventDefault();
        const rootProductID = isoProductID.wrap(
            parseInt(e.currentTarget.value, 10),
        );
        const rootProduct = this.props.rootProducts.find(
            (p) => p.id === rootProductID,
        );
        if (rootProduct) {
            this.props.onSelectRootProduct(rootProduct);
        }
    };

    private buildOptions(products: IProduct[]) {
        return products.map((rootProduct) => (
            <option
                key={`${rootProduct.id}`}
                value={isoProductID.unwrap(rootProduct.id)}
            >
                {rootProduct.title}
            </option>
        ));
    }

    render() {
        if (this.props.rootProducts.length <= 1) {
            return null;
        }
        const htmlID = "rootProduct";
        const value = this.props.selectedRootProduct?.id
            ? this.props.selectedRootProduct.id
            : getDefaultRootProduct(this.props.rootProducts).id;
        const options = this.buildOptions(this.props.rootProducts);
        const configuratorClass = classNames({
            "configurator__select-container": true,
            [`configurator__select-container--${this.props.optionSet.layout.design}`]:
                true,
        });
        const prefixClass = classNames({
            configurator__prefix: true,
            [`configurator__prefix--${this.props.optionSet.layout.design}`]:
                true,
        });
        const selectClass = classNames({
            configurator__select: true,
            [`configurator__select--${this.props.optionSet.layout.design}`]:
                true,
        });
        return (
            <div className="configurator__option-container">
                <label htmlFor={htmlID} className={prefixClass}>
                    {this.props.optionSet.root_product_selector.label}
                </label>
                <div className={configuratorClass}>
                    <span className="ada-screenreader-only">
                        {gettext(
                            "Activating these elements will cause content on the page to be updated.",
                        )}
                    </span>
                    <select
                        id={htmlID}
                        value={isoProductID.unwrap(value)}
                        onChange={this.onChange}
                        className={selectClass}
                    >
                        {options}
                    </select>
                </div>
            </div>
        );
    }
}

const mapStateToProps: TStateMapper<"configurator", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.configurator || defaults;
    return {
        rootProducts: state.entities.rootProducts,
        selectedRootProduct: rootProductSelector(state),
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        onSelectRootProduct: (product) => {
            dispatchers.setSelectedRootProduct(product);
        },
    };
};

export const DropdownRootProductSelector = connect(
    mapStateToProps,
    mapDispatchToProps,
)(DropdownRootProductSelectorComponent);

import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../reducers";
import { FinancingValueProp } from "../../../models/financing";
import { IReduxFormState as ICheckoutForm } from "../../checkout/reducers.interfaces";
import { openPreQualModal, openApplyModal } from "../reducers";
import { trackEvent } from "../../../utils/analytics";
import { PrefillData } from "../models";

type BtnProps = React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
>;

type ModalType = "prequal-app" | "full-app";

interface Props extends BtnProps {
    modalType: ModalType;
    prefillData?: PrefillData;
    // Props for opening/customizing the custom apply form modal
    purchasePrice?: number;
    applicationSource?: string;
    valueProps?: FinancingValueProp[];
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const sendTrackingEvents = (modalType: ModalType) => {
    if (modalType === "prequal-app") {
        trackEvent("Financing", "renderPreQualModalButton", undefined, {
            nonInteraction: true,
        });
    } else {
        trackEvent("Financing", "renderApplyFormModalButton", undefined, {
            nonInteraction: true,
        });
    }
};

const getPrefillData = (
    checkoutFormData: ICheckoutForm,
): PrefillData | undefined => {
    return {
        first_name: checkoutFormData.billing_first_name,
        last_name: checkoutFormData.billing_last_name,
        email: checkoutFormData.guest_email,
        address_line_1: checkoutFormData.billing_line1,
        address_line_2: checkoutFormData.billing_line2,
        city: checkoutFormData.billing_line4,
        state_code: checkoutFormData.billing_state,
        postal_code: checkoutFormData.billing_postcode
            .replace(/[^0-9]/g, "")
            .slice(0, 5),
        home_phone: checkoutFormData.billing_phone_number,
        mobile_phone: checkoutFormData.billing_phone_number,
    };
};

export const FinancingModalTrigger = (
    props: React.PropsWithChildren<Props>,
) => {
    const {
        modalType,
        prefillData: maybePrefillData,
        purchasePrice,
        applicationSource,
        valueProps,
        onClick,
        ...btnProps
    } = props;

    // On first mount, track that we rendered this button
    useEffect(() => {
        sendTrackingEvents(modalType);
    }, []);

    // Setup click handlers
    const dispatch = useAppDispatch();
    const checkoutFormData = useAppSelector((state) => state.checkout.form);

    const onOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        // Bubble up the event
        if (onClick) {
            onClick(e);
        }
        const prefillData =
            maybePrefillData ?? getPrefillData(checkoutFormData);
        // Open the modal
        if (modalType === "prequal-app") {
            dispatch(openPreQualModal({ prefillData }));
        } else {
            dispatch(
                openApplyModal({
                    purchasePrice,
                    applicationSource,
                    valueProps,
                    prefillData,
                }),
            );
        }
    };

    return (
        <button {...btnProps} onClick={onOpen}>
            {props.children}
        </button>
    );
};

import React from "react";
import {
    getOptionValueHash,
    isProductPriceDiscounted,
    PriceType,
    getProductPrice,
} from "../../../utils/catalogue";
import { IWebPageURL } from "../../../models/nominals";
import { Image } from "../../../common/Image";
import { RichText } from "../../../common/RichText";
import { assertNever } from "../../../utils/never";
import {
    IAttributeOptionGroup,
    ICompareTile,
    IProductTileAttribute,
    IProductTileAttributeRow,
} from "../../../models/product-compare.interfaces";
import { HighlightedAttribute } from "./HighlightedAttribute";
import { ProductCompareSelectionTile } from "../containers/ProductCompareSelectionTile";
import { ProductCompareUpgradeTile } from "../containers/ProductCompareUpgradeTile";
import { getSelectedVariant } from "../selectors";
import { MicroConfiguratorRowContent } from "./MicroConfiguratorRowContent";
import { ShopNowLink } from "./ShopNowLink";
import Flair from "../../../common/Flair";
import { roundedMoney } from "../../../utils/format";
import { IProduct } from "../../../models/catalogue.interfaces";
import { InternalProduct } from "../../../models/catalogue";
import styles from "./ProductCompareTileAttributeRow.module.scss";
import { OptionTypes, ProductCompareTheme } from "../../../constants";
import classNames from "classnames";
import { listPossibleOptionValues } from "../../../utils/sorting";
import { ProductCompareTileCTA } from "./ProductCompareTileCTA";
import urls from "../../../utils/urls";
import { setShowStartingAt } from "../utility";
import { getPageSetting } from "../../../utils/settings";

interface IProps {
    row: IProductTileAttributeRow;
    data: ICompareTile;
    basketLink?: IWebPageURL;
    financingLink?: IWebPageURL;
    theme?: ProductCompareTheme;
    priceSelectValue?: string;
    showFirstAvailablePrice?: boolean;
    rootProducts?: IProduct[];
    showStartingAt?: boolean;
    sizeAttributeOptionGroup: IAttributeOptionGroup | null;
    highestAttributeCount?: number;
}

const getFirstAvailableVariant = (
    data: ICompareTile,
    rootProducts?: IProduct[],
): InternalProduct | null | undefined => {
    const rootProduct = rootProducts?.find(
        (p) => p.id === data.product_compare_tile.product_id,
    );
    if (!rootProduct) {
        return null;
    }
    const possibleOptions = listPossibleOptionValues(
        OptionTypes.SIZE,
        rootProduct,
    );

    // Get first non-null variant - lowest price
    return getSelectedVariant(data, possibleOptions[0]);
};

const AttributeList = (props: IProps) => {
    const items = props.row.attributes?.map(
        (attribute: IProductTileAttribute) => {
            if (attribute.show_attribute) {
                return (
                    <li key={getOptionValueHash(attribute.name)}>
                        <HighlightedAttribute
                            attribute={attribute.name}
                            icon={attribute.icon}
                        />
                    </li>
                );
            }
            return null;
        },
    );
    return <>{items}</>;
};

const AttributeGrid = (props: IProps) => {
    const items = props.row.attributes?.map(
        (attribute: IProductTileAttribute) => (
            <li key={getOptionValueHash(attribute.name)}>
                <span>{attribute.name}</span>
                {attribute.show_attribute && attribute.icon && (
                    <Image src={attribute.icon.url} alt={gettext("")} />
                )}
            </li>
        ),
    );
    return <>{items}</>;
};

const AttributeListRow = (props: IProps) => {
    const attributeCellClasses = classNames({
        "attribute-cell": true,
        [styles.attributeCell]: props.theme === "",
        [styles.attributeCellThemeStepUp]:
            props.theme === ProductCompareTheme.STEP_UP,
        [styles.attributeCellThemeSelect]:
            props.theme === ProductCompareTheme.SELECT,
    });

    // Assign a class to adjust container height for 'sealy' Construction rows with over 2 Attributes.
    const contentClasses =
        getPageSetting("app-slug") === "sealy" &&
        props.row.row_type.title === "Construction" &&
        !!props.highestAttributeCount &&
        props.highestAttributeCount > 2 &&
        `attribute-count-${props.highestAttributeCount}`;
    return (
        <div className={attributeCellClasses}>
            <h5 className="ada-screenreader-only">
                {props.row.row_type.title}
            </h5>
            <ul className={`content ${contentClasses}`}>
                {props.row.row_type.group_title && (
                    <RichText html={props.row.row_type.group_title} />
                )}
                <AttributeList {...props} />
            </ul>
        </div>
    );
};

const AttributeGridRow = (props: IProps) => {
    const attributeCellClasses = classNames({
        "attribute-cell": true,
        [styles.attributeCell]: props.theme === "",
        [styles.attributeCellThemeStepUp]:
            props.theme === ProductCompareTheme.STEP_UP,
        [styles.attributeCellThemeSelect]:
            props.theme === ProductCompareTheme.SELECT,
    });
    return (
        <div className={attributeCellClasses}>
            <h5 className="ada-screenreader-only">
                {props.row.row_type.title}
            </h5>
            <ul className="content">
                <AttributeGrid {...props} />
            </ul>
        </div>
    );
};

const ProductSelectionRow = (props: IProps) => {
    const attributeCellClasses = classNames({
        "attribute-cell": true,
        [styles.attributeCellProductSelect]: true,
        [styles.attributeCellThemeStepUp]:
            props.theme === ProductCompareTheme.STEP_UP,
        [styles.attributeCellThemeSelect]:
            props.theme === ProductCompareTheme.SELECT,
    });
    return (
        <div className={attributeCellClasses}>
            <h5 className="ada-screenreader-only">
                {props.row.row_type.title}
            </h5>
            <ProductCompareSelectionTile tile={props.data} />
        </div>
    );
};

const MicroConfiguratorRow = (props: IProps) => {
    const attributeCellClasses = classNames({
        "attribute-cell": true,
        [styles.attributeCell]: true,
        "microconfigurator": true,
        [styles.attributeCellThemeStepUp]:
            props.theme === ProductCompareTheme.STEP_UP,
        [styles.attributeCellThemeSelect]:
            props.theme === ProductCompareTheme.SELECT,
    });
    return (
        <div className={attributeCellClasses}>
            <h5 className="ada-screenreader-only">
                {props.row.row_type.title}
            </h5>
            <MicroConfiguratorRowContent
                rootProductIDs={props.data.product_compare_tile.product_ids}
                basketLink={props.basketLink}
                financingLink={props.financingLink}
            />
        </div>
    );
};

const RichTextRow = (props: IProps) => {
    const attributeCellClasses = classNames({
        "attribute-cell": true,
        [styles.attributeCellRichText]: true,
        [styles.attributeCellThemeStepUp]:
            props.theme === ProductCompareTheme.STEP_UP,
        [styles.attributeCellThemeSelect]:
            props.theme === ProductCompareTheme.SELECT,
    });

    return (
        <div className={attributeCellClasses}>
            {props.row.flair && <Flair flair={props.row.flair} />}
            <h5 className="ada-screenreader-only">
                {props.data.product_compare_tile.name} -{" "}
                {props.row.row_type.title}
            </h5>
            <RichText className="content" html={props.row.richtext || ""} />
        </div>
    );
};

const UpgradeRow = (props: IProps) => {
    const attributeCellClasses = classNames({
        "attribute-cell": true,
        [styles.attributeCell]: props.theme === "",
        [styles.attributeCellThemeStepUp]:
            props.theme === ProductCompareTheme.STEP_UP,
        [styles.attributeCellThemeSelect]:
            props.theme === ProductCompareTheme.SELECT,
    });

    return (
        <div className={attributeCellClasses}>
            {props.row.flair && <Flair flair={props.row.flair} />}
            <h5 className="ada-screenreader-only">
                {props.data.product_compare_tile.name} -{" "}
                {props.row.row_type.title}
            </h5>
            <RichText className="content" html={props.row.richtext || ""} />
            <ProductCompareUpgradeTile tile={props.data} />
        </div>
    );
};

const DynamicPriceRow = (props: IProps) => {
    const attributeCellClasses = classNames({
        "attribute-cell": true,
        [styles.attributeCellDynamicPrice]: true,
        [styles.attributeCellThemeStepUp]:
            props.theme === ProductCompareTheme.STEP_UP,
        [styles.attributeCellThemeSelect]:
            props.theme === ProductCompareTheme.SELECT,
    });
    const variant =
        props.showFirstAvailablePrice && props.data.rootProducts
            ? getFirstAvailableVariant(props.data, props.data.rootProducts)
            : getSelectedVariant(props.data, props.priceSelectValue || "");
    if (variant) {
        const isDiscounted = isProductPriceDiscounted(variant.price);
        const isDiscountedClassNames = isDiscounted ? "accent" : "";
        return (
            <div className={attributeCellClasses}>
                {props.row.flair && <Flair flair={props.row.flair} />}
                <h5 className="ada-screenreader-only">
                    {props.data.product_compare_tile.name} -{" "}
                    {props.row.row_type.title}
                </h5>
                <div className="rich-text content">
                    <p>Starting At</p>
                    {isDiscounted && (
                        <p>
                            <span className="strikethru">
                                {roundedMoney(
                                    getProductPrice(variant.price, {
                                        priceType: PriceType.RETAIL,
                                        includePostDiscountAddons: true,
                                        quantity: 1,
                                    }),
                                )}
                            </span>
                        </p>
                    )}
                    <h3 className={isDiscountedClassNames}>
                        {roundedMoney(
                            getProductPrice(variant.price, {
                                priceType: PriceType.COSMETIC_EXCL_TAX,
                                includePostDiscountAddons: true,
                                quantity: 1,
                            }),
                        )}
                    </h3>
                </div>
            </div>
        );
    }

    return (
        <div className={attributeCellClasses}>
            <p>{gettext("Price Unavailable")}</p>
        </div>
    );
};

const PriceCTARow = (props: IProps) => {
    const attributeCellClasses = classNames({
        "attribute-cell": true,
        [styles.attributeCellRichText]: true,
        [styles.attributeCellThemeStepUp]:
            props.theme === ProductCompareTheme.STEP_UP,
        [styles.attributeCellThemeSelect]:
            props.theme === ProductCompareTheme.SELECT,
    });
    const variant = getSelectedVariant(
        props.data,
        props.priceSelectValue || "",
    );

    return (
        <div className={attributeCellClasses}>
            {props.row.flair && <Flair flair={props.row.flair} />}
            <h5 className="ada-screenreader-only">
                {props.data.product_compare_tile.name} -{" "}
                {props.row.row_type.title}
            </h5>
            <div>
                <ProductCompareTileCTA
                    data={props.data}
                    selectedVariant={variant}
                    financingLink={
                        props.financingLink
                            ? props.financingLink
                            : urls.pageURL("finance-link")
                    }
                    showStartingAt={
                        props.showStartingAt
                            ? props.showStartingAt
                            : setShowStartingAt(
                                  props.data,
                                  props.sizeAttributeOptionGroup,
                              )
                    }
                    theme={props.theme}
                />
            </div>
        </div>
    );
};

const ShopNowRow = (props: IProps) => {
    const attributeCellClasses = classNames({
        [styles.attributeCellShopNow]: true,
        [styles.attributeCellThemeStepUp]:
            props.theme === ProductCompareTheme.STEP_UP,
        [styles.attributeCellThemeSelect]:
            props.theme === ProductCompareTheme.SELECT,
    });
    const variant = getSelectedVariant(
        props.data,
        props.priceSelectValue || "",
    );
    if (variant) {
        return (
            <div className={attributeCellClasses}>
                <ShopNowLink tile={props.data} selectedVariant={variant} />
            </div>
        );
    }
    return (
        <div className={attributeCellClasses}>
            <p>{gettext("Unavailable in this size")}</p>
        </div>
    );
};

export const ProductCompareTileAttributeRow = (props: IProps) => {
    switch (props.row.row_type.row_type) {
        case "attribute-option-list":
            return <AttributeListRow {...props} />;
        case "attribute-grid":
            return <AttributeGridRow {...props} />;
        case "product-select":
            return <ProductSelectionRow {...props} />;
        case "microconfigurator":
            return <MicroConfiguratorRow {...props} />;
        case "richtext":
            return <RichTextRow {...props} />;
        case "shop-now":
            return <ShopNowRow {...props} />;
        case "upgrade":
            return <UpgradeRow {...props} />;
        case "dynamic-price":
            return <DynamicPriceRow {...props} />;
        case "price-cta":
            return <PriceCTARow {...props} />;
    }
    throw assertNever(props.row.row_type.row_type);
};

import React from "react";
import { IWebPageURL } from "../../models/nominals";
import { Link } from "../../common/Link";
import { trackSleepExpertEvent } from "../../utils/analytics";
import { urls } from "../../utils/urls";
import { getChatConnector } from "./index";

interface IProps {
    className?: string;
    chatOnlineText?: string | React.ReactNode;
    chatOfflineText?: string | React.ReactNode;
    hideIfOffline?: boolean;
    chatOfflineLink: IWebPageURL;
}

interface IState {
    isChatOnline: boolean;
}

export class ChatLink extends React.Component<IProps, IState> {
    public state = {
        isChatOnline: false,
    };

    async componentDidMount() {
        try {
            const { isOnline } = await getChatConnector().init();
            this.setState({
                isChatOnline: isOnline,
            });
        } catch (e) {
            console.error(e);
        }
    }

    private readonly onClick = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        if (this.state.isChatOnline) {
            getChatConnector().openChat();
            trackSleepExpertEvent("chat");
        } else {
            urls.navigateToURL(this.props.chatOfflineLink);
        }
    };

    render() {
        if (this.state.isChatOnline) {
            return (
                <button className={this.props.className} onClick={this.onClick}>
                    {this.props.chatOnlineText || gettext("Chat")}
                </button>
            );
        }
        if (this.props.hideIfOffline) {
            return null;
        }
        return (
            <Link
                className={this.props.className}
                href={this.props.chatOfflineLink}
            >
                {this.props.chatOfflineText || gettext("Email us")}
            </Link>
        );
    }
}

import React from "react";
import { assertNever } from "../../../utils/never";
import { useAppDispatch, useAppSelector } from "../../reducers";
import { ModalStateType } from "../constants";
import { ModalState } from "../models";
import { closeModal } from "../reducers";

import { FinancingRoot, FinancingPortal } from "../FinancingPortal";

import { FinancingPreQualForm } from "../FinancingPreQualForm";
import { FinancingPreQualApproval } from "../FinancingPreQualApproval";
import { FinancingPreQualDenied } from "../FinancingPreQualDenied";
import { FinancingPreQualExistingCardHolder } from "../FinancingPreQualExistingCardHolder";

import { FinancingFullApplyForm } from "../FinancingFullApplyForm";
import { FinancingFullApproval } from "../FinancingFullApproval";
import { FinancingFullPending } from "../FinancingFullPending";
import { FinancingFullDenied } from "../FinancingFullDenied";
import { FinancingFullNextBackend } from "../FinancingFullNextBackend";
import { FinancingFullExistingCardholder } from "../FinancingFullExistingCardholder";

const ModalContent = (props: { modalState: ModalState }) => {
    const valueProps = useAppSelector((state) => state.financing.valueProps);

    switch (props.modalState._tag) {
        case ModalStateType.CLOSED: {
            return null;
        }

        case ModalStateType.PREQUAL_INITIAL:
        case ModalStateType.PREQUAL_SUBMITTING:
        case ModalStateType.PREQUAL_ERROR: {
            return <FinancingPreQualForm />;
        }

        case ModalStateType.PREQUAL_APPROVED: {
            return (
                <FinancingPreQualApproval
                    firstName={props.modalState.data.first_name}
                    backend_type={
                        props.modalState.approvedResponse.backend_type
                    }
                    creditLimit={props.modalState.approvedResponse.credit_limit}
                    apr={props.modalState.approvedResponse.apr}
                    fullDuration={
                        props.modalState.approvedResponse.full_duration
                    }
                />
            );
        }

        case ModalStateType.PREQUAL_DENIED: {
            return <FinancingPreQualDenied />;
        }

        case ModalStateType.PREQUAL_EXISTING_CARDHOLDER: {
            return <FinancingPreQualExistingCardHolder />;
        }

        case ModalStateType.PRESCREEN_APPROVED: {
            return null;
        }

        case ModalStateType.APPLY_INITIAL:
        case ModalStateType.APPLY_SUBMITTING:
        case ModalStateType.APPLY_ERROR: {
            return <FinancingFullApplyForm modalState={props.modalState} />;
        }

        case ModalStateType.APPLY_APPROVED: {
            return (
                <FinancingFullApproval
                    account={props.modalState.account}
                    documents={props.modalState.documents}
                    hasAgreedToCardholderAgreement={
                        props.modalState.hasAgreedToCardholderAgreement
                    }
                    valueProps={valueProps}
                />
            );
        }

        case ModalStateType.APPLY_PENDING: {
            return <FinancingFullPending modalState={props.modalState} />;
        }

        case ModalStateType.APPLY_DENIED: {
            return <FinancingFullDenied modalState={props.modalState} />;
        }

        case ModalStateType.APPLY_NEXT_BACKEND: {
            return <FinancingFullNextBackend modalState={props.modalState} />;
        }

        case ModalStateType.APPLY_EXISTING_CARDHOLDER: {
            return <FinancingFullExistingCardholder />;
        }
    }

    throw assertNever(props.modalState);
};

export const FinancingModal = () => {
    const dispatch = useAppDispatch();
    const modalState = useAppSelector((state) => state.financing.modal);
    const isOpen =
        modalState._tag !== ModalStateType.CLOSED &&
        modalState._tag !== ModalStateType.PRESCREEN_APPROVED;

    const onOpenChange = (open: boolean) => {
        if (open) {
            return;
        }
        dispatch(closeModal());
    };
    const title = modalState._tag.startsWith("PREQUAL")
        ? "Pre-Qualification Form"
        : "Credit Card Application";

    return (
        <FinancingRoot modal={true} open={isOpen} onOpenChange={onOpenChange}>
            <FinancingPortal title={title}>
                <ModalContent modalState={modalState} />
            </FinancingPortal>
        </FinancingRoot>
    );
};

import React from "react";
import {
    StreamField,
    StreamFieldBlockMap,
} from "@thelabnyc/thelabui/src/components/StreamField";
import {
    templates,
    RichNavigationContentAssembly,
    RichNavigationBannerContentAssembly,
} from "@reactivated";
import { BannerBlock } from "../../../blocks/BannerBlock";
import { RichNavigationLinkCollection } from "../RichNavigationLinkCollection";
import { RichNavigationLink } from "../RichNavigationLink";

const contentAssemblyComponents: StreamFieldBlockMap<RichNavigationContentAssembly> =
    {
        rich_nav_link_collection: RichNavigationLinkCollection,
        link: RichNavigationLink,
        closest_store_selection: RichNavigationLink,
    };

const bannerContentAssemblyComponents: StreamFieldBlockMap<RichNavigationBannerContentAssembly> =
    {
        banner: BannerBlock,
    };

export const RichNavigation = (
    props: templates.RichNavigationTemplate["rich_navigation"],
) => {
    if (!props) {
        return null;
    }
    return (
        <section>
            {props.content && (
                <StreamField
                    components={contentAssemblyComponents}
                    value={props.content.value}
                />
            )}
            {props.banner_content && (
                <StreamField
                    components={bannerContentAssemblyComponents}
                    value={props.banner_content.value}
                />
            )}
        </section>
    );
};

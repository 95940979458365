import { getCurrentScope } from "@sentry/browser";
import { ajax, CSRF_HEADER, getCSRFToken } from "../utils/ajax";
import { PromiseMutex } from "../utils/mutex";
import { check } from "../models/utils";
import {
    IUser,
    ICSRAssistedUser,
    IUserLoginResponse,
    ISessionKeepAliveResponse,
    IEmailOfferSignupRequest,
    IEmailOfferSignupResponse,
} from "../models/user.interfaces";
import {
    User,
    EmailOfferSignupResponse,
    CSRAssistedUser,
    UserLoginResponse,
    SessionKeepAliveResponse,
} from "../models/user";
import { IWebPageURL, isoWebPageURL } from "../models/nominals";

export const load = async () => {
    const mutex = new PromiseMutex<IUser>("load-current-user");
    let loading = mutex.getPromise();
    if (!loading) {
        loading = ajax
            .get("/api/user/")
            .set("Accept", "application/json")
            .then((resp): IUser => {
                const user = check(User.decode(resp.body));
                getCurrentScope().setUser(resp.body);
                return user;
            });
        mutex.setPromise(loading);
    }
    return loading;
};

export const getCurrentlyAssistedUser = async () => {
    const mutex = new PromiseMutex<ICSRAssistedUser | null>(
        "load-currently-assisted-user",
    );
    let loading = mutex.getPromise();
    if (!loading) {
        loading = ajax
            .get("/api/csr/users/currently_assisted_user/")
            .set("Accept", "application/json")
            .then((resp): ICSRAssistedUser | null => {
                if (!resp.body) {
                    return null;
                }
                return check(CSRAssistedUser.decode(resp.body));
            });
        mutex.setPromise(loading);
    }
    return loading;
};

export const updateAssistedUserEmail = async (
    userURL: string,
    email: string,
): Promise<ICSRAssistedUser> => {
    const resp = await ajax
        .patch(userURL)
        .set(CSRF_HEADER, await getCSRFToken())
        .set("Accept", "application/json")
        .send({ email: email });
    return check(CSRAssistedUser.decode(resp.body));
};

export const update = async (data: Partial<IUser>): Promise<IUser> => {
    const resp = await ajax
        .patch("/api/user/")
        .set("Accept", "application/json")
        .set(CSRF_HEADER, await getCSRFToken())
        .send(data);
    const user = check(User.decode(resp.body));
    getCurrentScope().setUser(resp.body);
    return user;
};

export const updateEmailAddress = async (email: string): Promise<IUser> => {
    return update({
        email: email,
    });
};

export const changePassword = async (newPassword: string): Promise<void> => {
    await ajax
        .put("/api/user/change-password/")
        .set("Accept", "application/json")
        .set(CSRF_HEADER, await getCSRFToken())
        .send({
            password: newPassword,
        });
};

export const login = async (
    email: string,
    password: string,
    nextURL?: IWebPageURL,
): Promise<IUserLoginResponse> => {
    const resp = await ajax
        .post("/api/user/login/")
        .set("Accept", "application/json")
        .set(CSRF_HEADER, await getCSRFToken())
        .send({
            email: email,
            password: password,
            nextURL: nextURL ? isoWebPageURL.unwrap(nextURL) : undefined,
        });
    return check(UserLoginResponse.decode(resp.body));
};

export const sendSessionKeepAlivePing = async () => {
    const csrfToken = await getCSRFToken();

    // If the user doesn't have a CSRF token yet, that means they're anonymous, which means they
    // have the default session length (2 weeks). We only actually need to send the AJAX request
    // for authenticated users.
    if (!csrfToken) {
        return Promise.resolve<ISessionKeepAliveResponse>({
            session_lifetime_seconds: 60 * 60 * 24 * 14,
        });
    }
    const resp = await ajax
        .post("/api/user/keep-alive/")
        .set("Accept", "application/json")
        .set(CSRF_HEADER, await getCSRFToken())
        .send({});
    return check(SessionKeepAliveResponse.decode(resp.body));
};

export const signupForEmailOffers = async (
    email: string,
    zipcode?: string,
    campaign_id?: string,
): Promise<IEmailOfferSignupResponse> => {
    const reqData: IEmailOfferSignupRequest = {
        email: email,
        zipcode: zipcode,
        campaign_id: campaign_id,
    };
    const resp = await ajax
        .post("/api/email-offers-signup/")
        .set("Accept", "application/json")
        .set(CSRF_HEADER, await getCSRFToken())
        .send(reqData);
    return check(EmailOfferSignupResponse.decode(resp.body));
};

import React from "react";
import SVG from "react-inlinesvg";
import { IAPR, IMonths } from "../../../models/nominals";
import { formatAPR, formatTermLength } from "../../../utils/format";
import fortivaLogo from "../../../../img/finance/fortiva-logo.svg";

import styles from "./index.module.scss";

export const FinancingFortivaOffer = (props: {
    creditLimit: string;
    apr: IAPR;
    fullDuration: IMonths | null;
}) => {
    return (
        <div className={styles.terms}>
            <SVG className={styles.logo} src={fortivaLogo} />
            <h3 className={styles.subheading}>
                Congratulations! You are pre-qualified for a Fortiva® Retail
                Credit Account with
                <br /> Tempur-Pedic.
            </h3>
            <p>
                Please review your offer details from Fortiva® Retail Credit
                below:
            </p>
            <div className={styles.details}>
                <p className={styles.detailItem}>
                    Pre-qualified Amount:
                    <strong>${props.creditLimit}</strong>
                </p>
                <p className={styles.detailItem}>
                    Annual Percentage Rate:
                    <strong>{formatAPR(props.apr)}</strong>
                </p>
                {props.fullDuration && (
                    <p className={styles.detailItem}>
                        Estimated Payoff:
                        <strong>
                            {formatTermLength(props.fullDuration)} Months*
                        </strong>
                    </p>
                )}
            </div>
            <p className={styles.fineprint}>
                <strong>*Estimated Payoff Disclosure:</strong>
                <br /> Minimum purchase of $1000 or more required per
                transaction. Estimated Payoff shows how long it will take to pay
                off your purchase if you make your minimum payments by the due
                date each month.
            </p>
        </div>
    );
};

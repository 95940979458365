import React from "react";
import { strings } from "../../../localization";
import { useAppDispatch, useAppSelector } from "../../reducers";
import { closeModal } from "../reducers";
import Trans from "../../../common/Trans";

interface Props {
    isRefreshing: boolean;
    firstName: string;
}

export const BodyContent = (props: Props) => {
    const dispatch = useAppDispatch();
    const purchasePrice = useAppSelector(
        (state) => state.financing.purchasePrice,
    );
    const buttonText =
        (purchasePrice
            ? strings.get("FINANCING_RETURN_TO_CHECKOUT")
            : strings.get("FINANCING_RETURN_TO_SITE")) || "";

    if (props.isRefreshing) {
        return (
            <p>
                {gettext(
                    `This may take a couple minutes. Please do not navigate away
                    from this screen until you see a credit decision page.`,
                )}
            </p>
        );
    }
    return (
        <>
            <p>
                <Trans
                    fmtString={interpolate(
                        gettext("Thank you, %s, for your interest in the %s!"),
                        [
                            props.firstName,
                            `${strings.get("FINANCING_CARD_NAME")}`,
                        ],
                    )}
                    data={{
                        SUP: (content) => <sup key={1}>{content}</sup>,
                    }}
                />
            </p>
            <p>{strings.get("FINANCING_APP_PENDING_MSG")}</p>
            <button
                className="button"
                onClick={(e) => {
                    e.preventDefault();
                    dispatch(closeModal());
                }}
            >
                {buttonText}
            </button>
        </>
    );
};

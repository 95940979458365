import React from "react";
import { connect } from "react-redux";
import { TStateMapper, TDispatchMapper } from "../../reducers.interfaces";
import { IWriteReviewTemplateField } from "../../../models/reviews.interfaces";
import { IFormUUID } from "../../../models/nominals";
import { FormTextbox } from "../../../forms/FormTextbox";
import { Dispatchers } from "../dispatchers";
import { getWriteReviewFormState } from "../selectors";

interface IOwnProps {
    formUUID: IFormUUID;
    name: string;
    field: IWriteReviewTemplateField;
    isMultiReview?: boolean;
}

interface IReduxProps {
    value: string;
    errors: string[];
    showErrors: boolean;
}

interface IDispatchProps {
    dispatchers: Dispatchers;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {
    isExpanded: boolean;
}

class WriteReviewFormTextAreaComponent extends React.PureComponent<
    IProps,
    IState
> {
    public state: IState = {
        isExpanded: false,
    };
    private readonly onChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
        this.props.dispatchers.onReviewFormTextChange({
            formUUID: this.props.formUUID,
            fieldName: this.props.name,
            fieldValue: e.currentTarget.value,
        });
    };
    private readonly clickToExpand = () => {
        this.setState({
            isExpanded: !this.state.isExpanded,
        });
    };

    render() {
        const isCommentBox = this.props.name === "comments";
        return (
            <span>
                <FormTextbox
                    name={this.props.name}
                    id={this.props.name}
                    required={this.props.field.is_required}
                    label={this.props.field.name}
                    labelPlacement={"static"}
                    maxLength={this.props.field.max_length || undefined}
                    rows={4}
                    value={this.props.value}
                    errors={this.props.errors}
                    showErrorMessages={this.props.showErrors}
                    onChange={this.onChange}
                />
                {isCommentBox && (
                    <div className="write-review-form__comment-disclaimer">
                        {!this.state.isExpanded && this.props.isMultiReview ? (
                            <button
                                onClick={this.clickToExpand}
                                aria-expanded={this.state.isExpanded}
                            >
                                {gettext(
                                    "+ Show tips on writing useful comments",
                                )}
                            </button>
                        ) : (
                            <>
                                <h4>
                                    {gettext(
                                        "The most useful comments contain specific information about:",
                                    )}
                                </h4>
                                <ul>
                                    <li>
                                        {gettext(
                                            "How this product has changed your life",
                                        )}
                                    </li>
                                    <li>
                                        {gettext("Things you love about it")}
                                    </li>
                                    <li>
                                        {gettext(
                                            "Things you don't love about it",
                                        )}
                                    </li>
                                </ul>
                                {gettext(
                                    "Please do not include: HTML, references to other retailers, pricing, personal " +
                                        "information, or any profane, inflammatory or copyrighted comments. Also, if you were given " +
                                        "this product as a gift or otherwise compensated in exchange for writing this review, you " +
                                        "are REQUIRED to disclose it in your comments above.",
                                )}
                            </>
                        )}
                    </div>
                )}
            </span>
        );
    }
}

const mapStateToProps: TStateMapper<"reviews", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const formState = getWriteReviewFormState(rootState.reviews, ownProps);
    return {
        value: formState?.valuesText[ownProps.name] ?? "",
        errors: formState.errors[ownProps.name] ?? [],
        showErrors: formState.showErrors,
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        dispatchers: dispatchers,
    };
};

export const WriteReviewFormTextArea = connect(
    mapStateToProps,
    mapDispatchToProps,
)(WriteReviewFormTextAreaComponent);

import React from "react";
import creditCardType from "credit-card-type";
import classNames from "classnames";
import { FormInput } from "../../../forms/FormInput";
import { FormCreditCardNumberInput } from "../../../forms/FormCreditCardNumberInput";
import { FormNumber } from "../../../forms/FormNumber";
import { CARD_TYPE_VISA } from "../constants";
import { ICybersourcePayment } from "../reducers.interfaces";
import { Dispatchers } from "../dispatchers";
import { IPaymentMethodFormProps } from "./PaymentMethod.interfaces";
import format from "../../../utils/format";
import { Image } from "../../../common/Image";
import creditCards from "../../../../img/checkout/credit-cards.png";
import iconAmericanExpress from "../../../../img/checkout/american-express.png";
import iconMastercard from "../../../../img/checkout/mastercard.png";
import iconVisa from "../../../../img/checkout/visa.png";
import iconDiscover from "../../../../img/checkout/discover.png";
import iconLock from "../../../../img/checkout/lock.png";
import iconCCBack from "../../../../img/checkout/cc-back.png";

const cardIcons = {
    "american-express": iconAmericanExpress,
    "mastercard": iconMastercard,
    "visa": iconVisa,
    "discover": iconDiscover,
    "lock": iconLock,
};

const cardIconExists = (
    cardType: string,
): cardType is keyof typeof cardIcons => {
    return Object.prototype.hasOwnProperty.call(cardIcons, cardType);
};

const getCardIcon = (cardType: string): string | null => {
    return cardIconExists(cardType) ? cardIcons[cardType] : null;
};

interface IProps extends IPaymentMethodFormProps {
    setPaymentMethodFields: Dispatchers["setPaymentMethodFields"];
    changeCreditCardNumber: Dispatchers["changeCreditCardNumber"];
    changeCreditCardExpDate: Dispatchers["changeCreditCardExpDate"];
    changeAmount: Dispatchers["changeAmount"];
}

interface IState {}

export class Cybersource extends React.Component<IProps, IState> {
    cvcElem: FormInput<"card_cvc"> | null | undefined;

    private readonly onCardNumberChange = (
        event: React.FormEvent<HTMLInputElement>,
    ) => {
        this.props.changeCreditCardNumber(
            this.props.methodKey,
            event.currentTarget.value,
        );
    };

    componentDidUpdate(prevProps: IProps) {
        const prevPayment = prevProps.form.payment_methods[
            this.props.methodKey
        ] as ICybersourcePayment;
        const currentPayment = this.props.form.payment_methods[
            this.props.methodKey
        ] as ICybersourcePayment;

        if (
            this.cvcElem &&
            prevPayment.card_number !== currentPayment.card_number
        ) {
            // force validate CVC given a new card number
            this.cvcElem.runValidators();
        }
    }

    private readonly onCardExpChange = (
        event: React.FormEvent<HTMLInputElement>,
    ) => {
        const methodData = this.getMethodData();
        this.props.changeCreditCardExpDate(
            this.props.methodKey,
            methodData.card_expiration,
            event.currentTarget.value,
        );
    };

    private readonly onChange = (event: React.FormEvent<HTMLInputElement>) => {
        this.props.setPaymentMethodFields(this.props.methodKey, {
            [event.currentTarget.name]: event.currentTarget.value,
        });
    };

    private readonly onAmountChange = (
        event: React.FormEvent<HTMLInputElement>,
    ) => {
        this.props.changeAmount(
            this.props.methodKey,
            this.props.derived.grand_total,
            event.currentTarget.value,
        );
    };

    private readonly onValidStateChange = (
        fieldName: string,
        errorMessages: string[],
    ) => {
        this.props.onValidStateChange(
            fieldName,
            errorMessages,
            this.props.methodKey,
        );
    };

    private getMethodData(): ICybersourcePayment {
        const data = this.props.form.payment_methods[this.props.methodKey];
        let cybData: ICybersourcePayment | null = null;
        if (data.method_type === "cybersource") {
            cybData = data;
        }
        const baseFields: ICybersourcePayment = {
            method_type: "cybersource",
            amount: "",
            pay_balance: false,
            card_type: CARD_TYPE_VISA,
            card_number: "",
            card_expiration: "",
            card_cvc: "",
        };
        return {
            ...baseFields,
            ...cybData,
        };
    }

    render() {
        const methodData = this.getMethodData();
        const cardNumber = methodData.card_number.replace(/\s+/g, "");
        const types = creditCardType(cardNumber);
        const type = types[0];

        const cardNumberClasses = classNames({
            ["form__card-number"]: true,
        });
        const cardIcon = getCardIcon(
            type && cardNumber.length ? type.type : "lock",
        );

        const securityCode =
            type && type.code
                ? type.code
                : {
                      name: "CVV",
                      size: 3,
                  };

        const errors =
            this.props.methodKey in this.props.errors
                ? this.props.errors[this.props.methodKey]
                : {};

        const creditCardImage = creditCards;
        const sectionName = this.props.header
            ? `_${this.props.header.replace(/ +/g, "_").toLowerCase()}`
            : "";
        return (
            <div>
                <h4 className="checkout-step__bar-heading">
                    {this.props.header}
                </h4>
                <div className="checkout-step__form-container checkout-step__form-container--cc checkout-step__form-container--blue">
                    <div className="checkout-step__form-header">
                        <div className="checkout-step__form-header--cc-copy">
                            <h5 className="checkout-step__copy--bold">
                                Secure Credit Card Payment
                            </h5>
                            <div>
                                Use your VISA, MasterCard, American Express or
                                Discover credit card.
                            </div>
                        </div>
                        <div className="checkout-step__form-header--cc-image">
                            <Image
                                src={creditCardImage}
                                className="checkout-step__form-image--cc responsive-img"
                                alt="Accepted Credit Cards: VISA, MasterCard, American Express or Discover"
                            />
                        </div>
                    </div>
                    <fieldset
                        disabled={this.props.disabled}
                        style={{ border: 0 }}
                    >
                        <legend className="ada-screenreader-only">
                            {interpolate(gettext("%s Information"), [
                                `${
                                    !this.props.header
                                        ? "Credit Card"
                                        : this.props.header
                                }`,
                            ])}
                        </legend>
                        <label
                            htmlFor={`card_number${sectionName}`}
                            className="checkout-step__copy--bold"
                        >
                            Card Number
                        </label>
                        <FormCreditCardNumberInput
                            name="card_number"
                            id={`card_number${sectionName}`}
                            showErrorMessages={this.props.showErrorMessages}
                            onValidStateChange={this.onValidStateChange}
                            className={cardNumberClasses}
                            value={methodData.card_number}
                            errors={errors.card_number}
                            onChange={this.onCardNumberChange}
                            secureImage={
                                cardIcon
                                    ? {
                                          image: cardIcon,
                                          alt: gettext("Secure"),
                                      }
                                    : undefined
                            }
                        />
                        <div className="checkout-step__payment-cc-expiration">
                            <label
                                htmlFor={`card_expiration${sectionName}`}
                                className="checkout-step__copy--bold"
                            >
                                Expiration (MM/YY)
                            </label>
                            <FormInput
                                type="text"
                                name="card_expiration"
                                id={`card_expiration${sectionName}`}
                                validation={["required", "expiration-date"]}
                                showErrorMessages={this.props.showErrorMessages}
                                onValidStateChange={this.onValidStateChange}
                                autoComplete="cc-exp"
                                value={methodData.card_expiration}
                                errors={errors.card_expiration}
                                onChange={this.onCardExpChange}
                                formatOptions={{
                                    type: "date",
                                    datePattern: ["m", "y"],
                                }}
                                maxLength={5}
                            />
                        </div>
                        <div className="checkout-step__payment-cc-code">
                            <label
                                htmlFor={`card_cvc${sectionName}`}
                                className="checkout-step__copy--bold"
                            >
                                Security Code
                            </label>
                            <FormInput
                                ref={(ref) => {
                                    this.cvcElem = ref;
                                }}
                                type="text"
                                name="card_cvc"
                                id={`card_cvc${sectionName}`}
                                autoComplete="cc-csc"
                                validation={[
                                    "required",
                                    securityCode.size === 3 ? "cvc3" : "cvc4",
                                ]}
                                showErrorMessages={this.props.showErrorMessages}
                                onValidStateChange={this.onValidStateChange}
                                value={methodData.card_cvc}
                                errors={errors.card_cvc}
                                onChange={this.onChange}
                                formatOptions={{
                                    type: "general",
                                    numericOnly: true,
                                    blocks: [securityCode.size],
                                }}
                                secureImage={{
                                    image: iconCCBack,
                                    alt: gettext(
                                        "Code on back of card, Secure",
                                    ),
                                }}
                                maxLength={securityCode.size}
                            />
                        </div>
                        {this.props.isSplitPay && (
                            <div className="checkout-step__payment-amount">
                                <label
                                    htmlFor={`amount${sectionName}`}
                                    className="checkout-step__copy--bold"
                                >
                                    Amount
                                </label>
                                <FormNumber
                                    name="amount"
                                    id={`amount${sectionName}`}
                                    autoComplete=""
                                    step={0.01}
                                    validation={[
                                        "required",
                                        "amount",
                                        "nonzero",
                                    ]}
                                    showErrorMessages={
                                        this.props.showErrorMessages
                                    }
                                    onValidStateChange={this.onValidStateChange}
                                    value={methodData.amount || undefined}
                                    errors={errors.amount}
                                    onChange={this.onAmountChange}
                                    disabled={this.props.shouldFreezeAmount}
                                />
                            </div>
                        )}
                        {this.props.disabled && (
                            <div>
                                <h5>{gettext("Pre-Authorized")}</h5>
                                <p>
                                    {interpolate(
                                        gettext(
                                            "This card has been pre-authorized for the amount of %s",
                                        ),
                                        [
                                            format.money(
                                                methodData.amount || "0.00",
                                            ),
                                        ],
                                    )}
                                    .
                                </p>
                            </div>
                        )}
                    </fieldset>
                </div>
            </div>
        );
    }
}

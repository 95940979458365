import React from "react";
import classNames from "classnames";
import { strings } from "../../../localization";
import { FinancingPlan } from "../../../models/financing";
import { IMonths, isoMonths } from "../../../models/nominals";
import { FinePrint } from "./PaymentMethod_Financing_FinePrint";
import { SelectPlanForm } from "./PaymentMethod_Financing_SelectPlanForm";
import { FormSubmit } from "../../../forms";
import { IReduxFormState } from "../reducers.interfaces";
import { Dispatchers } from "../dispatchers";

interface IProps {
    methodKey: string;
    form: IReduxFormState;
    plans: FinancingPlan[];
    grandTotal: string;
    setPaymentMethodFields: Dispatchers["setPaymentMethodFields"];
    disabled: boolean;
}

interface IState {}

export class RequestLimitIncrease extends React.Component<IProps, IState> {
    private currentPlanMonths = 0;

    private readonly onSetPlanMonths = (months: IMonths) => {
        this.currentPlanMonths = isoMonths.unwrap(months);
    };

    render() {
        const submitClasses = classNames({
            "button": true,
            "button--full-width": true,
            "button--place-order": true,
            [`checkout-place-order-button--wf-${this.currentPlanMonths}`]: true,
            [`al-checkout__place-order-button--wf-${this.currentPlanMonths}`]:
                true,
        });

        return (
            <div className="accordion-target accordion-target--checkout-finance">
                <span className="financing-new-account__arrow-down"></span>
                <div>
                    <div>
                        {gettext(
                            "To request a credit limit increase, call Wells Fargo at 1-800-459-8451, " +
                                "Monday – Friday, 9 am – 7 pm Eastern Time.",
                        )}
                    </div>
                    <br />
                    <div>
                        {gettext(
                            "If Wells Fargo grants an increased credit limit that covers your order total, you may continue " +
                                "placing your order. If your request is denied, please choose one of the remaining options to " +
                                "complete your order.",
                        )}
                    </div>
                </div>
                <div className="financing-form__plan-select financing-new-account__plan-select">
                    <SelectPlanForm
                        methodKey={this.props.methodKey}
                        form={this.props.form}
                        plans={this.props.plans}
                        grandTotal={this.props.grandTotal}
                        setPlanMonths={this.onSetPlanMonths}
                        setPaymentMethodFields={
                            this.props.setPaymentMethodFields
                        }
                        disabled={this.props.disabled}
                    />
                    <FinePrint />
                    <FormSubmit
                        value={
                            strings.get("CONTINUE_BTN_LABEL_PAYMENT_METHODS") ||
                            gettext("Place Order")
                        }
                        className={submitClasses}
                    />
                </div>
            </div>
        );
    }
}

import React, { forwardRef } from "react";
import { IntrinsicElementProps } from "@thelabnyc/thelabui/src/utils/types";
import { FinancingCardType } from "../../../constants";

import iconTempurCcUpdated from "../../../../img/finance/tempur-cc-updated.png";
import iconStearnsCc from "../../../../img/finance/stearns-cc.png";
import iconSealyCc from "../../../../img/finance/sealy-cc.png";
import iconCocoonCc from "../../../../img/finance/cocoon-cc.png";
import iconSleepoutfittersCc from "../../../../img/finance/sleepoutfitters-cc.png";

const icons: { [K in FinancingCardType]: string } = {
    [FinancingCardType.TEMPUR_CC]: iconTempurCcUpdated,
    [FinancingCardType.STEARNS_CC]: iconStearnsCc,
    [FinancingCardType.SEALY_CC]: iconSealyCc,
    [FinancingCardType.COCOON_CC]: iconCocoonCc,
    [FinancingCardType.SLEEPOUTFITTERS_CC]: iconSleepoutfittersCc,
};

type PassthroughProps = Omit<IntrinsicElementProps<HTMLImageElement>, "src">;
type Props = PassthroughProps & {
    cardType: FinancingCardType;
};

export const FinancingCardImage = forwardRef<HTMLImageElement, Props>(
    (props, ref) => {
        const { cardType, ...otherProps } = props;
        const src = icons[cardType];
        return (
            <img
                ref={ref}
                src={src}
                alt={gettext("Credit Card image")}
                {...otherProps}
            />
        );
    },
);

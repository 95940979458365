import React, { useState } from "react";
import { IVoucherDiscount } from "../../../models/catalogue.interfaces";
import { Image } from "../../../common/Image";
import styles from "./VoucherLineFull.module.scss";

interface IProps {
    voucherData: IVoucherDiscount;
    onRemoveLine: (code: string) => void;
}

export const VoucherLineFull = (props: IProps) => {
    const [isSavingsInfoExpanded, setIsSavingsInfoExpanded] = useState(true);
    const toggleAccordion = () => {
        setIsSavingsInfoExpanded((prev) => !prev);
    };
    const basketSavingsID = `voucher-line-savings-details-${props.voucherData.voucher.code}`;
    return (
        <section className={styles.voucherLine}>
            {props.voucherData.desktop_image && (
                <div className={styles.firstColumn}>
                    <Image
                        alt={props.voucherData.name}
                        src={props.voucherData.desktop_image}
                    />
                </div>
            )}
            <div>
                <h2 className={styles.voucherTitle}>
                    {props.voucherData.name}
                </h2>
            </div>
            <div className={styles.quantity}>
                <span>
                    {gettext("Qty:")} &nbsp; {props.voucherData.freq}
                </span>
            </div>
            <div className={styles.voucherLineSavings}>
                <div className={styles.savingSummary}>
                    <button
                        className={styles.accordionButton}
                        aria-controls={basketSavingsID}
                        onClick={toggleAccordion}
                        aria-expanded={isSavingsInfoExpanded}
                    >
                        {gettext("Total Savings")}
                    </button>
                </div>
                {isSavingsInfoExpanded && (
                    <div className={styles.savingSummary}>
                        <div
                            id={basketSavingsID}
                            className={styles.savingDetails}
                        >
                            {props.voucherData.voucher.code}
                        </div>
                    </div>
                )}
            </div>
            <div className={styles.whiteGloveDelivery}>
                <span className={styles.deliveryText}>
                    {" "}
                    Free Delivery - additional details sent via email.
                </span>
            </div>
            <button
                onClick={() =>
                    props.onRemoveLine(props.voucherData.voucher.code)
                }
                className={`${styles.removeButton} al-basket-line__button--remove`}
            >
                {gettext("[Remove from cart]")}
            </button>
        </section>
    );
};

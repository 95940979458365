import { IReduxState } from "./reducers.interfaces";
import { Step, Mattress_Size } from "./constants";

export const defaults: IReduxState = {
    ui: {
        selectedSize: Mattress_Size.QUEEN,
        loadedResults: null,
        selectedBudget: 0,
        selectedHeight: 0,
        selectedWeight: 0,
        selectedFirmness: 0,
        selectedCoolness: 0,
        currentScreen: Step.SIZE,
    },
};

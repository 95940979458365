import React from "react";
import { isoWebPageURL, IWebPageURL } from "../../../models/nominals";
import {
    FinancingDocumentFormat,
    FinancingDocument,
} from "../../../models/financing";

import styles from "./index.module.scss";

interface Props {
    document: FinancingDocument;
}

export const DocumentEmbed = (props: Props) => {
    const htmlURL = props.document.formats[FinancingDocumentFormat.HTML];
    const pdfURL = props.document.formats[FinancingDocumentFormat.PDF];
    return (
        <div>
            <iframe
                className={styles.iframe}
                src={isoWebPageURL.unwrap(htmlURL || pdfURL)}
            />
            <div className={styles.buttonContainer}>
                <button
                    className={styles.button}
                    onClick={(e) => {
                        e.preventDefault();
                        printSummary(htmlURL || pdfURL);
                    }}
                >
                    {gettext("Print")}
                </button>
            </div>
        </div>
    );
};

const printSummary = (url: IWebPageURL) => {
    const width = 640;
    const height = 480;
    const options = [
        ["toolbar", "no"],
        ["location", "no"],
        ["directories", "no"],
        ["status", "no"],
        ["menubar", "no"],
        ["scrollbars", "no"],
        ["resizable", "yes"],
        ["width", width],
        ["height", height],
        ["top", screen.height / 2 - height / 2],
        ["left", screen.width / 2 - width / 2],
    ];
    const encodedOptions = options
        .map((pair) => {
            return pair.join("=");
        })
        .join(", ");
    const popup = window.open(
        isoWebPageURL.unwrap(url),
        undefined,
        encodedOptions,
    );
    if (!popup) {
        return;
    }
    popup.print();
};

import type { AdaEmbedAPI } from "@ada-support/embed-types";
import config from "./config";
import type { SyfWidgetConfig, SyfMPP } from "./models/synchrony";
import type {
    IZenChat,
    IWidgetPositionSettings,
} from "./apps/chat/connector-zendesk";
import { domContentLoaded, readyStateComplete } from "./utils/events";
import { markPerfTiming, reportWebVitals } from "./utils/performance";
import { TSIEcomPublicAPI, publicAPI } from "./public-api";
import { ReCaptcha } from "./@types/recaptcha";

markPerfTiming("js-init");
reportWebVitals();

// Event logging
(async () => {
    await domContentLoaded;
})();

(async () => {
    await readyStateComplete;
})();

// Internationalization
type INamedInterpolationData = { [key: string]: string };
type IPositionalInterpolationData = string[];
type IInterpolateFn = ((
    fmt: string,
    obj: IPositionalInterpolationData,
    named?: false,
) => string) &
    ((fmt: string, obj: INamedInterpolationData, named: true) => string);
export interface IDjango {
    readonly gettext: <T extends string>(msgid: T) => T;
    readonly ngettext: (
        singular: string,
        plural: string,
        count: number,
    ) => string;
    readonly gettext_noop: (msgid: string) => string;
    readonly pgettext: (context: string, msgid: string) => string;
    readonly npgettext: (
        context: string,
        singular: string,
        plural: string,
        count: number,
    ) => string;
    readonly interpolate: IInterpolateFn;

    readonly pluralidx: (count: number) => number;

    readonly jsi18n_initialized: boolean;
    readonly catalog: {
        [msgid: string]: string;
    };
    readonly formats: {
        [msgid: string]: string | string[];
    };
    readonly get_format: (formatType: string) => string | string[];
}

interface IFrameResizerPageInfo {
    // The height of the iframe in pixels
    iframeHeight: number;
    // The width of the iframe in pixels
    iframeWidth: number;
    // The number of pixels between the left edge of the containing page and the left edge of the iframe
    offsetLeft: number;
    // The number of pixels between the top edge of the containing page and the top edge of the iframe
    offsetTop: number;
    // The number of pixels between the left edge of the iframe and the left edge of the iframe viewport
    scrollLeft: number;
    // The number of pixels between the top edge of the iframe and the top edge of the iframe viewport
    scrollTop: number;
    // The containing document's height in pixels (the equivalent of document.documentElement.clientHeight in the container)
    documentHeight: number;
    // The containing document's width in pixels (the equivalent of document.documentElement.clientWidth in the container)
    documentWidth: number;
    // The containing window's height in pixels (the equivalent of window.innerHeight in the container)
    windowHeight: number;
    // The containing window's width in pixels (the equivalent of window.innerWidth in the container)
    windowWidth: number;
}

declare global {
    // Django i18n functions
    const django: IDjango;
    const pluralidx: IDjango["pluralidx"];
    const gettext: IDjango["gettext"];
    const ngettext: IDjango["ngettext"];
    const gettext_noop: IDjango["gettext_noop"];
    const pgettext: IDjango["pgettext"];
    const npgettext: IDjango["npgettext"];
    const interpolate: IDjango["interpolate"];
    const get_format: IDjango["get_format"];

    interface Window {
        django: IDjango;

        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        dataLayer: any[];

        // Interfaces for Synchrony Unify
        syfWidgetObject?: SyfWidgetConfig;
        syfMPP?: SyfMPP;

        // Ada chat
        adaEmbed?: AdaEmbedAPI | undefined;

        // Zendesk chat
        zE?: IZenChat | undefined;
        zESettings?: IWidgetPositionSettings | undefined;

        // Wistia
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        _wq: any;
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        Wistia?: any;
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        wistiaEmbed?: any;

        // Iframe resizer
        // See https://github.com/davidjbradshaw/iframe-resizer/blob/master/docs/iframed_page/methods.md
        parentIFrame?: {
            autoResize: (enable: boolean) => void;
            close: () => void;
            getId: () => void;
            getPageInfo: (
                callback: (info: IFrameResizerPageInfo) => void,
            ) => void;
            scrollTo: (x: number, y: number) => void;
            scrollToOffset: (x: number, y: number) => void;
            setHeightCalculationMethod: (
                heightCalculationMethod: string,
            ) => void;
            size: (customHeight?: number, customWidth?: number) => void;
        };

        tsiecom?: TSIEcomPublicAPI;

        grecaptcha?: ReCaptcha;
    }
}

// Polyfill requestIdleCallback / cancelIdleCallback
// See https://developer.mozilla.org/en-US/docs/Web/API/Background_Tasks_API
type RequestIdleCallbackEvent = {
    didTimeout: boolean;
    timeRemaining: () => number;
};

window.requestIdleCallback =
    window.requestIdleCallback ||
    ((cb: (event: RequestIdleCallbackEvent) => void) => {
        const startTime = Date.now();
        return setTimeout(() => {
            cb({
                didTimeout: false,
                timeRemaining: function () {
                    return Math.max(0, 50.0 - (Date.now() - startTime));
                },
            });
        }, 1);
    });

window.cancelIdleCallback =
    window.cancelIdleCallback ||
    ((id: number) => {
        window.clearTimeout(id);
    });

window.tsiecom = publicAPI;

export { config };

import React from "react";
import {
    RichNavigationLinkTypes,
    RichNavigationLinkCollection as Value,
} from "@reactivated";
import { IntrinsicElementProps } from "@thelabnyc/thelabui/src/utils/types";
import { RichNavigationGeneralLink } from "../RichNavigationGeneralLink";
import {
    StreamField,
    StreamFieldBlockMap,
} from "@thelabnyc/thelabui/src/components/StreamField";
import { RichNavigationButtonLink } from "../RichNavigationButtonLink";
import { RichNavigationOfferLink } from "../RichNavigationOfferLink";
import { RichNavigationProductLink } from "../RichNavigationProductLink";
import { RichNavigationImageLink } from "../RichNavigationImageLink";
import { RichNavigationBannerLink } from "../RichNavigationBannerLink";
import { concatClassNames } from "@thelabnyc/thelabui/src/utils/styles";
import { RichNavigationVariantOptions } from "../constants";

const richNavigationLinkTypeComponents: StreamFieldBlockMap<RichNavigationLinkTypes> =
    {
        rich_nav_general_link: RichNavigationGeneralLink,
        rich_nav_offer_link: RichNavigationOfferLink,
        rich_nav_product_link: RichNavigationProductLink,
        rich_nav_image_link: RichNavigationImageLink,
        rich_nav_banner_link: RichNavigationBannerLink,
        rich_nav_button_link: RichNavigationButtonLink,
    };

export interface Props {
    value: Value;
    attrs?: IntrinsicElementProps<HTMLElement>;
}

export const RichNavigationLinkCollection = (props: Props) => {
    if (!props.value.links) {
        return null;
    }
    return (
        <section
            className={concatClassNames([
                "rich-nav-link-collection",
                props.value.variant ?? RichNavigationVariantOptions.DEFAULT,
            ])}
        >
            <h2>{props.value.name}</h2>
            {props.value.links.map((link, i) => (
                <StreamField
                    key={i}
                    components={richNavigationLinkTypeComponents}
                    value={link.value}
                />
            ))}
        </section>
    );
};

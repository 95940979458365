import React from "react";
import ReactTooltip from "react-tooltip";
import SVG from "react-inlinesvg";
import iconQuestionMarkCircle from "../../../../img/icons/question-mark-circle.svg";

import styles from "./Tooltip.module.scss";

export const Tooltip = (props: { options: string; copy: string }) => {
    const tipId = `financing-modal-control__tooltip-${props.options}`;
    // Automatically activated tooltips should not use <button>s as their triggers
    // so using <div> with tabindex="0" as per a360 aricles
    // https://hub.accessible360.com/kb/articles/524#heading-if-youre-using-automatic-activation-
    return (
        <div className={styles.wrapper}>
            {props.options}
            <div data-for={tipId} data-tip={true} tabIndex={0}>
                <SVG
                    aria-hidden="true"
                    className={styles.icon}
                    title="What does this mean?"
                    src={iconQuestionMarkCircle}
                />
                <span className="ada-screenreader-only">
                    {interpolate(gettext("%s account?"), [props.options])}
                </span>
            </div>
            <ReactTooltip
                class={styles.tooltip}
                event={"mouseover focus"}
                eventOff="scroll mousewheel mouseleave mouseout blur"
                id={tipId}
                isCapture={true}
                role="tooltip"
            >
                {props.copy}
            </ReactTooltip>
        </div>
    );
};

import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { strings } from "../../../localization";
import { FormCheckbox } from "../../../forms/FormCheckbox";
import { AgreeFields, AgreeErrors } from "../models";
import { ConsentDisclaimer } from "./ConsentDisclaimer";
import { consentMustBeChecked, termsMustBeChecked } from "./validators";

import commonStyles from "./index.module.scss";

export interface Props {
    isMain: boolean;
    hasAgreed: boolean;
    hasESigned: boolean;
    errors: AgreeErrors;
    showErrorMessages: boolean;
    onAgree: (event: React.FormEvent<HTMLInputElement>) => void;
    onESign: (event: React.FormEvent<HTMLInputElement>) => void;
    onValidStateChange: (
        fieldName: AgreeFields,
        errorMessages: string[],
    ) => void;
}

export const WellsFargoTerms = (props: Props) => {
    const [isConsentOpen, setIsConsentOpen] = useState(true);
    const [isTermsOpen, setIsTermsOpen] = useState(false);

    useEffect(() => {
        // close consent accordion and open terms accordion when consent checkbox is checked
        if (props.hasAgreed) {
            setIsConsentOpen(false);
            setIsTermsOpen(true);
        }
    }, [props.hasAgreed]);

    const PDF = strings.get("FINANCING_TERMS_PDF") || "";
    const agreeName = props.isMain
        ? "agree_disclosure"
        : "agree_disclosure_joint";
    const esignName = props.isMain ? "esign" : "esign_joint";
    const consentClasses = classNames({
        [commonStyles.subheading]: true,
        "accordion": !isConsentOpen,
        "accordion--is-open": isConsentOpen,
    });
    const consentContentClasses = classNames({
        [commonStyles.disclosure]: true,
        "accordion-target": !isConsentOpen,
        "accordion-target--is-open": isConsentOpen,
    });
    const termsClasses = classNames({
        [commonStyles.subheading]: true,
        "accordion": !isTermsOpen,
        "accordion--is-open": isTermsOpen,
    });
    const termsContentClasses = classNames({
        "accordion-target": !isTermsOpen,
        "accordion-target--is-open": isTermsOpen,
    });
    const onClickConsent = () => {
        setIsConsentOpen(!isConsentOpen);
    };
    const onClickTerms = () => {
        setIsTermsOpen(!isTermsOpen);
    };
    return (
        <div className={commonStyles.wrapper}>
            <h3 className={commonStyles.heading}>
                {gettext("Terms & Details")}
            </h3>
            <p>
                {gettext(
                    "You must review the agreements and accept to submit your application.",
                )}
            </p>
            <h4
                className={consentClasses}
                onClick={onClickConsent}
                aria-expanded={isConsentOpen}
                aria-controls={`consent_${esignName}`}
            >
                {gettext("e-Sign Consent")}
            </h4>
            <div className={consentContentClasses} id={`consent_${esignName}`}>
                <ConsentDisclaimer />
            </div>
            <FormCheckbox
                label={gettext(
                    "I consent to receiving my agreements and disclosures electronically.",
                )}
                id={`id_${agreeName}`}
                name={agreeName}
                checked={props.hasAgreed}
                onChange={props.onAgree}
                onValidStateChange={props.onValidStateChange}
                errors={props.errors[agreeName]}
                showErrorMessages={props.showErrorMessages}
                validation={["required", consentMustBeChecked]}
            />
            <h4
                className={termsClasses}
                onClick={onClickTerms}
                aria-expanded={isTermsOpen}
                aria-controls={`terms_${esignName}`}
            >
                {gettext("Card Agreement & Privacy Notice")}
            </h4>
            <div className={termsContentClasses} id={`terms_${esignName}`}>
                <div className="financing-form__disclosure">
                    <embed className="financing-form__pdf" src={PDF} />
                </div>
            </div>
            <a
                href={PDF}
                target="_blank"
                className="financing-form__terms-link"
                rel="noreferrer"
            >
                {gettext("Terms & Conditions [PDF]")}
            </a>
            <p>
                {gettext(
                    "The last thing we need to process your application is your signature.",
                )}
            </p>
            <p>
                {gettext(
                    "Select Submit Application to send Wells Fargo Bank, N.A. your application. Selecting Submit means that all of the information " +
                        "you provided as part of your application is true and complete and that you have received, read, and agree to the terms of our " +
                        "Credit Card Agreement, our Arbitration Agreement, and the Wells Fargo Retail Services Privacy Notice. You agree that in order to " +
                        "process your application, we will obtain information necessary to evaluate your credit, employment, assets, and income records. " +
                        "You also authorize us to obtain a credit report at no cost to you. You give us and we will retain a purchase-money security " +
                        "interest in goods purchased under our Credit Card Agreement. The Arbitration Agreement may not apply to you if you are on active " +
                        "duty military service(including active guard or reserve service) or you are a spouse or dependent of a person who is on such " +
                        "active duty military service (please see the Important Statement Concerning Active Duty Military Service paragraph in the Credit " +
                        "Card Agreement for more information).",
                )}
            </p>
            <div
                dangerouslySetInnerHTML={{
                    __html: strings.get("FINANCING_SHARE_CONTACT_INFO") || "",
                }}
            ></div>
            <h4 className={commonStyles.subheading}>
                {gettext("Applicant Consent")}
            </h4>
            <FormCheckbox
                label={gettext(
                    "I indicate my agreement with the statement above, and that this is my electronic signature.",
                )}
                id={`id_${esignName}`}
                name={esignName}
                checked={props.hasESigned}
                onChange={props.onESign}
                onValidStateChange={props.onValidStateChange}
                errors={props.errors[esignName]}
                showErrorMessages={props.showErrorMessages}
                validation={["required", termsMustBeChecked]}
            />
            <p>
                {gettext(
                    "I also understand that if Wells Fargo, N.A. has no available offers, the above information will be sent to secondary lenders, and those secondary lenders may conduct a soft pull of my credit that will not affect my credit score.",
                )}
            </p>
        </div>
    );
};

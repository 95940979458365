import React from "react";
import SVG from "react-inlinesvg";
import { strToBool } from "tsi-common-react/src/utils/format";
import { RichText } from "tsi-common-react/src/common/RichText";
import { ChatLink } from "tsi-common-react/src/apps/chat/ChatLink";
import { urls } from "tsi-common-react/src/utils/urls";
import { getPageSetting } from "tsi-common-react/src/utils/settings";
import iconSpeechBubblesTalk from "../../svg/speech-bubbles-talk.svg";

interface IProps {}

export const CheckoutSidebarContent = (_props: IProps) => {
    let questionsBlock: JSX.Element | null = null;
    if (strToBool(getPageSetting("enable-checkout-sidebar-questions-block"))) {
        questionsBlock = (
            <div className="basket-summary__help">
                <h3>
                    <SVG
                        className="basket-summary__help-icon"
                        src={iconSpeechBubblesTalk}
                        title={gettext("Speech Bubbles Icon")}
                    />
                    {gettext("Questions?")}
                </h3>
                <div className="basket-summary__text">
                    <ChatLink
                        className="basket-summary__help--link basket-summary__help--link-checkout"
                        chatOfflineLink={urls.pageURL("customer-service")}
                    />
                    <br />
                </div>
            </div>
        );
    }
    return (
        <div className="basket-summary__sidebar-content">
            {questionsBlock}
            <RichText html={getPageSetting("checkout-sidebar-extra-content")} />
        </div>
    );
};

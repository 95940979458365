import React, { useMemo } from "react";
import { interpolate } from "@thelabnyc/thelabui/src/utils/i18n";
import { isoMonths } from "../../../models/nominals";
import { formatFinancingPlan } from "../../../utils/format";
import { urls } from "../../../utils/urls";
import { Link } from "../../../common/Link";
import { FinancingPlanMeta } from "../../../utils/financing";
import { FinancingModalTriggerTheme } from "../../../constants";
import { IWebPageURL } from "../../../models/nominals";

export const Copy = (props: {
    plan: FinancingPlanMeta;
    theme?: FinancingModalTriggerTheme;
    aprInfoID?: string | null;
}) => {
    const plan = useMemo(() => formatFinancingPlan(props.plan), [props.plan]);
    if (props.theme === FinancingModalTriggerTheme.SPECIAL) {
        return (
            <div>
                <h2>{gettext("Special Financing Offers Available")}</h2>
                <p>
                    {gettext(
                        "buy today and pay over time with approved credit",
                    )}
                </p>
            </div>
        );
    }

    const financeLink = urls.pageURL("finance-link");
    const aprInfoURL = props.aprInfoID
        ? `${financeLink}${props.aprInfoID}`
        : financeLink;
    if (
        props.theme === FinancingModalTriggerTheme.MINI_FINANCING ||
        props.theme === FinancingModalTriggerTheme.CARD_OVERLAP
    ) {
        const useMiniFinancingText =
            props.theme === FinancingModalTriggerTheme.MINI_FINANCING;
        const offerCopy = useMiniFinancingText
            ? interpolate(gettext("%(apr)s APR for %(length)s Months"), plan)
            : interpolate(
                  gettext("Enjoy %(apr)s Interest for %(length)s Years"),
                  {
                      ...plan,
                      length: (
                          isoMonths.unwrap(props.plan.length) / 12
                      ).toString(),
                  },
              );
        const subCopy = useMiniFinancingText
            ? gettext(
                  "Check if you pre-qualify without affecting your credit score.",
              )
            : interpolate(gettext("on purchases %(threshold)s or more."), plan);
        return (
            <div>
                <h2>
                    {offerCopy}
                    {props.plan.superscript && (
                        <sup>
                            <Link href={aprInfoURL as IWebPageURL}>
                                {props.plan.superscript}
                                <span className="ada-screenreader-only">
                                    {gettext(
                                        "Additional information about Terms and Conditions",
                                    )}
                                </span>
                            </Link>
                        </sup>
                    )}
                </h2>
                <p>{subCopy}</p>
            </div>
        );
    }
    return (
        <>
            <div className="pre-approval-ad__terms">
                <h2>
                    <div className="pre-approval-ad__terms-line pre-approval-ad__terms-line--1">
                        {interpolate(gettext("%(apr)s APR"), plan)}
                    </div>
                    <div className="pre-approval-ad__terms-line pre-approval-ad__terms-line--2">
                        {gettext("for")}{" "}
                        <div className="pre-approval-ad__line-rule"></div>
                    </div>
                    <div className="pre-approval-ad__terms-line pre-approval-ad__terms-line--3">
                        {interpolate(gettext("%(length)s Months"), plan)}
                        <sup>
                            <Link href={financeLink}>
                                {plan.superscript}
                                <span className="ada-screenreader-only">
                                    {gettext(
                                        "Additional information about Terms and Conditions",
                                    )}
                                </span>
                            </Link>
                        </sup>
                    </div>
                </h2>
            </div>
            <div className="pre-approval-ad__see pre-approval-ad__see--block">
                <div className="pre-approval-ad__see--bold">
                    {gettext("Check if you're pre-qualified")}
                </div>
                <div className="pre-approval-ad__see--small">
                    {gettext("without affecting your credit score.")}
                </div>
            </div>
        </>
    );
};

import React from "react";
import { IOptionValue } from "../../../models/catalogue.interfaces";
import { ImageChooserBlock } from "../../../models/streamfield-blocks";
import { Image } from "../../../common/Image";

interface IProps {
    attribute: IOptionValue;
    icon: ImageChooserBlock | null;
}

interface IState {}

export class HighlightedAttribute extends React.Component<IProps, IState> {
    render() {
        return (
            <>
                {this.props.icon && (
                    <Image src={this.props.icon.url} alt={gettext("")} />
                )}
                <p className="caption">{this.props.attribute}</p>
            </>
        );
    }
}

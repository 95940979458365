import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import * as productAPI from "../../../api/products";
import { TStateMapper, TDispatchMapper } from "../../reducers.interfaces";
import {
    Action,
    IActionFocusProduct,
    IActionBlurProduct,
} from "../reducers.interfaces";
import { defaults } from "../defaults";
import {
    IProductID,
    IProductAPIURL,
    isoWebPageURL,
} from "../../../models/nominals";
import {
    IProduct,
    IProductAttributes,
} from "../../../models/catalogue.interfaces";
import { IAPIPrice } from "../../../models/prices.interfaces";
import { Loaders as CheckoutLoaders } from "../../checkout/loaders";
import { Dispatchers as CheckoutDispatchers } from "../../checkout/dispatchers";
import { Actions as CheckoutActions } from "../../checkout/actions";
import { ProductSchemaTag } from "../../../common/ProductSchemaTag";
import { RatingGraphic } from "../../../common/RatingGraphic";
import { Image } from "../../../common/Image";
import { Link } from "../../../common/Link";
import { RawHTML } from "../../../common/RawHTML";
import { money, formatTermLength } from "../../../utils/format";
import { getMonthlyPrice } from "../../../utils/money";
import {
    PriceType,
    getProductPrice,
    isProductPriceDiscounted,
} from "../../../utils/catalogue";
import {
    SortType,
    sortProductVariants,
    getCheapestProductVariant,
} from "../../../utils/sorting";
import { isMobile } from "../../../utils/detectMobile";
import { trackAddToBasketEvent } from "../../../utils/analytics";
import { urls } from "../../../utils/urls";
import { IProductCardType } from "../models.interfaces";
import { getProductVariant } from "../../selectors";
import { ProductCardConfigurator } from "./ProductCardConfigurator";

import styles from "./ProductCard.module.scss";

export const enum CartButtonStatus {
    INITIAL,
    ADDING,
    ADDED,
}

interface ICardPortionProps {
    gridVariant: string;
    cardType: IProductCardType;
    rootProduct: IProduct;
    variant: IProduct | null;
    customStyle?: string;
}

export const CardTitle = (props: ICardPortionProps) => {
    const titleClass = classNames({
        [styles.title]: true,
        [`${props.cardType}__title`]: true,
        "product-grid-card__title--no-pre":
            !props.rootProduct.attributes.product_callout,
        [`${props.cardType}__title--no-pre`]:
            !props.rootProduct.attributes.product_callout,
    });
    return <h3 className={titleClass}>{props.rootProduct.title}</h3>;
};

export const PillowSleepBanner = (props: ICardPortionProps) => {
    if (props.gridVariant !== "pillows") {
        return null;
    }
    const sleepPositions: Array<keyof IProductAttributes> = [
        "back_sleeper",
        "side_sleeper",
        "stomach_sleeper",
    ];
    const positionNames = sleepPositions
        .filter((position) => {
            const attr = props.rootProduct.attributes[position];
            return attr ? attr.value : false;
        })
        .map((position) => {
            const attr = props.rootProduct.attributes[position];
            return attr ? attr.name.split(" ")[0] : "";
        });
    let sleepPosition: string | null = null;
    if (positionNames.length === 1) {
        sleepPosition = interpolate(gettext("%s sleepers"), positionNames);
    } else if (positionNames.length === 2) {
        sleepPosition = interpolate(
            gettext("%s and %s sleepers"),
            positionNames,
        );
    } else if (positionNames.length === 3) {
        sleepPosition = interpolate(
            gettext("%s, %s, and %s sleepers"),
            positionNames,
        );
    }
    if (!sleepPosition) {
        return null;
    }
    return <div className={styles.badge}>{sleepPosition}</div>;
};

export const CardImage = (props: ICardPortionProps) => {
    if (props.rootProduct.images.length <= 0) {
        return null;
    }
    const imageClass = classNames({
        ["responsive-img"]: true,
        [styles.img]: true,
        [`${props.cardType}__image`]: true,
        [`${props.cardType}--${props.gridVariant}__image`]: true,
    });
    const image =
        props.rootProduct.images.length > 1
            ? props.rootProduct.images.find(
                  (img) => img.role === "Product Tile",
              )
            : props.rootProduct.images[0];
    if (!image) {
        return null;
    }
    return (
        <Image
            src={image.original}
            className={imageClass}
            alt={props.rootProduct.title}
        />
    );
};

export const CardPromo = (props: ICardPortionProps) => {
    if (!props.rootProduct.attributes.promo_badge) {
        return null;
    }
    return (
        <div className={styles.promo}>
            <div className={styles.middleCircle}>
                <div className={styles.innerCircle}>
                    <span className={styles.promoCopy}>
                        {`${props.rootProduct.attributes.promo_badge.value}`}
                    </span>
                </div>
            </div>
        </div>
    );
};

export const CardCallout = (props: ICardPortionProps) => {
    const promo_callout = props.rootProduct.promo_callout
        ? props.rootProduct.promo_callout
        : props.rootProduct.attributes.product_callout?.value;
    if (!promo_callout) {
        return null;
    }
    return (
        <div className={styles.callout}>
            <span>{promo_callout}</span>
        </div>
    );
};

export const CardProductLink = (
    props: ICardPortionProps & {
        children: React.ReactNode;
        hashFragment?: string;
    },
) => {
    const linkTitle = interpolate(gettext("Learn more about %s"), [
        props.rootProduct.title,
    ]);
    let url = props.variant ? props.variant.link : props.rootProduct.link;
    if (props.hashFragment) {
        if (!url) {
            url = isoWebPageURL.wrap("");
        }
        url = isoWebPageURL.wrap(
            `${isoWebPageURL.unwrap(url)}#${props.hashFragment}`,
        );
    }
    return (
        <Link href={url} title={linkTitle} className={props.customStyle}>
            {props.children}
        </Link>
    );
};

export const CardRating = (props: ICardPortionProps) => {
    if (!props.rootProduct.rating) {
        return null;
    }
    return (
        <CardProductLink {...props} hashFragment={"reviews"}>
            <RatingGraphic
                rating={props.rootProduct.rating}
                numReviews={props.rootProduct.num_reviews}
                onlyReviewsNum={false}
                cardClass={props.cardType}
                cardSize={"small"}
            />
        </CardProductLink>
    );
};

export const CardCopy = (props: ICardPortionProps) => {
    const copyClass = classNames({
        [styles.copy]: true,
        [`${props.cardType}__copy`]: true,
        [`${props.cardType}--${props.gridVariant}__copy`]: true,
    });
    return (
        <RawHTML className={copyClass} html={props.rootProduct.description} />
    );
};

export const CardFeelBadge = (props: ICardPortionProps) => {
    const feelClass = classNames({
        [`${props.cardType}__feel-badge`]: true,
    });
    let feel = props.rootProduct.attributes.feel?.value;
    if (!feel) {
        const sortedChildren = sortProductVariants(
            props.rootProduct,
            SortType.PRESELECT,
        );
        for (const child of sortedChildren) {
            if (!feel && child.attributes.feel) {
                feel = child.attributes.feel.value;
            }
        }
    }
    if (!feel) {
        return null;
    }
    return (
        <div className={feelClass}>
            {interpolate(gettext("FEEL: %s"), [feel])}
        </div>
    );
};

interface ICardPriceContentProps extends ICardPortionProps {
    price: IAPIPrice | null;
    quantity: number;
}

const CardPriceContent = (props: ICardPriceContentProps) => {
    if (!props.variant) {
        return null;
    }
    const priceWrapperClass = classNames({
        [styles.priceContainer]: true,
        [`${props.cardType}__price`]: true,
        [`${props.cardType}--${props.gridVariant}__price`]: true,
    });
    const qtyAdjustedRetailPrice = getProductPrice(
        props.price ?? props.variant.price,
        {
            priceType: PriceType.COSMETIC_EXCL_TAX,
            includePostDiscountAddons: true,
            quantity: props.quantity,
        },
    );
    const qtyAdjustedActualPrice = getProductPrice(
        props.price ?? props.variant.price,
        {
            priceType: PriceType.COSMETIC_EXCL_TAX,
            includePostDiscountAddons: true,
            quantity: props.quantity,
        },
    );
    const isDiscounted =
        props.price && isProductPriceDiscounted(props.price.total);
    const regularPriceClass = classNames({
        ["product-grid-card__price__actual"]: true,
        [`grid-card-configurator__price__actual--${props.gridVariant}`]: true,
        [styles.priceRegular]: true,
        [styles.priceActualHighlight]: isDiscounted,
    });
    const priceCopy =
        props.rootProduct.children.length > 0 ? (
            <span className={styles.priceCopy}>{gettext("Starting at")}</span>
        ) : null;
    if (
        !props.variant.availability.is_available_to_buy ||
        !qtyAdjustedActualPrice
    ) {
        return (
            <div className={priceWrapperClass}>
                <span className={styles.priceUnavilable}>Unavailable</span>
            </div>
        );
    }
    if (!isDiscounted) {
        return (
            <div className={priceWrapperClass}>
                {priceCopy}
                <CardProductLink {...props}>
                    <span className="product-grid-card__regular-price">
                        <span className={regularPriceClass}>
                            {money(qtyAdjustedActualPrice)}
                        </span>
                    </span>
                </CardProductLink>
            </div>
        );
    }
    return (
        <>
            <div className={priceWrapperClass}>
                {priceCopy}
                <CardProductLink {...props}>
                    <span className="product-grid-card__sale-price">
                        <span className={styles.priceRetail}>
                            {money(qtyAdjustedRetailPrice)}
                        </span>
                        <span className={regularPriceClass}>
                            {money(qtyAdjustedActualPrice)}
                        </span>
                    </span>
                </CardProductLink>
            </div>
            {props.variant.price.enable_per_month_pricing &&
                props.variant.price.per_month_term_length && (
                    <a
                        href="https://www.tempurpedicoutlet.com/finance-your-purchase/#terms_and_conditions"
                        className={styles.financing}
                    >
                        Or{" "}
                        <span className={styles.financingUnderline}>
                            {money(
                                getMonthlyPrice(
                                    qtyAdjustedActualPrice,
                                    props.variant.price.per_month_term_length,
                                ),
                            )}
                            /mo
                        </span>
                        <sup>2</sup> for{" "}
                        <span className={styles.financingUnderline}>
                            {formatTermLength(
                                props.variant.price.per_month_term_length,
                            )}{" "}
                            months
                        </span>
                        <sup>1</sup> (total payments of{" "}
                        {money(qtyAdjustedActualPrice)})
                    </a>
                )}
        </>
    );
};

interface ICardPriceProps extends ICardPortionProps {
    quantity: number;
}

interface ICardPriceState {
    price: IAPIPrice | null;
}

export class CardPrice extends React.Component<
    ICardPriceProps,
    ICardPriceState
> {
    state: ICardPriceState = {
        price: null,
    };

    componentDidMount() {
        this.loadPrice();
    }

    componentDidUpdate(prevProps: ICardPriceProps) {
        if (
            this.props.variant?.url !== prevProps.variant?.url ||
            this.props.quantity !== prevProps.quantity
        ) {
            this.loadPrice();
        }
    }

    private async loadPrice() {
        try {
            const price = await this.innerLoadPrice();
            this.setState({
                price: price,
            });
        } catch (e) {
            this.setState({
                price: null,
            });
        }
    }

    private async innerLoadPrice() {
        // If no product variant selected, abort.
        if (!this.props.variant) {
            return null;
        }
        // If the quantity is 1, use the default price from the product API.
        if (this.props.quantity === 1) {
            return null;
        }
        // If the price for this product/quantity is already loaded, abort
        if (
            this.props.variant.url === this.state.price?.product &&
            this.props.quantity === this.state.price?.quantity
        ) {
            return null;
        }
        return productAPI.getProductPrice(
            this.props.variant.url,
            this.props.quantity,
        );
    }

    render() {
        return (
            <CardPriceContent
                {...this.props}
                price={this.state.price}
                quantity={this.props.quantity}
            />
        );
    }
}

export const CardDefaultPrice = (props: ICardPortionProps) => {
    props = { ...props };
    if (!props.variant) {
        props.variant = props.rootProduct.availability.is_available_to_buy
            ? props.rootProduct
            : getCheapestProductVariant(props.rootProduct);
    }
    return <CardPrice {...props} quantity={1} />;
};

export const CardHoverPrice = (
    props: ICardPortionProps & { quantity: number },
) => {
    return <CardPrice {...props} />;
};

interface ICardAddToCartButtonProps extends ICardPortionProps {
    quantity: number;
    buttonStatus: CartButtonStatus;
    onAddToBasket: () => void;
}

export const CardAddToCartButton = (props: ICardAddToCartButtonProps) => {
    const buttonClass = classNames({
        "button": true,
        "button--primary-congress-blue": true,
        [`${props.cardType}__button`]: true,
        [`${props.cardType}__button--${props.variant?.id}`]: true,
        [`al-${props.cardType}__button`]: true,
        [`al-${props.cardType}__button--add-to-cart`]: true,
    });
    if (!props.variant || !props.variant.availability.is_available_to_buy) {
        return (
            <div>
                <br />
                <div className="product-grid-card__unavailable">
                    {props.variant ? props.variant.availability.message : ""}
                </div>
                <button className={buttonClass} disabled={true}>
                    {gettext("UNAVAILABLE")}
                </button>
            </div>
        );
    }
    return (
        <button
            className={buttonClass}
            onClick={props.onAddToBasket}
            disabled={props.buttonStatus !== CartButtonStatus.INITIAL}
        >
            {gettext("Add to Cart")}
        </button>
    );
};

export const CardViewCartButton = (props: ICardPortionProps) => {
    const buttonClass = classNames({
        "button": true,
        "button--primary-congress-blue": true,
        [`${props.cardType}__button`]: true,
        [`${props.cardType}__button--${props.variant?.id}`]: true,
        [`al-${props.cardType}__button`]: true,
        [`al-${props.cardType}__button--go-to-cart`]: true,
    });
    return (
        <Link className={buttonClass} href={urls.pageURL("basket-summary")}>
            {gettext("Go to Cart")}
        </Link>
    );
};

export const CardFullDetailsLink = (props: ICardPortionProps) => {
    const wrapperClass = classNames({
        [`${props.cardType}__full-details`]: true,
    });
    return (
        <div className={wrapperClass}>
            <CardProductLink {...props}>
                {gettext("SEE FULL DETAILS")}
            </CardProductLink>
        </div>
    );
};

interface ICardHoverOverlayProps extends ICardPortionProps {
    quantity: number;
    isOpen: boolean;
    isMobile: boolean;
    buttonStatus: CartButtonStatus;
    onAddToBasket: () => void;
}

export const CardHoverOverlay = (props: ICardHoverOverlayProps) => {
    const overlayClass = classNames({
        [`${props.cardType}__hover-state`]: true,
        [`${props.cardType}__hover-state--visible`]: props.isOpen,
        [`${props.cardType}__hover-state--display-none`]: props.isMobile,
    });
    const contentClass = classNames({
        [`${props.cardType}--${props.gridVariant}__content`]: true,
        [`${props.cardType}__hover-state__content`]: true,
    });
    return (
        <div className={overlayClass}>
            <PillowSleepBanner {...props} />
            <CardProductLink {...props}>
                <CardImage {...props} />
                <CardPromo {...props} />
            </CardProductLink>
            <div className={contentClass}>
                <CardProductLink {...props}>
                    <CardCallout {...props} />
                    <CardTitle {...props} />
                </CardProductLink>
                <CardRating {...props} />
                <CardProductLink {...props}>
                    <CardCopy {...props} />
                </CardProductLink>
                <CardFeelBadge {...props} />
                <ProductCardConfigurator rootProduct={props.rootProduct} />
                <CardHoverPrice {...props} />
                {/* financing*/}
                {props.buttonStatus === CartButtonStatus.ADDED ? (
                    <CardViewCartButton {...props} />
                ) : (
                    <CardAddToCartButton {...props} />
                )}
                <CardFullDetailsLink {...props} />
            </div>
        </div>
    );
};

interface IOwnProps {
    gridVariant: string;
    cardType: IProductCardType;
    rootProduct: IProduct;
    disableHoverOverlay?: boolean;
    buildExternalCallout?: (rootProduct: IProduct) => React.ReactNode;
    forwardedRef?: React.RefObject<HTMLDivElement>;
}

interface IReduxProps {
    isFocused: boolean;
    variant: IProduct | null;
    quantity: number;
}

interface IDispatchProps {
    onFocus: (productID: IProductID) => void;
    onBlur: (productID: IProductID) => void;
    onAddToBasket: (
        productURL: IProductAPIURL,
        quantity: number,
    ) => Promise<void>;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {
    buttonStatus: CartButtonStatus;
}

export class ProductCardComponent extends React.PureComponent<IProps, IState> {
    state: IState = {
        buttonStatus: CartButtonStatus.INITIAL,
    };

    private readonly onHoverOverlayOpen = () => {
        if (this.props.disableHoverOverlay || this.props.isFocused) {
            return;
        }
        this.props.onFocus(this.props.rootProduct.id);
    };

    private readonly onHoverOverlayClose = (
        event: React.MouseEvent<HTMLElement>,
    ) => {
        // Firefox will emit onMouseLeave events when hoving AND clicking on a select box
        // However we don't want to toggle the hover state just by hoving or clicking the select box
        if (
            (event.target as HTMLElement).tagName === "SELECT" ||
            event.currentTarget.tagName === "SELECT"
        ) {
            return;
        }
        this.props.onBlur(this.props.rootProduct.id);
    };

    private readonly onAddToBasket = async () => {
        if (!this.props.variant) {
            console.error(
                "Can not add to basket because no variant is selected",
            );
            return;
        }
        this.setState({
            buttonStatus: CartButtonStatus.ADDING,
        });
        await this.props.onAddToBasket(
            this.props.variant.url,
            this.props.quantity,
        );
        this.setState({
            buttonStatus: CartButtonStatus.ADDED,
        });
        trackAddToBasketEvent(this.props.variant, this.props.quantity);
    };

    render() {
        const cardClass = classNames({
            ["product-grid-card"]: true, // used for Masonry Grid Layout
            [styles.card]: true,
            [this.props.cardType]: true,
            [`cardType--${this.props.rootProduct.id}`]: true,
            [`${this.props.cardType}--${this.props.gridVariant}`]: true,
        });
        const contentClass = classNames({
            ["grid-card__content"]: true,
            [`${this.props.cardType}--${this.props.gridVariant}__content`]:
                true,
        });
        return (
            <div
                role="group"
                aria-label="product"
                ref={this.props.forwardedRef}
                className={cardClass}
                onMouseEnter={this.onHoverOverlayOpen}
                onMouseLeave={this.onHoverOverlayClose}
            >
                {!this.props.disableHoverOverlay && (
                    <CardHoverOverlay
                        {...this.props}
                        isOpen={this.props.isFocused}
                        isMobile={isMobile.any()}
                        buttonStatus={this.state.buttonStatus}
                        onAddToBasket={this.onAddToBasket}
                    />
                )}
                <PillowSleepBanner {...this.props} />
                <CardProductLink {...this.props}>
                    <CardImage {...this.props} />
                    <CardPromo {...this.props} />
                </CardProductLink>
                <div className={contentClass}>
                    <CardProductLink {...this.props}>
                        {this.props.buildExternalCallout
                            ? this.props.buildExternalCallout(
                                  this.props.rootProduct,
                              )
                            : null}
                        <CardCallout {...this.props} />
                        <CardTitle {...this.props} />
                    </CardProductLink>
                    <CardRating {...this.props} />
                    <CardProductLink {...this.props}>
                        <CardCopy {...this.props} />
                    </CardProductLink>
                    <CardDefaultPrice {...this.props} />
                </div>
                <ProductSchemaTag
                    name={this.props.rootProduct.title}
                    imageUrl={
                        this.props.rootProduct.images[0] &&
                        this.props.rootProduct.images[0].original
                    }
                    description={this.props.rootProduct.description}
                    sku={this.props.rootProduct.upc}
                    brandName="Tempur-Pedic"
                    ratingValue={this.props.rootProduct.rating}
                    reviewCount={this.props.rootProduct.num_reviews}
                />
            </div>
        );
    }
}

const mapStateToProps: TStateMapper<"productgrid2", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.productgrid2 || defaults;
    return {
        ...ownProps,
        isFocused: state.focusedProductID === ownProps.rootProduct.id,
        variant: getProductVariant([ownProps.rootProduct], state.optionValues),
        quantity: state.quantity,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    return {
        onFocus: (productID) => {
            dispatch<IActionFocusProduct>({
                type: Action.FOCUS_PRODUCT,
                payload: productID,
            });
        },
        onBlur: (productID) => {
            dispatch<IActionBlurProduct>({
                type: Action.BLUR_PRODUCT,
                payload: productID,
            });
        },
        onAddToBasket: async (productURL, qty) => {
            const dispatchers = new CheckoutDispatchers(dispatch);
            const loaders = new CheckoutLoaders(dispatchers);
            const actions = new CheckoutActions(loaders, dispatchers);
            await actions.addBasketLine(productURL, qty);
        },
    };
};

export const ProductCard = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProductCardComponent);

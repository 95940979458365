import React from "react";
import classNames from "classnames";
import styles from "./EmailCaptureContent.module.scss";
import { Form } from "../../../forms/Form";
import { FormSubmit } from "../../../forms/FormSubmit";
import { FormInput } from "../../../forms";
import { signupForEmailOffers } from "../../../api/user";

export interface IEmailModal {
    title: string;
    content: string;
    confirm_content: string;
    image: string;
    footnotes?: string;
}

export interface IEmailModalSnippet {
    email_snippet: IEmailModal;
}

interface IProps {
    content: IEmailModalSnippet;
    onSubmit?: (height?: number) => void;
}

interface IState {
    isSubmitting: boolean;
    formSubmitted: boolean;
    email: string;
}

export class EmailCaptureContent extends React.Component<IProps, IState> {
    private contentElem: HTMLElement | undefined | null;

    public state: IState = {
        isSubmitting: false,
        formSubmitted: false,
        email: "",
    };

    private readonly onSubmit = async (
        event: React.FormEvent<HTMLFormElement>,
    ) => {
        event.preventDefault();

        this.setState({
            isSubmitting: true,
        });

        try {
            await signupForEmailOffers(this.state.email);

            this.setState(
                {
                    isSubmitting: false,
                    formSubmitted: true,
                },
                () => {
                    if (this.props.onSubmit && this.contentElem) {
                        // If submit function exists, pass new height of content up to parent
                        this.props.onSubmit(
                            this.contentElem.getBoundingClientRect().height,
                        );
                    }
                },
            );
        } catch (error) {
            const data = error.response.body;

            console.log(data);
            this.setState({
                isSubmitting: false,
            });
        }
    };

    private readonly onEmailChange = async (
        event: React.FormEvent<HTMLInputElement>,
    ) => {
        const email = event.currentTarget.value;
        this.setState({
            email: email,
        });
    };

    private buildFootnotes() {
        const footnotesStyles = classNames({
            [styles.footnotes]: true,
            [styles.footnotesSubmitted]: this.state.formSubmitted,
        });

        if (!this.props.content.email_snippet.footnotes) {
            return;
        }

        return (
            <div
                className={footnotesStyles}
                dangerouslySetInnerHTML={{
                    __html: this.props.content.email_snippet.footnotes,
                }}
            ></div>
        );
    }

    render() {
        const contentString: string = this.state.formSubmitted
            ? this.props.content.email_snippet.confirm_content
            : this.props.content.email_snippet.content;

        return (
            <>
                <section
                    className={styles.copySection}
                    ref={(ref) => {
                        this.contentElem = ref;
                    }}
                >
                    <div
                        className={`${styles.copy} rich-text`}
                        dangerouslySetInnerHTML={{ __html: contentString }}
                        role="status"
                    ></div>
                    {!this.state.formSubmitted && (
                        <div className={styles.form}>
                            <Form onSubmit={this.onSubmit}>
                                <FormInput
                                    id="email_modal_email"
                                    name="email_modal_email"
                                    type="email"
                                    placeholder={gettext("Email address")}
                                    required={true}
                                    autoComplete="email"
                                    value={this.state.email}
                                    onChange={this.onEmailChange}
                                    disabled={this.state.isSubmitting}
                                />
                                <FormSubmit
                                    type="submit"
                                    value={gettext("Submit")}
                                />
                            </Form>
                        </div>
                    )}
                    {this.buildFootnotes()}
                </section>
            </>
        );
    }
}

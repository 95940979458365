import React from "react";
import classNames from "classnames";
import { IImageURL, ISafeHTML, IWebPageURL } from "../../../models/nominals";
import {
    IProduct,
    IConcreteBundle,
} from "../../../models/catalogue.interfaces";
import { IAPIPrice, IPrice } from "../../../models/prices.interfaces";
import LoadingSpinner from "../../../common/LoadingSpinner";
import { IUpsellModalComponentClass } from "../models.interfaces";
import { ConfiguratorPrice } from "../../../common/ConfiguratorPrice";
import { ProductUnavailable } from "../../../common/ProductUnavailable";
import { RichText } from "../../../common/RichText";
import { ConfiguratorPriceLoading } from "../elements/ConfiguratorPriceLoading";
import { ConfiguratorOptions } from "../elements/ConfiguratorOptions";
import { RatingsSummary } from "../elements/RatingsSummary";
import { QuantitySelector } from "../elements/QuantitySelector";
import { ConfiguratorOptionSelectionError } from "../elements/ConfiguratorOptionSelectionError";
import { AddToBasketButton } from "../elements/AddToBasketButton";
import { ConfiguratorTypes } from "../../../constants";
import { IModularConfiguratorOptionSet } from "../models.interfaces";
import { ConfiguratorDeliveryDisclaimer } from "../elements/ConfiguratorDeliveryDisclaimer";
import { ConfiguratorShippingCallout } from "../elements/ConfiguratorShippingCallout";
import { ConfiguratorUpsells } from "../elements/ConfiguratorUpsells";
import { ConfiguratorReviewAggregation } from "../elements/ConfiguratorReviewAggregation";
import { ConfiguratorDynamicshippingTimeMessaging } from "../elements/ConfiguratorDynamicshippingTimeMessaging";
import { FinancingModalTriggerConfigurator } from "../../financing/FinancingModalTrigger";
import { getTotalPriceWithAddons } from "../../../utils/money";
import { FinancingPlans } from "../../../models/financing";
import {
    filterFinancingPlans,
    getLongestFinancingPlan,
} from "../../checkout/utils";

interface IProps {
    optionSet: IModularConfiguratorOptionSet;
    // Basic Data
    title?: string;
    preheader?: string;
    subhead?: string;
    description?: string;
    financingLink: IWebPageURL;
    rootProduct: IProduct | null;
    concreteBundles: IConcreteBundle[];
    baseVariant: IProduct | null;
    upgradedVariant: IProduct | null;
    price: IAPIPrice | null;
    financingPlans?: FinancingPlans;
    showImage?: boolean;
    promoCopy?: string;
    chatLinkComponent?: JSX.Element | null;
    liveChatHeader?: string;
    basketLink: IWebPageURL;
    configureGiftsLink: IWebPageURL;

    showShippingLead?: boolean;
    showUpsellModalIcon?: boolean;
    // Product Add-Ons
    selectedAddonPrice: IPrice | null;

    // Add to favorites button
    enableAddToFavorites: boolean;
    addToFavoritesButtonText: string;
    onAddToFavorites: () => void;

    // Optional: Up Sell Modal functionality
    showUpsellModal?: (product: IProduct | null) => boolean;
    getUpsellModalComponentClass?: (
        product: IProduct,
    ) => IUpsellModalComponentClass | null;

    // Colors
    buttonColor: string;

    // Misc UI options
    configType: string;
    getDeliveryCopy: (
        rootProduct: IProduct,
        isPreorder: boolean,
    ) => string | null;
    getDeliveryIcon?: () => IImageURL | null;
    showFeelScale: boolean;
    showPreTitle: boolean;
    learnMoreSelector: string;
    hideLearnMoreLink?: boolean;
    showVariantCallout: boolean;
    starRatingURL?: IWebPageURL;
    strikeThroughMSRP: boolean;
    actualPriceStyle: string;
    boxHeaderContent: string;
    topBadgeContent: ISafeHTML | string;
    overrideFinancingCopy: string;
    supDollarSign?: boolean;
    showStarRatingsModal?: boolean;
    showFinancingModal?: boolean;
    phoneNumber?: string;
    optionSelectionErrorReason?: string;
    showImagePerUpgradeOption: boolean;
    isPlpPanelStyle?: boolean;

    children?: React.ReactNode;
}

interface IState {}

export class Configurator extends React.PureComponent<IProps, IState> {
    private readonly onAddToFavoritesClick = (
        e: React.FormEvent<HTMLElement>,
    ) => {
        e.preventDefault();
        this.props.onAddToFavorites();
    };

    private buildBoxHeader() {
        if (!this.props.boxHeaderContent) {
            return null;
        }
        return (
            <div className="configurator__top-copy">
                {this.props.boxHeaderContent}
            </div>
        );
    }

    private buildTopBadge() {
        if (!this.props.topBadgeContent) {
            return null;
        }
        return (
            <div className="configurator__new">
                <RichText html={this.props.topBadgeContent} tagName="span" />
            </div>
        );
    }

    private buildOptionSelectors() {
        if (!this.props.rootProduct) {
            return null;
        }
        return (
            <ConfiguratorOptions
                optionSet={this.props.optionSet}
                chatLinkComponent={this.props.chatLinkComponent}
                liveChatHeader={this.props.liveChatHeader}
                financingLink={this.props.financingLink}
                isPlpPanelStyle={this.props.isPlpPanelStyle}
            />
        );
    }

    private buildPrice() {
        if (!this.props.price || !this.props.upgradedVariant) {
            return (
                <ConfiguratorPriceLoading
                    configuratorType={this.props.optionSet.layout.design}
                    alternatePriceView={
                        this.props.optionSet.layout.enable_prequal
                    }
                />
            );
        }
        return (
            <ConfiguratorPrice
                price={this.props.price}
                financingPlans={this.props.financingPlans}
                variant={this.props.upgradedVariant}
                selectedAddonPrice={this.props.selectedAddonPrice}
                configuratorType={this.props.optionSet.layout.design}
                financingLink={this.props.financingLink}
                strikeThroughMSRP={this.props.strikeThroughMSRP}
                discountDisplayMode={
                    this.props.optionSet.discounts.discount_mode
                }
                percentageDiscountFormat={
                    this.props.optionSet.discounts.percentage_discount_format
                }
                absoluteDiscountFormat={
                    this.props.optionSet.discounts.absolute_discount_format
                }
                actualPriceStyle={this.props.actualPriceStyle}
                overrideFinancingCopy={this.props.overrideFinancingCopy}
                supDollarSign={this.props.supDollarSign}
                alternatePriceView={
                    this.props.optionSet.layout.design !==
                        ConfiguratorTypes.PANEL &&
                    this.props.optionSet.layout.enable_prequal
                }
                showFinancingModal={this.props.showFinancingModal}
            />
        );
    }

    private getRootClassNames() {
        const brandColor =
            this.props.rootProduct &&
            this.props.rootProduct.attributes.brand_color
                ? this.props.rootProduct.attributes.brand_color.value
                : "";
        return classNames({
            configurator: true,
            [`${this.props.configType}__configurator`]: true,
            [`${this.props.configType}__configurator--${brandColor}`]:
                this.props.optionSet.layout.design ===
                    ConfiguratorTypes.CLASSIC && !!brandColor,
        });
    }

    private getProductUPC() {
        return this.props.rootProduct && this.props.rootProduct.upc
            ? this.props.rootProduct.upc
            : "";
    }

    private getChildrenUPCs() {
        const children =
            this.props.rootProduct && this.props.rootProduct.children
                ? this.props.rootProduct.children
                : [];
        return children.map((c) => {
            return c.upc;
        });
    }

    private getLongestActivePlan() {
        if (this.props.financingPlans) {
            // Before retrieving the longest active financing plan, narrow down the selection to include only plans with a 0% APR
            const filteredFinancingPlans = filterFinancingPlans(
                this.props.financingPlans,
            );
            return getLongestFinancingPlan(filteredFinancingPlans);
        }
        return null;
    }

    private buildPreTitle() {
        if (!this.props.showPreTitle) {
            return null;
        }
        const pretitle = this.props.baseVariant
            ? this.props.baseVariant.availability.short_message
            : gettext("Currently Unavailable");
        return <h2 className="configurator__pretitle">{pretitle}</h2>;
    }

    private buildTitle() {
        if (!this.props.title) {
            return null;
        }
        return <h2 className="configurator__title">{this.props.title}</h2>;
    }

    private buildHeader() {
        const text = (copy: string | undefined) => {
            if (!copy) {
                return null;
            }
            return <RichText html={copy} />;
        };
        return (
            <div className="configurator__header">
                <span className="configurator__preheader">
                    {text(this.props.preheader)}
                </span>
                {this.buildTitle()}
                {text(this.props.subhead)}
            </div>
        );
    }

    private buildReviewAggregation() {
        if (
            !this.props.optionSet.layout.review_aggregation
                ?.review_aggregation_copy
        ) {
            return null;
        }
        return (
            <ConfiguratorReviewAggregation
                reviewAggregationCopy={
                    this.props.optionSet.layout.review_aggregation
                        .review_aggregation_copy
                }
                modalContent={
                    this.props.optionSet.layout.review_aggregation.modal_content
                }
                checkmarkIconURL={
                    this.props.optionSet.layout.review_aggregation
                        .checkmark_icon?.url
                }
                starsImageURL={
                    this.props.optionSet.layout.review_aggregation.modal_image
                        ?.url
                }
            />
        );
    }

    private buildDescription() {
        if (!this.props.description) {
            return null;
        }
        // If an element on the page exists matching the learnMoreSelector prop, include an anchor link to it.
        let learnMoreLink: JSX.Element | null = null;
        if (this.props.learnMoreSelector && !this.props.hideLearnMoreLink) {
            const learnMoreTarget = document.querySelector(
                this.props.learnMoreSelector,
            );
            if (learnMoreTarget) {
                learnMoreLink = (
                    <a
                        href={`${this.props.learnMoreSelector}`}
                        className="configurator__learn-link"
                    >
                        {gettext("Learn More")}
                    </a>
                );
            }
        }

        // This will eventually check against the description field for the rootProduct in Oscar. In order to
        // preserve data, we'll use the CMS data in the interim
        const description: JSX.Element = (
            <span>
                <RichText html={this.props.description} tagName="span" />
                {learnMoreLink}
            </span>
        );

        return <div className="configurator__description">{description}</div>;
    }

    private buildStarRating() {
        const isPanel =
            this.props.optionSet.layout.design === ConfiguratorTypes.PANEL;
        if (!this.props.optionSet.layout.enable_star_rating) {
            return null;
        }
        return (
            <RatingsSummary
                isPanel={isPanel}
                starRatingURL={this.props.starRatingURL}
                showModal={this.props.showStarRatingsModal}
            />
        );
    }

    private buildImage() {
        if (!this.props.rootProduct) {
            return null;
        }

        if (!this.props.showImage || !this.props.rootProduct.images.length) {
            return null;
        }

        const image = this.props.rootProduct.images[0];

        const style = {
            backgroundImage: `url(${image.original})`,
        };

        return <div className="configurator__image" style={style}></div>;
    }

    private buildPromoCopy() {
        if (!this.props.promoCopy) {
            return null;
        }

        return (
            <div className="configurator__promo-copy">
                <RichText html={this.props.promoCopy} />
            </div>
        );
    }

    private buildShippingCallout(): JSX.Element | null {
        if (this.props.optionSet.layout.dynamic_shipping_time_messaging) {
            return (
                <ConfiguratorDynamicshippingTimeMessaging
                    dynamicShippingTimeMessaging={
                        this.props.optionSet.layout
                            .dynamic_shipping_time_messaging
                    }
                    iconURL={
                        this.props.optionSet.layout.shipping_callout_icon?.url
                    }
                    modalShippingMessages={
                        this.props.optionSet.layout.shipping_message
                    }
                    configuratorType={this.props.optionSet.layout.design}
                    phoneNumber={this.props.phoneNumber}
                />
            );
        } else if (this.props.optionSet.layout.shipping_callout_content) {
            return (
                <ConfiguratorShippingCallout
                    iconURL={
                        this.props.optionSet.layout.shipping_callout_icon?.url
                    }
                    content={
                        this.props.optionSet.layout.shipping_callout_content
                    }
                    shippingMessages={
                        this.props.optionSet.layout.shipping_message
                    }
                    configuratorType={this.props.optionSet.layout.design}
                    phoneNumber={this.props.phoneNumber}
                />
            );
        }
        return null;
    }

    private buildConfiguratorContent() {
        if (!this.props.rootProduct) {
            return <LoadingSpinner />;
        }
        const isPanel =
            this.props.optionSet.layout.design === ConfiguratorTypes.PANEL;
        // Render the variant's price and availability information.
        const variantIsAvailableToBuy = !!(
            this.props.rootProduct &&
            this.props.upgradedVariant &&
            this.props.upgradedVariant.availability.is_available_to_buy
        );
        let variantCallout: JSX.Element | null = null;
        let variantPrice: JSX.Element | null = null;
        let variantUnavailableReason: JSX.Element | null = null;
        if (variantIsAvailableToBuy) {
            variantPrice = this.buildPrice();
            if (
                this.props.upgradedVariant &&
                this.props.upgradedVariant.attributes.product_callout &&
                this.props.showVariantCallout
            ) {
                variantCallout = (
                    <div className="configurator__variant-callout">
                        {
                            this.props.upgradedVariant.attributes
                                .product_callout.value
                        }
                    </div>
                );
            }
        } else {
            variantUnavailableReason = (
                <ProductUnavailable variant={this.props.upgradedVariant} />
            );
        }

        // Render delivery disclaimer
        const deliveryWindow: JSX.Element | null = (
            <ConfiguratorDeliveryDisclaimer
                configuratorType={this.props.optionSet.layout.design}
                variantIsAvailableToBuy={variantIsAvailableToBuy}
                rootProduct={this.props.rootProduct}
                upgradedVariant={this.props.upgradedVariant}
                showShippingLead={this.props.showShippingLead}
                phoneNumber={this.props.phoneNumber}
                getDeliveryCopy={this.props.getDeliveryCopy}
                getDeliveryIcon={this.props.getDeliveryIcon}
            />
        );

        const shippingCallout: JSX.Element | null = this.buildShippingCallout();

        // Combine all the content together
        return (
            <div className="configurator__content">
                {this.buildOptionSelectors()}
                {this.props.configType !== "micro" && (
                    <ConfiguratorUpsells
                        configuratorType={this.props.optionSet.layout.design}
                    />
                )}
                {variantCallout}
                {!this.props.showStarRatingsModal &&
                    this.props.rootProduct &&
                    !!this.props.rootProduct.rating &&
                    this.buildStarRating()}
                {!isPanel ? variantPrice : deliveryWindow}
                {!isPanel && variantUnavailableReason}
                {!isPanel ? deliveryWindow : variantPrice}
                {isPanel && variantUnavailableReason}
                {this.buildPromoCopy()}
                {isPanel && (
                    <ConfiguratorOptionSelectionError
                        optionSelectionErrorReason={
                            this.props.optionSelectionErrorReason
                        }
                    />
                )}
                <div className="configurator__selection">
                    {this.props.optionSet.layout.enable_quantity_selector && (
                        <QuantitySelector
                            configuratorType={
                                this.props.optionSet.layout.design
                            }
                        />
                    )}
                    {this.props.enableAddToFavorites && (
                        <button
                            className="button add-to-favorites"
                            disabled={!variantIsAvailableToBuy}
                            onClick={this.onAddToFavoritesClick}
                        >
                            {this.props.addToFavoritesButtonText}
                        </button>
                    )}
                    <AddToBasketButton
                        basketLink={this.props.basketLink}
                        configureGiftsLink={this.props.configureGiftsLink}
                        configuratorType={this.props.optionSet.layout.design}
                        enableQuantitySelector={
                            this.props.optionSet.layout.enable_quantity_selector
                        }
                        buttonColor={this.props.buttonColor}
                        showUpsellModal={this.props.showUpsellModal}
                        getUpsellModalComponentClass={
                            this.props.getUpsellModalComponentClass
                        }
                    />
                </div>
                {shippingCallout}
            </div>
        );
    }

    private buildPromoComponents() {
        if (!this.props.optionSet.layout.enable_prequal || !this.props.price) {
            return null;
        }
        const totalPriceWithAddons = getTotalPriceWithAddons(
            this.props.price.total,
            this.props.selectedAddonPrice,
        );
        const longestActivePlan = this.getLongestActivePlan();
        const planLength = longestActivePlan
            ? longestActivePlan.term_months
            : this.props.upgradedVariant?.price.per_month_term_length ||
              totalPriceWithAddons?.per_month_term_length;
        return (
            <FinancingModalTriggerConfigurator
                planLength={planLength}
                financingLink={this.props.financingLink}
            />
        );
    }

    render() {
        const configuratorClass = this.getRootClassNames();
        const productUPC = this.getProductUPC();
        const childrenUPCJSON = JSON.stringify(this.getChildrenUPCs());

        return (
            <div
                className={configuratorClass}
                data-mpn={productUPC}
                data-children-mpns={childrenUPCJSON}
            >
                {this.buildBoxHeader()}
                <div className="configurator__inner">
                    {this.buildImage()}
                    {this.buildTopBadge()}
                    {this.buildPreTitle()}
                    {this.buildHeader()}
                    {this.props.showStarRatingsModal &&
                        this.props.rootProduct &&
                        this.buildStarRating()}
                    {this.buildReviewAggregation()}
                    {this.buildDescription()}
                    {this.buildConfiguratorContent()}
                    {this.buildPromoComponents()}
                    {this.props.children}
                </div>
            </div>
        );
    }
}

import React from "react";
import { RichTextBlock } from "@thelabnyc/thelabui/src/components/RichText";
import { IntrinsicElementProps } from "@thelabnyc/thelabui/src/utils/types";
import { _RichNavigation__GeneralLink as Value } from "@reactivated";

export interface Props {
    value: Value;
    attrs?: IntrinsicElementProps<HTMLElement>;
}

export const RichNavigationButtonLink = (props: Props) => {
    return (
        <a href={props.value.destination} className="button">
            {props.value.promo_period?.is_promo_active && <p>Promo Active</p>}
            <RichTextBlock value={props.value.text} />
        </a>
    );
};

import { submitReview } from "../../api/reviews";
import { IFormUUID } from "../../models/nominals";
import { objectKeys } from "../../utils/functional";
import { getPageSetting } from "../../utils/settings";
import { IWriteReviewTemplate } from "../../models/reviews.interfaces";
import { runValidator } from "../../forms/validation";
import { updateGTMUserHash } from "../../utils/analytics";
import { IWriteReviewFormState } from "./reducers.interfaces";
import { Dispatchers } from "./dispatchers";
import {
    WriteReviewFormMode,
    SubmitReviewStatus,
    REVIEW_VARIANT_NOT_SELECTED,
} from "./constants";

const appendMultiReviewMessage = (isMultiReview?: boolean) => {
    if (!isMultiReview) {
        return "";
    }
    return ". If you do not wish to review this product, please uncheck the box above.";
};
const getCustomRequiredMessage = (
    fieldName: string,
    isMultiReview?: boolean,
) => {
    switch (fieldName) {
        case "rating":
            return gettext("Please select a star rating");
        case "name":
            return gettext("Please enter a nickname");
        case "headline":
            return gettext(
                "Please enter a headline" +
                    appendMultiReviewMessage(isMultiReview),
            );
        case "comments":
            return gettext(
                "Please describe your experience" +
                    appendMultiReviewMessage(isMultiReview),
            );
        case "location":
            return gettext("Please enter your location");
        case "bottomline":
            return gettext(
                "Please choose yes or no" +
                    appendMultiReviewMessage(isMultiReview),
            );
        case "email_collection":
            return gettext("Please enter your email address");
        default:
            return gettext("This field is required");
    }
};

const getReviewDisclosureParams = () => {
    const url = new URL(window.location.href);
    return {
        source: url.searchParams.get("source") || "web",
        disclosure_code: url.searchParams.get("disclosure_code") || "",
    };
};
const getReviewSiteSource = () => {
    const app_name = getPageSetting("app-display-name");
    return {
        review_source: app_name || "",
    };
};
const clearProductErrors = (errors: IWriteReviewFormState["errors"]) => {
    const multiReviewRequiredFields = [
        "headline",
        "rating",
        "comments",
        "bottomline",
    ];

    const hasErrorMsg = (key: string) => {
        return errors[key].length > 0;
    };

    const incompleteForm = multiReviewRequiredFields.every(hasErrorMsg);

    if (incompleteForm) {
        multiReviewRequiredFields.forEach((field) => {
            errors[field] = [];
        });
    }
};
export class Actions {
    private readonly dispatchers: Dispatchers;

    constructor(dispatchers: Dispatchers) {
        this.dispatchers = dispatchers;
    }

    /**
     * Submit a new review to the reviews API
     */
    public readonly submitReview = async (
        formUUID: IFormUUID,
        template: IWriteReviewTemplate,
        form: IWriteReviewFormState,
        isMultiReview?: boolean,
    ): Promise<SubmitReviewStatus> => {
        if (!form.selectedProductID) {
            console.error(
                "Can not submit review without first selecting a product",
            );
            this.dispatchers.setReviewFormErrors({
                formUUID: formUUID,
                errors: {},
                showErrors: false,
                showProductSelectError: true,
                showVariantSelectError: false,
            });
            return SubmitReviewStatus.FAIL_MISSING_PRODUCT_SELECTION;
        }

        if (form.selectedVariant === REVIEW_VARIANT_NOT_SELECTED) {
            console.error(
                "Can not submit review without first selecting a variant or 'not-sure'",
            );
            this.dispatchers.setReviewFormErrors({
                formUUID: formUUID,
                errors: {},
                showErrors: false,
                showProductSelectError: false,
                showVariantSelectError: true,
            });
            return SubmitReviewStatus.FAIL_MISSING_VARIANT_SELECTION;
        }

        // Validate the form data
        const formData = {
            ...form.valuesText,
            ...form.valuesMultiSelect,
        };
        const errors: IWriteReviewFormState["errors"] = {};
        let errorCount = 0;
        objectKeys(template).forEach((tmplKey) => {
            const tmplField = template[tmplKey];
            errors[tmplKey] = [];

            const textValue: string | string[] = formData[tmplKey];

            // Check if the text is empty of is made of whitespace characters only
            // PowerReviews API expects RequiredField to have at least one non-whitespace character
            const isBlank = !textValue || !textValue.toString().trim();

            if (tmplField.is_required && isBlank) {
                errors[tmplKey].push(
                    getCustomRequiredMessage(tmplKey, isMultiReview),
                );
                errorCount++;
            }

            // Validate the email address
            if (tmplField.type === "email" && formData[tmplKey]) {
                const email = formData[tmplKey] as string;
                if (runValidator("email", email)) {
                    errors[tmplKey].push(
                        gettext("Please enter a valid email address"),
                    );
                    errorCount++;
                }
            }
        });
        // clear errors for product if all fields left blank
        if (isMultiReview) {
            clearProductErrors(errors);
        }

        // If anything failed, bail out.
        if (errorCount > 0) {
            this.dispatchers.setReviewFormErrors({
                formUUID: formUUID,
                errors: errors,
                showErrors: true,
                showProductSelectError: false,
                showVariantSelectError: false,
            });
            return SubmitReviewStatus.FAIL_INVALID_FIELDS;
        }

        const reviewData: typeof formData = {
            ...formData,
            variant: form.selectedVariant,
            ...getReviewDisclosureParams(),
            ...getReviewSiteSource(),
        };

        // Update USER_HASH in the datalayer
        if ("email_collection" in reviewData) {
            updateGTMUserHash(reviewData.email_collection.toString());
        }

        // Submit Review
        try {
            await submitReview(
                form.selectedProductID,
                form.started,
                reviewData,
            );
        } catch (e2) {
            // Log errors to console
            console.log(e2);
            return SubmitReviewStatus.FAIL_API;
        }

        // Success
        this.dispatchers.setWriteReviewFormMode(
            formUUID,
            WriteReviewFormMode.THANK_YOU_OPEN,
        );
        return SubmitReviewStatus.SUCCESS;
    };
}

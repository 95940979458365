import React from "react";
import classNames from "classnames";
import SVG from "react-inlinesvg";
import { UGCListSubBlock } from "../models/ugc";
import FlickityFull from "flickity-fullscreen";
import UGCRecommendations from "./UGCRecommendations";
import { Image } from "./Image";
import { SocialIcon } from "./SocialIcon";
import { trapFocus, focusElement } from "../utils/keyboardFocus";
import { loadWistiaPlayer } from "../utils/wistia";
import iconExternalLink from "../../img/icons/external-link.svg";
import iconXClose from "../../img/icons/x-close.svg";

interface IProps {
    tiles: UGCListSubBlock[];
    initialSlide: number;
    onCloseGallery: () => void;
}

interface IState {
    isMobile: boolean;
    isMediumScreen: boolean;
    selectedSlide: number;
}

export class UGCGallery extends React.PureComponent<IProps, IState> {
    private galleryCarousel: HTMLDivElement | null = null;
    private galleryFlkty: FlickityFull | null = null;
    public MOBILE_WIDTH_THRESHOLD = 501;
    public MEDIUM_SCREEN_WIDTH_THRESHOLD = 769;

    public state: IState = {
        isMobile: false,
        isMediumScreen: false,
        selectedSlide: 1,
    };

    private readonly onCloseGallery = () => {
        if (this.galleryFlkty) {
            this.galleryFlkty.exitFullscreen();
            this.galleryFlkty.destroy();
        }

        this.props.onCloseGallery();
    };

    private readonly checkMobileState = () => {
        const mobile: boolean = this.isMobileWidth() ? true : false;
        const mediumScreen: boolean = this.isMediumScreenWidth() ? true : false;

        this.setState(
            {
                isMobile: mobile,
                isMediumScreen: mediumScreen,
            },
            () => {
                this.initializeGallery();
            },
        );
    };

    componentDidMount() {
        this.checkMobileState();
        window.addEventListener("resize", this.checkMobileState);
        window.addEventListener("keyup", this.handleGalleryEscapeKeyUp);

        for (let i = 0; i < this.props.tiles.length; i++) {
            if (
                this.props.tiles[i].post?.post_type === "video" &&
                this.props.tiles[i].post?.video_link
            ) {
                loadWistiaPlayer();
                break;
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener("keyup", this.handleGalleryEscapeKeyUp);
        if (this.galleryFlkty) {
            this.galleryFlkty.destroy();
        }
    }

    private isMobileWidth() {
        return window.innerWidth < this.MOBILE_WIDTH_THRESHOLD;
    }

    private isMediumScreenWidth() {
        return window.innerWidth < this.MEDIUM_SCREEN_WIDTH_THRESHOLD;
    }

    /**
     * Keyboard escape override
     * if element in modal is focused
     */
    private readonly handleGalleryEscapeKeyUp = (e: KeyboardEvent) => {
        const KEY_ESCAPE = "Escape";
        if (e.key === KEY_ESCAPE) {
            this.onCloseGallery();
        }
    };

    private initializeGallery() {
        if (this.galleryFlkty) {
            this.galleryFlkty.destroy();
        }

        const galleryArrow = this.state.isMobile
            ? {
                  x0: 10,
                  x1: 60,
                  y1: 50,
                  x2: 60,
                  y2: 40,
                  x3: 20,
              }
            : {
                  x0: 35,
                  x1: 110,
                  y1: 120,
                  x2: 115,
                  y2: 120,
                  x3: 40,
              };

        if (this.galleryCarousel) {
            this.galleryFlkty = new FlickityFull(this.galleryCarousel, {
                accessibility: true,
                cellAlign: "center",
                pageDots: false,
                fullscreen: true,
                cellSelector: ".ugc-carousel-gallery-tile",
                arrowShape: galleryArrow,
            });

            this.galleryFlkty.select(this.props.initialSlide);
            this.galleryFlkty.on("select", (index) => {
                this.setState({
                    selectedSlide: Number(index) + 1,
                });
            });

            this.galleryFlkty.on("settle", () => {
                const activeModal = document.querySelector<HTMLElement>(
                    ".ugc-carousel-gallery__content",
                );
                if (activeModal) {
                    trapFocus(activeModal);
                }

                const activeCloseButton =
                    document.querySelector<HTMLButtonElement>(
                        ".ugc-carousel-gallery-tile.is-selected .ugc-carousel-gallery-tile__close",
                    );
                if (activeCloseButton) {
                    // focus() is fired but it is not applied properly for some reason
                    // This hack makes focus() working properly
                    setTimeout(() => {
                        focusElement(activeCloseButton);
                    }, 0);
                }
                if (this.galleryFlkty) {
                    this.galleryFlkty.viewFullscreen();
                }
            });

            this.galleryFlkty.on("dragStart.flickity", () => {
                document.ontouchmove = (event) => {
                    event.preventDefault();
                };
            });

            this.galleryFlkty.on("dragEnd.flickity", () => {
                document.ontouchmove = () => {
                    return true;
                };
            });

            this.galleryFlkty.on("fullscreenChange", (isFullscreen) => {
                if (!isFullscreen && this.galleryFlkty) {
                    this.galleryFlkty.destroy();
                    this.props.onCloseGallery();
                }

                const overlay = document.querySelector<HTMLElement>(
                    ".flickity-enabled.is-fullscreen",
                );
                if (overlay) {
                    overlay.setAttribute("aria-modal", "true");
                    overlay.setAttribute(
                        "aria-label",
                        gettext("TempurPedic Social Media Posts"),
                    );
                    overlay.setAttribute("role", "dialog");
                }

                const nextBtn = document.querySelector<HTMLElement>(
                    ".flickity-button.flickity-prev-next-button.next",
                );
                const prevBtn = document.querySelector<HTMLElement>(
                    ".flickity-button.flickity-prev-next-button.previous",
                );

                if (nextBtn) {
                    nextBtn.setAttribute(
                        "aria-label",
                        gettext(
                            "View Next. Activating this element will cause content on the page to be updated.",
                        ),
                    );
                    nextBtn.classList.add("al-ugc-gallery-flickity--next");
                }

                if (prevBtn) {
                    prevBtn.setAttribute(
                        "aria-label",
                        gettext(
                            "View Previous. Activating this element will cause content on the page to be updated.",
                        ),
                    );
                    prevBtn.classList.add("al-ugc-gallery-flickity--previous");
                }
            });

            this.galleryFlkty.on("pointerDown", (event) => {
                if (this.state.isMobile) {
                    return;
                }

                if (event.target) {
                    const target = event.target as HTMLElement;
                    if (
                        !target.classList.contains("ugc-carousel-gallery-tile")
                    ) {
                        return;
                    }
                    this.onCloseGallery();
                }
            });
        }
    }

    private gotoGalleryLink(
        event: React.MouseEvent<HTMLElement>,
        link?: string,
    ) {
        if (event) {
            event.preventDefault();
        }

        if (link) {
            window.open(link, "_blank");
        }
    }

    private isTilePhotoOrVideo(tile: UGCListSubBlock) {
        return (
            tile.post &&
            (tile.post.post_type === "image" ||
                tile.post.post_type === "video") &&
            tile.post.photo
        );
    }

    private isTextOnly(tile: UGCListSubBlock) {
        return tile.post?.post_type === "text-only";
    }

    private buildGalleryTiles() {
        return this.props.tiles.map((tile, key) => {
            if (!tile.post) {
                return null;
            }
            const tileContentClasses = classNames({
                "ugc-carousel-gallery-tile__content": true,
                "ugc-carousel-gallery-tile__content--text-only":
                    this.isTextOnly(tile),
            });
            const tileDetailsClasses = classNames({
                "ugc-carousel-gallery-tile__details": true,
                "ugc-carousel-gallery-tile__details--text-only":
                    this.isTextOnly(tile),
            });
            const tileUsernameClasses = classNames({
                "ugc-carousel-gallery-tile__username": true,
                "ugc-carousel-gallery-tile__username--highlight":
                    tile.post.social_link,
            });
            const tileIconContainerClasses = classNames({
                "ugc-carousel-gallery-tile__icon-container": true,
                "ugc-carousel-gallery-tile__icon-container--empty":
                    tile.post.social_media === "none",
            });

            const imageVideo =
                tile.post.post_type === "video" && tile.post.video_link ? (
                    <span
                        className="ugc-carousel-gallery-tile__video al-ugc-carousel-gallery-tile--video"
                        dangerouslySetInnerHTML={{
                            __html: tile.post.video_link,
                        }}
                    ></span>
                ) : (
                    <Image
                        src={tile.post.photo}
                        className="ugc-carousel-gallery-tile__image"
                        alt={tile.post.photo_title}
                    />
                );
            const tilePhoto = this.isTilePhotoOrVideo(tile) ? (
                <div className="ugc-carousel-gallery-tile__image-container">
                    {imageVideo}
                </div>
            ) : null;
            const recommendations =
                tile.post.recommendations &&
                tile.post.recommendations.length > 0 ? (
                    <UGCRecommendations
                        recommendations={tile.post.recommendations}
                    />
                ) : null;

            const tileUsername = tile.post.social_link ? (
                <a
                    id={`post-${key}`}
                    className={tileUsernameClasses}
                    href={tile.post.social_link}
                    target="_blank"
                    rel="noreferrer"
                >
                    {tile.post.username}
                </a>
            ) : (
                <span className={tileUsernameClasses}>
                    {tile.post.username}
                </span>
            );

            const onTileShareClick = (e: React.MouseEvent<HTMLElement>) => {
                this.gotoGalleryLink(e, tile.post?.social_link);
            };
            const tileShare = tile.post.social_link ? (
                <button
                    aria-label={gettext("Open in Instagram")}
                    title={gettext("Open in Instagram")}
                    className="ugc-carousel-gallery-tile__link ugc-carousel-gallery-tile__share al-ugc-gallery__share-link"
                    onClick={onTileShareClick}
                >
                    <SVG
                        aria-hidden="true"
                        title={gettext("Open in Instagram")}
                        className="ugc-carousel-gallery-tile__close-icon al-ugc-gallery__close"
                        src={iconExternalLink}
                    />
                </button>
            ) : null;

            return (
                <li className="ugc-carousel-gallery-tile" key={key}>
                    <p className="ada-screenreader-only">
                        {gettext("To navigate, use the left and right arrows.")}
                    </p>
                    <section className={tileContentClasses}>
                        <div className={tileDetailsClasses}>
                            <div className="ugc-carousel-gallery-tile__attribution">
                                {tile.post.avatar && (
                                    <Image
                                        className="ugc-carousel-gallery-tile__avatar"
                                        src={tile.post.avatar}
                                        alt={tile.post.avatar_title}
                                    />
                                )}
                                <div className="ugc-carousel-gallery-tile__links">
                                    <button
                                        aria-label={gettext("Close")}
                                        title={gettext("Close Modal")}
                                        className="ugc-carousel-gallery-tile__link ugc-carousel-gallery-tile__close al-ugc-gallery__close"
                                        onClick={this.onCloseGallery}
                                    >
                                        <SVG
                                            aria-hidden="true"
                                            title={gettext("Close")}
                                            className="ugc-carousel-gallery-tile__close-icon"
                                            src={iconXClose}
                                        />
                                    </button>
                                    {tileShare}
                                </div>
                                <h3 className="ugc-carousel-gallery-tile__title">
                                    <strong>{tile.post.title}</strong>
                                </h3>
                                <span className={tileIconContainerClasses}>
                                    <SocialIcon
                                        type={tile.post.social_media}
                                        className={
                                            "ugc-carousel-gallery-tile__icon"
                                        }
                                    />
                                </span>
                                {tileUsername}
                            </div>
                            <div className="ugc-carousel-gallery-tile__copy">
                                {!this.state.isMobile && tile.post.copy}
                                {!this.state.isMobile &&
                                    this.state.isMediumScreen &&
                                    recommendations}
                            </div>
                            {!this.state.isMobile &&
                                !this.state.isMediumScreen &&
                                recommendations}
                        </div>
                        <div className="ugc-carousel-gallery-tile__photo-and-copy">
                            {!this.state.isMobile && tilePhoto}
                            {this.state.isMobile && (
                                <div>
                                    {tilePhoto}
                                    <div className="ugc-carousel-gallery-tile__copy-container">
                                        <div className="ugc-carousel-gallery-tile__copy">
                                            {tile.post.copy}
                                            {recommendations}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </section>
                </li>
            );
        });
    }

    render() {
        return (
            <div
                className="ugc-carousel-gallery__content"
                ref={(ref) => {
                    this.galleryCarousel = ref;
                }}
            >
                {this.state.isMobile && (
                    <span className="ugc-carousel-gallery__counter">
                        {this.state.selectedSlide} of {this.props.tiles.length}
                    </span>
                )}
                <ul>{this.buildGalleryTiles()}</ul>
            </div>
        );
    }
}

export default UGCGallery;

import React from "react";
import { IMenuCategoryLink, IDropdownMenuItem } from "../menu.interface";
import { PromoAd } from "tsi-common-react/src/blocks/components/PromoAd";

interface IProps {
    menuItems: IMenuCategoryLink[];
}

const NavAdvert = (props: IProps) => {
    let promoAds: JSX.Element | null = null;
    props.menuItems.forEach((menuItem: IMenuCategoryLink) => {
        if (menuItem.type !== "nav_category") {
            return;
        }
        if (menuItem.value.sub_nav.length > 0) {
            menuItem.value.sub_nav.forEach((val: IDropdownMenuItem) => {
                switch (val.type) {
                    case "nav_advert":
                        promoAds = <PromoAd promoArrayEl={val} />;
                        break;
                    default:
                        break;
                }
            });
        }
    });

    return promoAds;
};

export default NavAdvert;

import cookies from "js-cookie";
import { IProductID, IProductSKU } from "../models/nominals";
import { IProduct, IBasket, IBasketLine } from "../models/catalogue.interfaces";
import { IRetailStoreWithDistance } from "../models/location.interfaces";
import { IUser } from "../models/user.interfaces";
import { load as loadUser } from "../api/user";
import { getPageSetting } from "./settings";
import { getHighestDiscountName } from "./money";
import {
    AddToCartType,
    RetailStoreEventType,
    RetailStoreEventCTAType,
} from "../constants";

if (!window.dataLayer) {
    window.dataLayer = [];
}

// Declare user hash name in the dataLayer
const USER_HASH = "USER_HASH";
const CURRENCY = "USD";

const getBreadCrumb = () => {
    const location = "Home" + window.location.pathname;
    let breadcrumb = location.split("/").filter(Boolean).join(" > ");

    const onScreenBreadcrumb = document.querySelector<HTMLElement>(
        "[class^='breadcrumb']",
    );
    if (onScreenBreadcrumb) {
        breadcrumb = onScreenBreadcrumb.innerText.replace(/\n/g, "> ");
        if (!onScreenBreadcrumb.innerText.includes("home")) {
            return "Home > " + breadcrumb.trim();
        }
    }
    return breadcrumb.trim();
};
const getPageTitle = () => {
    const pageTitle: string | null = document.title.split(/\s([-–])|(\|)/)[0];
    const commonUrls = document.querySelector<HTMLElement>(
        `common-page-urls a[href="${window.location.pathname}"]`,
    );
    if (commonUrls) {
        return commonUrls.getAttribute("title");
    }
    return pageTitle.trim();
};

/**
 * Named counter with value stored in a cookie
 */
export class PersistentCounter {
    private readonly name: string;
    private readonly expiryMinutes: number;

    public constructor(name: string, expiryMinutes = 20) {
        this.name = name;
        this.expiryMinutes = expiryMinutes;
    }

    public get value(): number {
        const val = cookies.get(this.name) || "0";
        return parseInt(val, 10) || 0;
    }

    public set value(newValue: number) {
        const date = new Date();
        const expiryTimestamp = date.getTime() + this.expiryMinutes * 60 * 1000;
        date.setTime(expiryTimestamp);
        // Update cookie value
        cookies.set(this.name, `${newValue}`, {
            expires: date,
        });
        // Update datalayer value
        if (window.dataLayer) {
            window.dataLayer.push({
                [`counter_${this.name}`]: newValue,
            });
        }
    }

    public increment(amount = 1) {
        this.value += amount;
    }
}

/**
 * Track a page view
 */
export const track = (virtualURL: string, title?: string) => {
    title = title === undefined ? document.title : title;
    if (window.dataLayer !== undefined) {
        window.dataLayer.push({
            event: "virtualPageview",
            virtualPageURL: virtualURL,
            virtualPageTitle: title,
        });
    }
};

/**
 * Record a page view for a virtual (JS-rendered) subpage of the current (real) page.
 */
export const trackVirtualSubPage = (urlSegment: string) => {
    let page = window.location.href.replace(window.location.origin, "");
    page = page.replace(window.location.hash, "");
    page += "/" + urlSegment + "/";
    page = page.replace(/\/+/g, "/");
    page = page + window.location.hash;
    track(page);
};

/**
 * Send an event to our analytics platform(s)
 */
export const trackEvent = (
    eventCategory: string,
    eventAction: string,
    eventLabel?: string,
    eventValue?:
        | number
        | {
              nonInteraction?: boolean;
              [key: string]: unknown;
          },
) => {
    // Send event to Google Analytics
    if (window.ga !== undefined) {
        window.ga(
            "send",
            "event",
            eventCategory,
            eventAction,
            eventLabel,
            eventValue,
        );
    }
};

/**
 * The following functions are used in combination to encode the User's email into a SHA-256
 * hash and send the hash to the GTM dataLayer for marketing retargeting purposes.
 */
export const sendGTMUserHash = (hash?: string) => {
    // Emit a GTM event with the provided hash or try to pull the existing hash from localStorage
    let local_hash = "";
    try {
        const ls_hash = window.localStorage.getItem(USER_HASH);
        local_hash = ls_hash ? ls_hash : "";
    } catch (e) {
        local_hash = "";
    }
    const hash_to_send = hash ? hash : local_hash;
    if (hash_to_send) {
        window.dataLayer.push({
            USER_HASH: hash_to_send,
        });
    }
};

export const updateGTMUserHash = async (email: string) => {
    const { sha256 } = (await import("js-sha256")).default;
    // Generate the user hash from the email string and update
    // the dataLayer. (Called in user login forms)
    const cleanedEmail = email.toLowerCase().trim();
    // Convert cleaned_email into a Uint8Array and then generate a SHA-256 hash
    const userHash = sha256(cleanedEmail);
    try {
        window.localStorage.setItem(USER_HASH, userHash);
    } catch (e) {
        // eslint-disable-next-line
    }
    // Update window.dataLayer
    sendGTMUserHash(userHash);
};

export const kuidListeners = () => {
    // Add a form submit listener to email forms to trigger user hash
    // creation. KUID forms are indicated by adding the 'js-kuid-form' class
    Array.prototype.forEach.call(
        document.querySelectorAll(".js-kuid-form"),
        (form: HTMLFormElement) => {
            form.addEventListener("submit", (event) => {
                const new_form = event.currentTarget as HTMLElement;
                if (new_form) {
                    const email_input =
                        new_form.querySelector<HTMLInputElement>(
                            'input[name="email"]',
                        );
                    if (email_input) {
                        updateGTMUserHash(email_input.value);
                    }
                }
            });
        },
    );
};

/**
 * GTM remarketing page view event analytics
 */
export const trackHomePageView = () => {
    if (window.dataLayer === undefined) {
        return;
    }
    window.dataLayer.push({
        event: "HomePageView",
        ecomm_pagetype: "home",
    });
};

export const trackSearchPageView = (prodIds: IProductSKU[]) => {
    if (window.dataLayer === undefined) {
        return;
    }
    window.dataLayer.push({
        event: "SearchPageView",
        ecomm_prodid: prodIds.slice(0, 3),
        ecomm_pagetype: "search",
    });
};

export const trackCategoryPageView = (prodIds: IProductSKU[]) => {
    if (window.dataLayer === undefined) {
        return;
    }
    window.dataLayer.push({
        event: "CategoryPageView",
        ecomm_prodid: prodIds.slice(0, 3),
        ecomm_pagetype: "category",
    });
};

export const trackPageView = async () => {
    let pageType = document.body.dataset.trackPageType;
    if (!pageType) {
        pageType = document.body.dataset.trackEvent
            ? document.body.dataset.trackEvent?.replace("View", "")
            : "Landing Page";
    }
    const user: IUser = await loadUser();
    if (window.dataLayer !== undefined) {
        window.dataLayer.push({
            event: "page_view",
            userLoginStatus: user.has_password,
            pageType: pageType,
            userId: user.username,
            userType: user.has_password ? "Registered" : "Guest",
            userIsCSR: user.is_csr,
            breadcrumb: getBreadCrumb(),
            pageTitle: getPageTitle(),
        });
    }
};

// for PDP, cart, checkout, conversion pages
export const trackProductValuePageView = (
    prodIds: IProductSKU[],
    totalValue: string,
    pageType: string,
    event: string,
) => {
    if (window.dataLayer === undefined) {
        return;
    }
    window.dataLayer.push({
        event: event,
        ecomm_prodid: prodIds.slice(0, 3),
        ecomm_pagetype: pageType,
        ecomm_totalvalue: Number(totalValue),
    });
};

/**
 * Financing Events
 */
export const trackVariantSelectedEvent = (variantID: IProductID) => {
    if (window.dataLayer === undefined) {
        return;
    }
    // Push content to Google tag dataLayer
    window.dataLayer.push({
        event: "VARIANT_SELECTED",
        variant_id: variantID,
    });
};

export const trackConfiguratorLoadedEvent = () => {
    if (window.dataLayer === undefined) {
        return;
    }
    // update dataLayer to confirm the Configurator has finished loading
    window.dataLayer.push({
        configuratorLoaded: true,
    });
};

export const trackBasketEvent = (event: string, basket: IBasket) => {
    if (window.dataLayer === undefined) {
        return;
    }
    window.dataLayer.push({
        event: event,
        currency: basket?.currency || CURRENCY,
        value: parseFloat(basket.total_excl_tax),
        items: basket.lines.map((line: IBasketLine) => ({
            item_id: line.product.upc,
            item_name: line.product.title,
            item_brand: getPageSetting("app-display-name"),
            item_variant:
                line.product.skus.length > 0 ? line.product.skus[0] : undefined,
            item_size: line.product.attributes.option_size?.value,
            item_feel: line.product.attributes.option_feel?.value,
            item_category: line.product.product_class_slug,
            item_category2: line.product.subtitle,
            item_coupon: getHighestDiscountName(line.discounts) || undefined,
            quantity: line.quantity,
            price:
                parseFloat(line.line_price_excl_tax_incl_discounts) /
                line.quantity,
            discount: parseFloat(line.discount_value) / line.quantity,
        })),
    });
};

export const trackViewItemEvent = (product: IProduct) => {
    if (window.dataLayer === undefined || !product) {
        return;
    }
    const price = product.price?.cosmetic_excl_tax
        ? parseFloat(product.price.cosmetic_excl_tax)
        : 0.0;
    window.dataLayer.push({
        event: "view_item",
        currency: product.price?.currency || CURRENCY,
        value: price,
        items: [
            {
                item_id: product.upc,
                item_name: product.title,
                item_brand: getPageSetting("app-display-name"),
                item_variant:
                    product.skus.length > 0 ? product.skus[0] : undefined,
                item_size: product.attributes.option_size?.value,
                item_feel: product.attributes.option_feel?.value,
                item_category: product.product_class_slug,
                item_category2: product.subtitle,
                price: price,
            },
        ],
    });
};

export const trackAddToBasketEvent = (
    product: IProduct | null,
    quantity?: number,
    addToCartType: AddToCartType = AddToCartType.ADDED,
) => {
    if (window.dataLayer === undefined || !product) {
        return;
    }
    const qty = quantity || 1;
    const price = product.price?.cosmetic_excl_tax
        ? parseFloat(product.price.cosmetic_excl_tax)
        : 0.0;
    window.dataLayer.push({
        event: "add_to_cart",
        currency: product.price?.currency || CURRENCY,
        value: price * qty,
        add_to_cart_type: addToCartType,
        items: [
            {
                item_id: product.upc,
                item_name: product.title,
                item_brand: getPageSetting("app-display-name"),
                item_variant:
                    product.skus.length > 0 ? product.skus[0] : undefined,
                item_size: product.attributes.option_size?.value,
                item_feel: product.attributes.option_feel?.value,
                item_category: product.product_class_slug,
                item_category2: product.subtitle,
                price: price,
                quantity: qty,
            },
        ],
    });
};

export const trackFormEvent = (
    event: "form_start" | "form_submit",
    name: `financing_${string}` | `checkout_${string}` | undefined,
    formElem: HTMLFormElement,
    basket: IBasket | null,
) => {
    if (window.dataLayer === undefined) {
        return;
    }
    window.dataLayer.push({
        event: event,
        form_id: formElem.id || undefined,
        form_name: name || formElem.name,
        form_destination: formElem.action,
        currency: basket?.currency,
        value: basket ? parseFloat(basket.total_excl_tax) : 0.0,
        items: basket?.lines.map((line) => ({
            item_id: line.product.upc,
            item_name: line.product.title,
            item_brand: getPageSetting("app-display-name"),
            item_variant:
                line.product.skus.length > 0 ? line.product.skus[0] : undefined,
            item_size: line.product.attributes.option_size?.value,
            item_feel: line.product.attributes.option_feel?.value,
            item_category: line.product.product_class_slug,
            item_category2: line.product.subtitle,
            item_coupon: getHighestDiscountName(line.discounts) || undefined,
            quantity: line.quantity,
            price:
                parseFloat(line.line_price_excl_tax_incl_discounts) /
                line.quantity,
            discount: parseFloat(line.discount_value) / line.quantity,
        })),
    });
};

export const trackRetailStoreEvent = (
    event: RetailStoreEventType,
    store: IRetailStoreWithDistance | null,
    ctaType: RetailStoreEventCTAType | null = null,
    linkText: string | null = null,
    linkUrl: string | null = null,
) => {
    if (window.dataLayer === undefined || !store) {
        return;
    }
    window.dataLayer.push({
        event: event,
        store_name: store.name,
        store_location: `${store.city}, ${store.state}`,
        cta_type: ctaType || undefined,
        link_text: linkText || undefined,
        link_url: linkUrl || undefined,
    });
};

export const trackSleepExpertEvent = (chatType: "call" | "chat") => {
    if (window.dataLayer === undefined) {
        return;
    }
    window.dataLayer.push({
        event: "sleep_expert_cta_event",
        start_chat: chatType,
    });
};

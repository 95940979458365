import { IPayment } from "./reducers.interfaces";

// Checkout Form Steps
export enum Step {
    LOGIN = 1,
    SHIPPING_ADDRESS = 2,
    SHIPPING_METHOD = 3,
    BILLING_ADDRESS = 4,
    PAYMENT_METHODS = 5,
    PLACING_ORDER = 6,
}

export type StepIndexCheckoutFormNameMapType = {
    [V in (typeof Step)[keyof typeof Step]]: `checkout_${string}`;
};

export const StepIndexCheckoutFormNameMap: StepIndexCheckoutFormNameMapType = {
    [Step.LOGIN]: "checkout_login",
    [Step.SHIPPING_ADDRESS]: "checkout_shipping",
    [Step.SHIPPING_METHOD]: "checkout_delivery_options",
    [Step.BILLING_ADDRESS]: "checkout_billing",
    [Step.PAYMENT_METHODS]: "checkout_payment",
    [Step.PLACING_ORDER]: "checkout_placing_order",
};

export enum ConfigureGiftStatus {
    LOADING = "LOADING",
    LOADED = "LOADED",
    ADDING_TO_BASKET = "ADDING_TO_BASKET",
    SELECTION_ERROR = "SELECTION_ERROR",
    COMPLETE = "COMPLETE",
}

export enum PredictedDeliveryDateStatus {
    INITIAL = "initial",
    LOADING = "loading",
    LOADED = "loaded",
    ERROR = "error",
}

export enum PredictedDeliveryDateDisplayType {
    DISABLED = "disabled",
    SHOW_CONSERVATIVE = "show-conservative",
    SHOW_AGGRESSIVE = "show-aggressive",
}

export enum PredictedDeliveryDateStyle {
    ONE_LINE = "one-line",
    TWO_LINE = "two-line",
}

export enum PreferredDeliveryDateDisplayType {
    DISABLED = "disabled",
    SHOW_CONSERVATIVE = "show-conservative",
    SHOW_AGGRESSIVE = "show-aggressive",
}

// Redux Actions
export enum Action {
    // API data load events
    UPDATE_BASKET = "Checkout/UPDATE_BASKET",
    UPDATE_ORDER = "Checkout/UPDATE_ORDER",
    UPDATE_BUNDLE = "Checkout/UPDATE_BUNDLE",
    UPDATE_COUNTRIES = "Checkout/UPDATE_COUNTRIES",
    UPDATE_SHIPPING_STATES = "Checkout/UPDATE_SHIPPING_STATES",
    UPDATE_BILLING_STATES = "Checkout/UPDATE_BILLING_STATES",
    UPDATE_SHIPPING_METHODS = "Checkout/UPDATE_SHIPPING_METHODS",
    UPDATE_PAYMENT_METHODS = "Checkout/UPDATE_PAYMENT_METHODS",
    UPDATE_PAYMENT_STATES = "Checkout/UPDATE_PAYMENT_STATES",
    RESET_PAYMENT_STATES = "Checkout/RESET_PAYMENT_STATES",
    UPDATE_PAYMENT_ERROR = "Checkout/UPDATE_PAYMENT_ERROR",
    UPDATE_FINANCING_PLANS = "Checkout/UPDATE_FINANCING_PLANS",
    UPDATE_WISHLIST = "Checkout/UPDATE_WISHLIST",
    UPDATE_ASSISTED_USER = "Checkout/UPDATE_ASSISTED_USER",
    UPDATE_USER_ADDRESSES = "Checkout/UPDATE_USER_ADDRESSES",
    PREDICTED_DELIVERY_DATE_LOADING = "Checkout/PREDICTED_DELIVERY_DATE_LOADING",
    PREDICTED_DELIVERY_DATE_LOADED = "Checkout/PREDICTED_DELIVERY_DATE_LOADED",
    BEGIN_COMPLETE_DEFERRED_PAYMENT = "Checkout/BEGIN_COMPLETE_DEFERRED_PAYMENT",
    SET_PREFERRED_DELIVERY_DATE = "Checkout/SET_PREFERRED_DELIVERY_DATE",

    // User input actions
    SET_FIELDS = "Checkout/SET_FIELDS",
    USE_SHIPPING_ADDR_AS_BILLING_ADDR = "Checkout/USE_SHIPPING_ADDR_AS_BILLING_ADDR",
    RESET_PAYMENT_METHODS = "Checkout/RESET_PAYMENT_METHODS",
    ADD_PAYMENT_METHOD = "Checkout/ADD_PAYMENT_METHOD",
    REMOVE_PAYMENT_METHOD = "Checkout/REMOVE_PAYMENT_METHOD",
    SET_PAYMENT_METHOD_FIELDS = "Checkout/SET_PAYMENT_METHOD_FIELDS",

    // Derived data events
    SET_DERIVED_FIELDS = "Checkout/SET_DERIVED_FIELDS",
    ACKNOWLEDGE_MERGED_BASKET = "Checkout/ACKNOWLEDGE_MERGED_BASKET",

    // Gift Actions
    SET_CONFIGURE_GIFT_STATUS = "Checkout/SET_CONFIGURE_GIFT_STATUS",
    SET_CONFIGURABLE_BUNDLES = "Checkout/SET_CONFIGURABLE_BUNDLES",
    SET_GIFT_ROOT_PRODUCT = "Checkout/SET_GIFT_ROOT_PRODUCT",
    SET_GIFT_OPTION_VALUE = "Checkout/SET_GIFT_OPTION_VALUE",
    SET_ADD_TO_BASKET_ERROR_MODAL_STATE = "Checkout/SET_ADD_TO_BASKET_ERROR_MODAL_STATE",
}

// Cybersource Credit Card Types
export const CARD_TYPE_VISA = "001";
export const CARD_TYPE_MASTERCARD = "002";
export const CARD_TYPE_AMEX = "003";
export const CARD_TYPE_DISCOVER = "004";

export const getCardNameFromType = (cardType: string) => {
    switch (cardType) {
        case CARD_TYPE_AMEX:
            return "American Express";
        case CARD_TYPE_DISCOVER:
            return "Discover Card";
        case CARD_TYPE_MASTERCARD:
            return "MasterCard";
        case CARD_TYPE_VISA:
            return "Visa";
        default:
            return null;
    }
};

// Misc
export const PRIMARY_PAYMENT_METHOD_KEY = "1-primary";
export const SECONDARY_PAYMENT_METHOD_KEY = "2-secondary";
export const SECONDARY_PAYMENT_METHOD_TYPES_WHITELIST: IPayment["method_type"][] =
    ["cybersource"];
export const DEFAULT_PHONE_NUMBER = "+1";
export const DEFAULT_COUNTRY = "/api/countries/US/";

export const CURRENCY = "USD";

export const LIST_FAVORITES = "favorites";
export const LIST_PREVIOUSLY_IN_BASKET = "previously-in-basket";

export const RECYCLING_FEE_NAME = "STATE EXCISE TAX-MATTRESS";

export const SHIPPING_METHOD_NAMES: { [c: string]: string } = {
    "TMS": "White Glove Delivery",
    "UPS GROUND": "UPS Ground Shipping",
    "UPS 2 DAY": "UPS 2nd Day Air",
    "UPS NEXT DAY": "UPS Next Day Air",
};

// BasketLine Variants
export const enum BasketLineVariant {
    MINIMAL,
    MINIMAL_ENHANCED,
    FULL,
}

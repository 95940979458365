import React, { Fragment, useState } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import {
    IBasket,
    IBasketLine,
    IVoucherDiscount,
} from "../../../models/catalogue.interfaces";
import { FinancingPlan } from "../../../models/financing";
import { Loaders } from "../loaders";
import { Actions } from "../actions";
import {
    BasketLineVariant,
    PredictedDeliveryDateDisplayType,
    PreferredDeliveryDateDisplayType,
} from "../constants";
import { BasketLineMinimal } from "./BasketLineMinimal";
import { TGetProductUpsell, BasketLineFull } from "./BasketLineFull";
import { BasketLineMinimalEnhanced } from "./BasketLineMinimalEnhanced";
import { trackAddToBasketEvent } from "../../../utils/analytics";
import styles from "./BasketLines.module.scss";
import { AddToCartType } from "../../../constants";
import { VoucherLineFull } from "./VoucherLineFull";
import { VoucherLineMinimal } from "./VoucherLineMinimal";
import { VoucherLineMinimalEnhanced } from "./VoucherLineMinimalEnhanced";

interface IProps {
    basket: IBasket | null;
    financingPlans: FinancingPlan[];
    variant: BasketLineVariant;
    shippingMethodCode?: string;
    getProductUpsell: TGetProductUpsell;
    showValueProps: boolean;
    predictedDeliveryDates: PredictedDeliveryDateDisplayType;
    preferredDeliveryDates: PreferredDeliveryDateDisplayType;
    loadConcreteBundles: Loaders["loadConcreteBundles"];
    addBasketLine: Actions["addBasketLine"];
    updateBasketLineQuantity: Actions["updateBasketLineQuantity"];
    removeBasketLine: Actions["removeBasketLine"];
    isMobile: boolean;
    deliveryIsFree?: boolean;
    removeVoucherCode: (code: string) => void;
}

interface IErrorModelData {
    errorModalOpen: boolean;
    errorModalReason: string;
}

export const BasketLines = (props: IProps) => {
    const [, setIsOperationPending] = useState(false);
    const [, setErrorModalData] = useState<IErrorModelData>({
        errorModalOpen: false,
        errorModalReason: "",
    });
    function showLoadingIndicator() {
        setIsOperationPending(true);
    }

    function hideLoadingIndicator() {
        setIsOperationPending(false);
    }

    function openErrorModal(reason: string) {
        setErrorModalData({
            errorModalOpen: true,
            errorModalReason: reason,
        });
    }

    const onRemoveLine = async (line: IBasketLine) => {
        showLoadingIndicator();
        await props.removeBasketLine(line.url);
        hideLoadingIndicator();
    };

    const onUpdateLineQuantity = async (
        event: React.FormEvent<HTMLSelectElement>,
        line: IBasketLine,
    ) => {
        if (!line.quantity) {
            return;
        }

        const lineURL = line.url;
        const newQty = parseInt(event.currentTarget.value, 10);

        showLoadingIndicator();

        let loading: Promise<IBasket>;
        if (newQty === 0) {
            loading = props.removeBasketLine(lineURL);
        } else {
            loading = props.updateBasketLineQuantity(lineURL, newQty);
            const difference = newQty - line.quantity;
            // When the user increases the quantity of an item already in their basket,
            // we should fire add_to_cart event with a type of `increase quantity`
            if (difference > 0) {
                trackAddToBasketEvent(
                    line.product,
                    Math.abs(difference),
                    AddToCartType.INCREASE_QUANTITY,
                );
            }
        }

        try {
            await loading;
            // Hide the loading indicator
            hideLoadingIndicator();
        } catch (err) {
            // Hide the loading indicator
            hideLoadingIndicator();
            // Try to display the reason for the error
            const reason =
                err.response.body && err.response.body.reason
                    ? `: ${err.response.body.reason}`
                    : "";
            openErrorModal(
                interpolate(
                    gettext(
                        "Could not adjust quantity of %(title)s%(reasonGiven)s.",
                    ),
                    {
                        title: line.product.title,
                        reasonGiven: reason,
                    },
                    true,
                ),
            );
        }
    };

    function renderLine(line: IBasketLine, basket: IBasket) {
        switch (props.variant) {
            case BasketLineVariant.MINIMAL:
                return <BasketLineMinimal {...props} line={line} />;
            case BasketLineVariant.MINIMAL_ENHANCED:
                return (
                    <BasketLineMinimalEnhanced
                        {...props}
                        onRemoveLine={() => {
                            onRemoveLine(line);
                        }}
                        onUpdateLineQuantity={(e) => {
                            onUpdateLineQuantity(e, line);
                        }}
                        line={line}
                    />
                );
            case BasketLineVariant.FULL:
                return (
                    <BasketLineFull
                        {...props}
                        onRemoveLine={() => {
                            onRemoveLine(line);
                        }}
                        onUpdateLineQuantity={(e) => {
                            onUpdateLineQuantity(e, line);
                        }}
                        line={line}
                        basket={basket}
                        isMobile={props.isMobile}
                        deliveryIsFree={props.deliveryIsFree}
                    />
                );
        }
    }

    function renderVoucherLine(voucher: IVoucherDiscount) {
        switch (props.variant) {
            case BasketLineVariant.MINIMAL:
                return (
                    <VoucherLineMinimal
                        voucherData={voucher}
                        onRemoveLine={props.removeVoucherCode}
                    />
                );
            case BasketLineVariant.MINIMAL_ENHANCED:
                return (
                    <VoucherLineMinimalEnhanced
                        voucherData={voucher}
                        onRemoveLine={props.removeVoucherCode}
                    />
                );
            case BasketLineVariant.FULL:
                return (
                    <VoucherLineFull
                        voucherData={voucher}
                        onRemoveLine={props.removeVoucherCode}
                    />
                );
        }
    }

    function renderLineList(basket: IBasket) {
        const vouchers = basket.voucher_post_order_actions;
        return (
            <Fragment>
                {basket.lines.map((line) => (
                    <CSSTransition
                        key={`${line.url}`}
                        classNames="transition--fade"
                        timeout={{ exit: 500, enter: 500 }}
                    >
                        {renderLine(line, basket)}
                    </CSSTransition>
                ))}
                {vouchers.map((voucherData) => (
                    <CSSTransition
                        key={voucherData.voucher.code}
                        classNames="transition--fade"
                        timeout={{ exit: 500, enter: 500 }}
                    >
                        {renderVoucherLine(voucherData)}
                    </CSSTransition>
                ))}
            </Fragment>
        );
    }

    function renderEmptyBasket() {
        return [
            <CSSTransition
                key="empty"
                classNames="transition--fade"
                timeout={{ exit: 500, enter: 500 }}
            >
                <p>{gettext("Your cart is empty.")}</p>
            </CSSTransition>,
        ];
    }

    const isEmpty = !props.basket || props.basket.lines.length <= 0;
    const content =
        !isEmpty && props.basket
            ? renderLineList(props.basket)
            : renderEmptyBasket();
    return (
        <TransitionGroup>
            <ul className={styles.basketLinesList}>{content}</ul>
        </TransitionGroup>
    );
};

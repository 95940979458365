import React from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import { IHistogramEntry } from "../../../models/reviews.interfaces";
import { IWebPageURL, isoFormUUID } from "../../../models/nominals";
import { IProduct } from "../../../models/catalogue.interfaces";
import { getWriteReviewTemplate } from "../../../api/reviews";
import { roundReviewRating } from "../../../utils/math";
import { Popover } from "../../../common/Popover";
import { Link } from "../../../common/Link";
import { RatingGraphic } from "../../../common/RatingGraphic";
import { TStateMapper, TDispatchMapper } from "../../reducers.interfaces";
import { rootProductSelector, reviewHistogramSelector } from "../selectors";
import { Dispatchers as ReviewsDispatcher } from "../../reviews/dispatchers";
import { WriteReviewFormMode } from "../../reviews/constants";
import { Dispatchers as ConfiguratorDispatchers } from "../dispatchers";
import { RatingsModalRow } from "./RatingsModalRow";
import { formatNumber } from "../../../utils/format";

import styles from "./RatingsSummary.module.scss";

interface IOwnProps {
    isPanel?: boolean;
    starRatingURL?: IWebPageURL;
    showModal?: boolean;
}

interface IReduxProps {
    histogram: IHistogramEntry[];
    selectedRootProduct: IProduct | null;
    modalIsOpen: boolean;
}

interface IDispatchProps {
    reviewsDispatchers: ReviewsDispatcher;
    configuratorDispatchers: ConfiguratorDispatchers;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {}

class RatingsSummaryComponent extends React.Component<IProps, IState> {
    private readonly onWriteReview = async () => {
        this.closeRatingsModal();
        if (
            !this.props.selectedRootProduct ||
            !this.props.selectedRootProduct.reviews_product_id
        ) {
            return;
        }
        const writeReviewForm =
            document.querySelector<HTMLElement>(".write-review-form");
        const writeReviewFormUUIDstr = writeReviewForm?.dataset.formUuid;
        if (!writeReviewForm || !writeReviewFormUUIDstr) {
            return;
        }
        // Open form
        const writeReviewFormUUID = isoFormUUID.wrap(writeReviewFormUUIDstr);
        const template = await getWriteReviewTemplate(
            this.props.selectedRootProduct.reviews_product_id,
        );
        this.props.reviewsDispatchers.setReviewTemplate(
            writeReviewFormUUID,
            this.props.selectedRootProduct.reviews_product_id,
            template,
        );
        this.props.reviewsDispatchers.setWriteReviewFormMode(
            writeReviewFormUUID,
            WriteReviewFormMode.FORM_OPEN,
        );
        // Scroll to form
        writeReviewForm.scrollIntoView(true);
    };

    private readonly onViewAllReviews = async () => {
        this.scrollToReviewsModule();
    };

    public readonly onRequestClose = () => {
        this.props.configuratorDispatchers.setRatingsModalState(false);
    };

    private readonly onRequestOpen = () => {
        this.props.configuratorDispatchers.setRatingsModalState(true);
    };

    private scrollToReviewsModule() {
        this.closeRatingsModal();
        // Scroll and focus on the next tick since closing the modal will change focus also. We
        // need to make sure this happens after that.
        setTimeout(() => {
            const reviewsBody = document.querySelector<HTMLElement>(
                ".customer-review__body",
            );
            reviewsBody?.scrollIntoView(true);
            reviewsBody?.focus();
        }, 0);
    }

    private closeRatingsModal() {
        this.props.configuratorDispatchers.setRatingsModalState(false);
    }

    private buildPopoverContent(
        histogram: IHistogramEntry[],
        avgRating: number,
        totalReviewCount: number,
    ) {
        // Render empty state?
        if (totalReviewCount <= 0) {
            return (
                <div
                    className={`${styles.modalContent} configurator-rating-filter`}
                >
                    <button
                        className="configurator-rating-filter__button configurator-rating-filter__empty-rating"
                        onClick={this.onWriteReview}
                    >
                        {gettext("Be the first to write a review")}
                    </button>
                </div>
            );
        }
        // Render rating distribution rows
        return (
            <div
                className={`${styles.modalContent} configurator-rating-filter`}
            >
                <h2 className={styles.title}>
                    {interpolate(gettext("%s out of 5 stars"), [
                        `${avgRating}`,
                    ])}
                </h2>
                {histogram.map((entry) => (
                    <RatingsModalRow
                        key={entry.count}
                        totalReviewCount={totalReviewCount}
                        entry={entry}
                        onClickRating={this.onViewAllReviews}
                    />
                ))}
                <button
                    className={styles.button}
                    onClick={this.onViewAllReviews}
                >
                    {interpolate(gettext("View all %s reviews"), [
                        `${formatNumber(totalReviewCount)}`,
                    ])}
                </button>
            </div>
        );
    }

    private buildStaticSummary(avgRating: number, totalReviewCount: number) {
        if (!this.props.starRatingURL || totalReviewCount <= 0) {
            return null;
        }
        return (
            <Link
                href={this.props.starRatingURL}
                title={gettext("Read Reviews")}
            >
                <div className="configurator__star-rating">
                    <RatingGraphic
                        cardClass="configurator"
                        cardSize="large"
                        numReviews={totalReviewCount}
                        rating={avgRating}
                    />
                </div>
            </Link>
        );
    }

    private buildPopoverSummary(
        histogram: IHistogramEntry[],
        avgRating: number,
        totalReviewCount: number,
    ) {
        return (
            <>
                <Popover
                    isOpen={this.props.modalIsOpen}
                    onRequestOpen={this.onRequestOpen}
                    onRequestClose={this.onRequestClose}
                    popperOptions={{
                        modifiers: [
                            {
                                name: "offset",
                                options: {
                                    offset: [0, 16],
                                },
                            },
                        ],
                        placement: "bottom",
                    }}
                    modalProps={{
                        className: `${styles.modal} configurator-rating-filter-container__modal`,
                        contentLabel: gettext("Filter Star Ratings"),
                    }}
                    triggerContent={
                        <div className="configurator__star-rating">
                            <RatingGraphic
                                cardClass="configurator"
                                cardSize="large"
                                onlyStars={true}
                                rating={avgRating}
                                starHasStroke={false}
                            />
                        </div>
                    }
                >
                    {this.buildPopoverContent(
                        histogram,
                        avgRating,
                        totalReviewCount,
                    )}
                </Popover>
                <button
                    aria-describedby="configurator-rating-filter"
                    className={styles.reviewsNumber}
                    onClick={this.onViewAllReviews}
                >
                    {interpolate(gettext("%s Reviews"), [
                        `${formatNumber(totalReviewCount)}`,
                    ])}
                </button>
            </>
        );
    }

    render() {
        const histogram = this.props.histogram;
        const totalReviewCount = histogram.reduce(
            (memo, entry) => memo + entry.count,
            0,
        );
        const combinedScore = histogram.reduce(
            (memo, entry) => memo + entry.count * entry.rating,
            0,
        );
        const avgRating = roundReviewRating(combinedScore / totalReviewCount);
        const containerClasses = classNames({
            [styles.container]: true,
            [styles.centerContent]: this.props.isPanel,
            "configurator-rating-filter-container": true,
        });
        return (
            <div className={containerClasses}>
                {this.props.showModal && totalReviewCount > 0
                    ? this.buildPopoverSummary(
                          histogram,
                          avgRating,
                          totalReviewCount,
                      )
                    : this.buildStaticSummary(avgRating, totalReviewCount)}
            </div>
        );
    }
}

const mapStateToProps: TStateMapper<
    "configurator" | "reviews",
    IReduxProps,
    IOwnProps
> = (rootState, ownProps) => {
    const selectedRootProduct = rootProductSelector(rootState.configurator);
    return {
        modalIsOpen: rootState.configurator.ui.ratingsModalOpen,
        histogram: reviewHistogramSelector(
            rootState.reviews.data.products,
            selectedRootProduct?.uuid ?? null,
        ),
        selectedRootProduct: selectedRootProduct,
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const reviewsDispatchers = new ReviewsDispatcher(dispatch);
    const configuratorDispatchers = new ConfiguratorDispatchers(dispatch);
    return {
        reviewsDispatchers: reviewsDispatchers,
        configuratorDispatchers: configuratorDispatchers,
    };
};

export const RatingsSummary = connect(
    mapStateToProps,
    mapDispatchToProps,
)(RatingsSummaryComponent);

import React from "react";
import format from "../../../utils/format";
import { FinancingPlan } from "../../../models/financing";
import { isoAPR, IMonths, isoMonths } from "../../../models/nominals";
import { FormRadioSelect } from "../../../forms/FormRadioSelect";
import { IFinancingPayment, IReduxFormState } from "../reducers.interfaces";
import { Dispatchers } from "../dispatchers";
import classNames from "classnames";
import { getDinero, getMonthlyPrice } from "../../../utils/money";
import { Trans } from "../../../common/Trans";

interface IProps {
    methodKey: string;
    form: IReduxFormState;
    plans: FinancingPlan[];
    grandTotal: string;
    setPaymentMethodFields: Dispatchers["setPaymentMethodFields"];
    setPlanMonths: (months: IMonths) => void;
    disabled: boolean;
}

interface IState {}

export class SelectPlanForm extends React.Component<IProps, IState> {
    componentDidMount() {
        this.props.setPlanMonths(this.props.plans[0].term_months);
    }

    private getMethodData(): IFinancingPayment {
        const data = this.props.form.payment_methods[this.props.methodKey];
        let wellsFargoData: IFinancingPayment | null = null;
        if (data.method_type === "financing") {
            wellsFargoData = data;
        }
        const baseFields: IFinancingPayment = {
            method_type: "financing",
            amount: "",
            pay_balance: false,
            new_financing_account: null,
            financing_account: "",
            financing_plan: null,
            has_agreed: false,
            has_esigned: false,
        };
        return {
            ...baseFields,
            ...wellsFargoData,
        };
    }

    private onChange(event: React.FormEvent<HTMLInputElement>) {
        const planID = parseInt(event.currentTarget.value, 10);
        const plan = this.props.plans.find((x) => {
            return x.id === planID;
        });
        if (!plan) {
            return;
        }
        const planMonths = plan.term_months;
        this.props.setPlanMonths(planMonths);
        this.props.setPaymentMethodFields(this.props.methodKey, {
            financing_plan: planID,
        });
    }

    private getTotal() {
        return (
            this.props.form.payment_methods[this.props.methodKey].amount ||
            "0.00"
        );
    }

    private getPlanDescription(plan: FinancingPlan) {
        if (
            plan.term_months <= isoMonths.wrap(0) ||
            parseFloat(isoAPR.unwrap(plan.apr)) > 0
        ) {
            return <span>{gettext("Regular Terms Apply")}</span>;
        }
        return (
            <span className="heavy">
                {interpolate(gettext("%s months"), [`${plan.term_months}`])}
            </span>
        );
    }

    private getPlanExtraDescription(plan: FinancingPlan) {
        return (
            <span className="tiny">
                {gettext("Minimum Financing Amount:")}{" "}
                {format.money(plan.product_price_threshold)}
            </span>
        );
    }

    private getEstimatedPayments(plan: FinancingPlan) {
        if (
            plan.term_months <= isoMonths.wrap(0) ||
            parseFloat(isoAPR.unwrap(plan.apr)) > 0
        ) {
            return (
                <span>
                    You can locate your rate on your monthly statement or by
                    calling Wells Fargo at: <br />
                    1-800-431-5921
                </span>
            );
        }
        const payment = getMonthlyPrice(
            getDinero(this.getTotal()),
            plan.term_months,
        );
        return (
            <span>
                <Trans
                    fmtString={interpolate(
                        gettext(
                            `%(payment)s/month<Superscript>2</Superscript> for %(length)s months (total payments of %(total)s)`,
                        ),
                        {
                            payment: `${format.money(payment)}`,
                            length: `${plan.term_months}`,
                            total: `${format.money(this.getTotal())}`,
                        },
                        true,
                    )}
                    data={{
                        Superscript: (content) => <sup key="1">{content}</sup>,
                    }}
                />
            </span>
        );
    }

    private buildTermHeader(plans: FinancingPlan[]) {
        if (plans.length === 1 && parseFloat(isoAPR.unwrap(plans[0].apr)) > 0) {
            return null;
        }

        const aprInfo = interpolate(
            gettext(
                "Your order of %s qualifies for a special interest rate of 0% APR with equal monthly payments.",
            ),
            [format.money(getDinero(this.getTotal()))],
        );

        return (
            <>
                <h3 className="checkout-step__copy--bold">
                    {gettext("Special Terms & APR Information")}
                </h3>
                <div className="form-options__financing-terms">{aprInfo}</div>
            </>
        );
    }

    render() {
        const formClass = classNames({
            "form-options-financing": true,
        });

        const plans = this.props.plans.map((plan) => {
            return {
                value: `${plan.id}`,
                label: this.getPlanDescription(plan),
                helpText: this.getEstimatedPayments(plan),
                extraText: !this.props.disabled
                    ? this.getPlanExtraDescription(plan)
                    : "",
                disabled:
                    this.props.disabled ||
                    parseFloat(plan.product_price_threshold) >
                        parseFloat(this.getTotal()),
            };
        });

        const termHeader = this.buildTermHeader(this.props.plans);

        const onChange = (event: React.FormEvent<HTMLInputElement>) => {
            this.onChange(event);
        };

        return (
            <div className={formClass}>
                {termHeader}
                <div className="form-options__financing-terms--choices">
                    <FormRadioSelect
                        name="financing_plan"
                        value={`${this.getMethodData().financing_plan}`}
                        onChange={onChange}
                        legend={gettext("Select term length:")}
                        choices={plans}
                    />
                </div>
            </div>
        );
    }
}

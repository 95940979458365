import React from "react";

interface Props {
    isRefreshing: boolean;
}

export const TitleText = (props: Props) => {
    if (props.isRefreshing) {
        return <>{gettext("Your application is processing.")}</>;
    }
    return <>{gettext("Your application is pending review.")}</>;
};

import React, { useState, useEffect } from "react";
import { captureException } from "@sentry/browser";
import * as financingAPI from "../../../api/financing";
import { IProduct } from "../../../models/catalogue.interfaces";
import { IWebPageURL, IProductID } from "../../../models/nominals";
import { FinancingPlan } from "../../../models/financing";
import { ConfiguratorPrice } from "../../../common/ConfiguratorPrice";
import { DiscountDisplayMode } from "../../configurator/constants";
import { ICompareTile } from "../../../models/product-compare.interfaces";
import { filterFinancingPlans, sortFinancingPlans } from "../../checkout/utils";
import { getVariantPrice } from "../selectors";
import { ProductCompareTheme } from "../../../constants";
import { ShopNowLink } from "./ShopNowLink";
import styles from "./ProductCompareTileCTA.module.scss";
import { TryInStoreCalloutContainer } from "./TryInStoreCallout";

interface IProps {
    data: ICompareTile;
    financingLink: IWebPageURL;
    selectedVariant: IProduct | null;
    showStartingAt: boolean;
    showInStoreCallout?: boolean;
    theme?: string | null;
}

export const ProductCompareTileCTA = (props: IProps) => {
    const [financingPlans, setFinancingPlans] = useState<FinancingPlan[]>([]);

    useEffect(() => {
        const fetchPlans = async () => {
            if (props.selectedVariant?.id) {
                const plans = await getVariantFinancingPlans(
                    props.selectedVariant.id,
                );
                if (plans) {
                    setFinancingPlans(plans);
                }
            }
        };
        fetchPlans();
    }, [props.selectedVariant]);

    const isStepUpOrSelect = () => {
        return (
            props.theme === ProductCompareTheme.STEP_UP ||
            props.theme === ProductCompareTheme.SELECT
        );
    };

    const getVariantFinancingPlans = async (productID: IProductID) => {
        // Load Financing plans depending on the product
        const plans = await financingAPI
            .listFinancingPlansForProduct(productID)
            .catch((err) => {
                captureException(err);
                return [];
            });
        const filteredPlans = filterFinancingPlans(plans);
        return sortFinancingPlans(filteredPlans);
    };

    const buildPrice = () => {
        if (props.selectedVariant) {
            return (
                <>
                    <ConfiguratorPrice
                        price={getVariantPrice(props.selectedVariant)}
                        variant={props.selectedVariant}
                        selectedAddonPrice={null}
                        financingLink={props.financingLink}
                        strikeThroughMSRP={false}
                        discountDisplayMode={DiscountDisplayMode.DISABLED}
                        showStartingAt={props.showStartingAt}
                        actualPriceStyle={""}
                        addPriceText={
                            props.theme === ProductCompareTheme.THEME_ONE
                        }
                        financingPlans={financingPlans}
                    />
                </>
            );
        }
        if (props.theme === ProductCompareTheme.WITHOUT_COMPARE) {
            return (
                <div className="configurator__price">
                    <p className="type_x0point75">{gettext("Unavailable")}</p>
                </div>
            );
        }
        return (
            <div className="configurator__price">
                <p className="type_x0point75">
                    {gettext("Unavailable in this size")}
                </p>
            </div>
        );
    };

    return (
        <div
            className={`product-compare-tile-cta ${styles.root} ${props.theme}`}
        >
            {buildPrice()}
            {props.showInStoreCallout && <TryInStoreCalloutContainer />}
            {isStepUpOrSelect() || (
                <ShopNowLink
                    tile={props.data}
                    selectedVariant={props.selectedVariant}
                />
            )}
        </div>
    );
};

import React from "react";
import { STR_YES } from "../../../constants";
import { assertNever } from "../../../utils/never";
import { notEmpty } from "../../../utils/functional";
import { connect } from "react-redux";
import {
    IOptionValues,
    IOptionValue,
    IOptionSingleValue,
} from "../../../models/catalogue.interfaces";
import { TStateMapper } from "../../reducers.interfaces";
import { defaults } from "../defaults";
import { getOptionValueByIdx } from "../utils";
import { IPLCProductOptionSelector } from "../models.interfaces";

interface IOwnProps {
    selectors: IPLCProductOptionSelector[];
}

interface IReduxProps {
    values: IOptionValues;
}

interface IProps extends IOwnProps, IReduxProps {}

const getValue = (
    values: IOptionValues,
    selector: IPLCProductOptionSelector,
): IOptionValue | undefined => {
    switch (selector.type) {
        case "mattress_size":
            return values.option_size;
        case "mattress_feel":
            return values.option_feel;
        case "boolean_option_group":
            return selector.value.selectors
                .reduce<IOptionSingleValue[]>((memo, subSel) => {
                    const val = getOptionValueByIdx(
                        values[subSel.attribute],
                        0,
                    );
                    if (val === STR_YES) {
                        memo.push(subSel.name);
                    }
                    return memo;
                }, [])
                .join(", ");
        case "other":
            return values[selector.value.attribute];
    }
    throw assertNever(selector);
};

const PLCProductOptionSelectorCurrentValueComponent = (props: IProps) => {
    const values = props.selectors
        .map((selector) => {
            const rawValue = getValue(props.values, selector);
            return getOptionValueByIdx(rawValue, 0);
        })
        .filter(notEmpty)
        .filter((val) => !!val);
    const text = values.length <= 0 ? gettext("Select…") : values.join(", ");
    return <>{text}</>;
};

const mapStateToProps: TStateMapper<"configurator", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.configurator || defaults;
    return {
        values: state.ui.optionValues,
        ...ownProps,
    };
};

export const PLCProductOptionSelectorCurrentValue = connect(mapStateToProps)(
    PLCProductOptionSelectorCurrentValueComponent,
);

import { isElement } from "./guards";

const focusableElemSelector = [
    "a[href]:not([disabled])",
    "button:not([disabled])",
    "textarea:not([disabled])",
    'input[type="text"]:not([disabled])',
    'input[type="radio"]:not([disabled])',
    'input[type="checkbox"]:not([disabled])',
    'input[type="email"]:not([disabled])',
    'input[type="submit"]:not([disabled])',
    "select:not([disabled])",
].join(", ");

export const getFocusableElems = (parent: HTMLElement) => {
    const elems = Array.from(
        parent.querySelectorAll<HTMLElement>(focusableElemSelector),
    ).filter((el) => {
        return (
            window.getComputedStyle(el).getPropertyValue("visibility") !==
            "hidden"
        );
    });
    return elems;
};

export const trapFocus = (element: HTMLElement) => {
    const focusableElems = getFocusableElems(element);
    const firstFocusableEl = focusableElems[0];
    const lastFocusableEl = focusableElems[focusableElems.length - 1];

    element.addEventListener("keydown", (event) => {
        const isTabPressed = event.key === "Tab";

        if (!isTabPressed) {
            return;
        }

        /* shift + tab */
        if (event.shiftKey) {
            if (document.activeElement === firstFocusableEl) {
                focusElement(lastFocusableEl);
                event.preventDefault();
            }
            /* tab */
        } else {
            if (document.activeElement === lastFocusableEl) {
                focusElement(firstFocusableEl);
                event.preventDefault();
            }
        }
    });
};

export const trapMenuFocus = (
    menuElem: HTMLElement,
    closeLinkElem: HTMLElement,
) => {
    const focusableElems = getFocusableElems(menuElem);
    const firstFocusableEl = focusableElems[0];
    const lastFocusableEl = focusableElems[focusableElems.length - 1];

    closeLinkElem.addEventListener("keydown", (event) => {
        const isTabPressed = event.key === "Tab";

        if (!isTabPressed) {
            return;
        }

        /* shift + tab */
        if (event.shiftKey) {
            if (document.activeElement === closeLinkElem) {
                focusElement(lastFocusableEl);
                event.preventDefault();
            }
            /* tab */
        } else {
            if (document.activeElement === closeLinkElem) {
                focusElement(firstFocusableEl);
                event.preventDefault();
            }
        }
    });

    menuElem.addEventListener("keydown", (event) => {
        const isTabPressed = event.key === "Tab";

        if (!isTabPressed) {
            return;
        }

        /* shift + tab */
        if (event.shiftKey) {
            if (document.activeElement === firstFocusableEl) {
                focusElement(closeLinkElem);
                event.preventDefault();
            }
            /* tab */
        } else {
            if (document.activeElement === lastFocusableEl) {
                focusElement(closeLinkElem);
                event.preventDefault();
            }
        }
    });
};

export const focusElement = (selector: HTMLElement | string) => {
    const elem = isElement(selector)
        ? selector
        : document.querySelector<HTMLElement>(selector);
    if (elem) {
        console.trace(`Changing keyboard focus to ${selector}.`);
        elem.focus();
    } else {
        console.trace(
            `Attempted to change keyboard focus to ${selector}, but no matching element exists.`,
        );
    }
};

import React from "react";
import Modal from "react-modal";
import SVG from "react-inlinesvg";
import { connect } from "react-redux";
import { TStateMapper, TDispatchMapper } from "../../reducers.interfaces";
import {
    Action,
    IReduxState,
    IActionUpdateSelectManyFilterValue,
    IActionUpdateSortMethod,
} from "../reducers.interfaces";
import { defaults } from "../defaults";
import { GridFilterConfig } from "../filters";
import { IProduct } from "../../../models/catalogue.interfaces";
import { GridFilter, GridFilterVariants } from "./GridFilter";

import styles from "./FiltersPanel.sleepoutfitters.module.scss";
import { focusElement } from "../../../utils/keyboardFocus";
import { GridSorterConfig } from "../sorters";
import { GridSort } from "./GridSort";
import iconXClose from "../../../../img/icons/x-close.svg";

interface IOwnProps {
    filters: GridFilterConfig[];
    sorters?: GridSorterConfig[];
    isOpen: boolean;
    onClose: () => void;
}

interface IReduxProps {
    products: IProduct[];
    filterState: IReduxState["filters"];
    sortMethod: string | null;
}

interface IDispatchProps {
    onButtonClick: (
        optionNamespace: string,
        filterID: string,
        optionID: string,
    ) => void;
    onChangeSortMethod: (sortMethod: string) => void;
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {}

export class FiltersPanelComponent extends React.PureComponent<IProps, IState> {
    private readonly onButtonClear = (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        event.preventDefault();
        const optionNamespace =
            this.props.products[0]?.product_class_slug || "";
        for (const filterConfig of this.props.filters) {
            const options = filterConfig.listFilterOptions(this.props.products);
            const filterState = this.props.filterState[filterConfig.filterID];
            const selectedValues = new Set(
                filterState ? filterState.selectedValues : [],
            );
            for (const option of options) {
                if (selectedValues.has(option.id)) {
                    this.props.onButtonClick(
                        optionNamespace,
                        filterConfig.filterID,
                        option.id,
                    );
                }
            }
        }
    };

    render() {
        const modalOverlayStyle = {
            overlay: {
                backgroundColor: "transparent",
            },
        };

        return (
            <Modal
                aria={{ modal: true }}
                contentLabel={gettext("Address Verification")}
                style={modalOverlayStyle}
                isOpen={this.props.isOpen}
                onRequestClose={() => {
                    this.props.onClose();
                }}
                onAfterOpen={() => {
                    focusElement(`.${styles.close}`);
                }}
                role="dialog"
            >
                <div className={styles.container}>
                    <button
                        className={styles.close}
                        aria-label={gettext("close")}
                        onClick={() => {
                            this.props.onClose();
                        }}
                    >
                        <SVG
                            aria-hidden="true"
                            src={iconXClose}
                            title={gettext("Close Icon")}
                        />
                    </button>
                    <h1 className={styles.title}>All Filters</h1>
                    <div className={styles.filtersContainer}>
                        {this.props.sorters && (
                            <GridSort
                                sorters={this.props.sorters}
                                sortMethod={this.props.sortMethod}
                                onChangeSortMethod={
                                    this.props.onChangeSortMethod
                                }
                            />
                        )}

                        {this.props.filters.map((config) => (
                            <GridFilter
                                key={config.filterID}
                                config={config}
                                variant={GridFilterVariants.ACCORDION}
                                showSelectedList={true}
                            />
                        ))}
                    </div>
                    <nav className={styles.panelNav}>
                        <button
                            className="button"
                            onClick={() => {
                                this.props.onClose();
                            }}
                        >
                            Apply
                        </button>
                        <button
                            className="button button--secondary"
                            onClick={this.onButtonClear.bind(this)}
                        >
                            Clear Filters
                        </button>
                    </nav>
                </div>
            </Modal>
        );
    }
}

const mapStateToProps: TStateMapper<"productgrid2", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.productgrid2 || defaults;
    return {
        ...ownProps,
        products: state.products,
        filterState: state.filters,
        sortMethod: state.sortMethod,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    return {
        onButtonClick: (optionNamespace, filterID, optionID) => {
            dispatch<IActionUpdateSelectManyFilterValue>({
                type: Action.UPDATE_SELECT_MANY_FILTER_VALUE,
                payload: {
                    optionNamespace: optionNamespace,
                    filterID: filterID,
                    optionID: optionID,
                    isSelected: false,
                },
            });
        },
        onChangeSortMethod: (sortMethod: string) => {
            dispatch<IActionUpdateSortMethod>({
                type: Action.UPDATE_SORT_METHOD,
                payload: sortMethod,
            });
        },
    };
};

export const FiltersPanel = connect(
    mapStateToProps,
    mapDispatchToProps,
)(FiltersPanelComponent);

import React, { useState, useEffect, useMemo } from "react";
import { Trans } from "../../../common/Trans";
import { FinancingModalTrigger } from "../../financing/FinancingModalTrigger";
import {
    FinancingPlanMeta,
    getDefaultFinancingPlan,
} from "../../../utils/financing";
import Link from "../../../common/Link";
import { urls } from "../../../utils/urls";
import { getPageSetting } from "../../../utils/settings";
import { formatAPR, formatTermLength } from "../../../utils/format";
import { IWebPageURL, IMonths } from "../../../models/nominals";
import { FinancingCardType } from "../../../constants";
import { FinancingCardImage } from "../FinancingCardImage";

import styles from "./Configurator.module.scss";

interface Props {
    planLength?: IMonths | null;
    financingLink?: IWebPageURL;
}

const getCardTypeByAppSlug = (): FinancingCardType | null => {
    const appSlugName = getPageSetting("app-slug");
    switch (appSlugName) {
        case "tempurpedic":
        case "sale2": {
            return FinancingCardType.TEMPUR_CC;
        }
        case "stearns": {
            return FinancingCardType.STEARNS_CC;
        }
        case "sealy": {
            return FinancingCardType.SEALY_CC;
        }
        case "cocoon": {
            return FinancingCardType.COCOON_CC;
        }
        case "sleepoutfitters": {
            return FinancingCardType.SLEEPOUTFITTERS_CC;
        }
    }
    return null;
};

export const FinancingModalTriggerConfigurator = (props: Props) => {
    const cardType = useMemo(getCardTypeByAppSlug, []);
    const [defaultPlan, setDefaultPlan] = useState<FinancingPlanMeta | null>(
        null,
    );

    useEffect(() => {
        setDefaultPlan(getDefaultFinancingPlan());
    }, []);

    const financeLink = props.financingLink
        ? props.financingLink
        : urls.pageURL("finance-link");
    return (
        <div className={styles.root}>
            <div className={styles.cardImageHolder}>
                {cardType && <FinancingCardImage cardType={cardType} />}
            </div>
            {!props.planLength || !defaultPlan ? (
                <Trans
                    fmtString={gettext(
                        `<Trigger>Check if you Pre-Qualify</Trigger>
                        <P>without affecting your credit score.</P>`,
                    )}
                    data={{
                        Trigger: (content) => (
                            <FinancingModalTrigger
                                key={1}
                                modalType="prequal-app"
                            >
                                {content}
                            </FinancingModalTrigger>
                        ),
                        P: (content) => <p key={2}>{content}</p>,
                    }}
                />
            ) : (
                <>
                    <Link className={styles.aprCopy} href={financeLink}>
                        <h6>
                            <Trans
                                fmtString={interpolate(
                                    gettext(
                                        "%(apr)s APR for %(length)s Months<Sup>1</Sup>",
                                    ),
                                    {
                                        apr: defaultPlan
                                            ? formatAPR(defaultPlan.apr)
                                            : "",
                                        length: formatTermLength(
                                            props.planLength,
                                        ),
                                    },
                                    true,
                                )}
                                data={{
                                    Sup: (content) => (
                                        <sup key={1}>{content}</sup>
                                    ),
                                }}
                            />
                        </h6>
                    </Link>
                    <FinancingModalTrigger modalType="prequal-app">
                        {gettext("Check if you Pre-Qualify")}
                    </FinancingModalTrigger>
                </>
            )}
        </div>
    );
};

import React, { useEffect, useState } from "react";
import styles from "./OrderOffer.module.scss";
import { Image } from "tsi-common-react/src/common/Image";
import Link from "tsi-common-react/src/common/Link";
import urls from "tsi-common-react/src/utils/urls";
import format from "tsi-common-react/src/utils/format";

interface OrderOfferProps {
    offer_freq: string;
    benefit_value: string;
    desktop_image?: string;
    mobile_image?: string;
    offer_name: string;
}

export const OrderOffer = (props: OrderOfferProps) => {
    const isMobileSize = window.innerWidth <= 500;
    const [isMobile, setIsMobile] = useState(isMobileSize);

    useEffect(() => {
        window.addEventListener("resize", isWindowResizing);
        return () => {
            window.removeEventListener("resize", isWindowResizing);
        };
    }, [isWindowResizing]);

    function isWindowResizing() {
        if (isMobileSize) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }
    return (
        <div className={styles.grid}>
            <Image
                className={styles.image}
                alt={props.offer_name}
                src={isMobile ? props.mobile_image : props.desktop_image}
            />
            <div className={styles.offerInfo}>
                <h5>{props.offer_name}</h5>
                <span>
                    {gettext("Qty:")}&nbsp; {props.offer_freq}{" "}
                </span>
                <span className={styles.offerValue}>
                    {format.money(props.benefit_value)}
                </span>
                <Link
                    href={urls.pageURL("how-will-i-receive-my-card")}
                    target="_top"
                >
                    {gettext("How will I receive my card?")}
                </Link>
            </div>
        </div>
    );
};

import React from "react";
import { connect } from "react-redux";
import { Key } from "ts-key-enum";
import { parseFacetValues } from "../../../utils/reviews";
import { TStateMapper, TDispatchMapper } from "../../reducers.interfaces";
import { Dispatchers } from "../dispatchers";
import { defaultState } from "../defaults";
import styles from "./TextFilterDisplay.module.scss";

interface IOwnProps {
    maxKeywordsNumber?: number;
}

interface IReduxProps {
    queries: string[];
}

interface IDispatchProps {
    updateFilterOptionValue: Dispatchers["updateFilterOptionValue"];
}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {}

class TextFilterDisplayComponent extends React.PureComponent<IProps, IState> {
    private readonly onRemoveClick = (value: string) => {
        this.props.updateFilterOptionValue("text", value, false);
    };

    private readonly onRemoveKeyPress = (
        value: string,
        event: React.KeyboardEvent,
    ) => {
        if (event.key === Key.Enter) {
            this.props.updateFilterOptionValue("text", value, false);
        }
    };

    render() {
        if (!this.props.queries) {
            return null;
        }
        const textBoxes = this.props.queries.map((value, i) => {
            if (
                this.props.maxKeywordsNumber &&
                this.props.maxKeywordsNumber - 1 < i
            ) {
                return null;
            }
            return (
                <button
                    type="button"
                    key={i}
                    className={styles.item}
                    onKeyPress={this.onRemoveKeyPress.bind(this, value)}
                    onClick={this.onRemoveClick.bind(this, value)}
                >
                    <span className="customer-review__top-filters__item--text">
                        {value}
                    </span>
                    <span
                        className={styles.itemRemove}
                        title={gettext("Clear Search Text")}
                        aria-hidden="true"
                    >
                        X
                    </span>
                    &nbsp;
                    <span className="ada-screenreader-only">remove filter</span>
                </button>
            );
        });
        return <div>{textBoxes}</div>;
    }
}

const mapStateToProps: TStateMapper<"reviews", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.reviews || defaultState;
    return {
        queries: parseFacetValues(state.ui.facetValues.text) || [],
        ...ownProps,
    };
};

const mapDispatchToProps: TDispatchMapper<IDispatchProps> = (dispatch) => {
    const dispatchers = new Dispatchers(dispatch);
    return {
        updateFilterOptionValue: dispatchers.updateFilterOptionValue,
    };
};

export const TextFilterDisplay = connect(
    mapStateToProps,
    mapDispatchToProps,
)(TextFilterDisplayComponent);

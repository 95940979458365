import React from "react";
import { OrderCancellationInfo } from "../../../models/orders";
import { FormRadioSelect } from "../../../forms/FormRadioSelect";
import { FormTextbox } from "../../../forms/FormTextbox";
import { Form } from "../../../forms/Form";
import { FormSubmit } from "../../../forms/FormSubmit";
import { CancelModalViewState, OTHER_OPTION } from "../models";
import styles from "./CancelReasonForm.module.scss";

interface IProps {
    viewState: CancelModalViewState;
    cancellationInfo: OrderCancellationInfo;
    selectedReason: string | null;
    enteredReason: string;
    onSelectReason: (reason: string) => void;
    onEnterReason: (reason: string) => void;
    onSubmit: () => Promise<void>;
}

export const CancelReasonForm = (props: IProps) => {
    // Figure out what inputs are enabled/disabled
    const isSaving = props.viewState === CancelModalViewState.SAVING;
    const isBtnDisabled =
        isSaving ||
        !props.selectedReason ||
        (props.selectedReason === OTHER_OPTION && !props.enteredReason);
    // Build radio button choices
    const choices = props.cancellationInfo.cancellation_reasons.map((r) => {
        return {
            value: r.reason,
            label: r.reason,
            disabled: isSaving,
        };
    });
    // Add an "other" choice
    choices.push({
        value: OTHER_OPTION,
        label: gettext("Other"),
        disabled: isSaving,
    });
    // Draw the form
    return (
        <Form onSubmit={props.onSubmit} className={styles.root}>
            <FormRadioSelect
                name={`order-${props.cancellationInfo.number}-select-reason`}
                id={`order-${props.cancellationInfo.number}-select-reason`}
                required={true}
                legend={gettext("Why do you wish to cancel?")}
                labelPlacement={"static"}
                choices={choices}
                value={props.selectedReason || undefined}
                onChange={(e) => {
                    props.onSelectReason(e.currentTarget.value);
                }}
            />
            {props.selectedReason === OTHER_OPTION && (
                <FormTextbox
                    name={`order-${props.cancellationInfo.number}-enter-reason`}
                    id={`order-${props.cancellationInfo.number}-enter-reason`}
                    required={true}
                    label={gettext("Why do you wish to cancel?")}
                    labelPlacement={"static"}
                    rows={4}
                    minLength={1}
                    disabled={isSaving}
                    value={props.enteredReason}
                    onChange={(e) => {
                        props.onEnterReason(e.currentTarget.value);
                    }}
                />
            )}
            <FormSubmit
                value={gettext("Confirm Cancellation")}
                disabled={isBtnDisabled}
            />
        </Form>
    );
};

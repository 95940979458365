import React from "react";
import Modal from "react-modal";
// import SVG from 'react-inlinesvg';
import classNames from "classnames";

interface IProps {
    isOpen: boolean;
    isSubmitting: boolean;
    onClose: () => void;
    onSubmit: (data: { rating: number | null }) => void;
}

interface IState {
    rating: number | null;
}

export class SurveyModalRateWebsite extends React.Component<IProps, IState> {
    state: IState = {
        rating: null,
    };

    private readonly possibleRatings = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    private readonly onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        this.props.onSubmit({
            rating: this.state.rating,
        });
    };

    private buildRatings() {
        const elems = this.possibleRatings.map((rating) => {
            const classes = classNames({
                "br-current": this.state.rating === rating,
            });
            const screenReaderText =
                this.state.rating === rating ? (
                    <span className="ada-screenreader-only">
                        This element is selected
                    </span>
                ) : null;
            return (
                <a
                    key={rating}
                    data-rating-value={rating}
                    data-rating-text={rating}
                    className={classes}
                    href="#"
                    onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                            rating: rating,
                        });
                    }}
                >
                    {screenReaderText}
                    {rating}
                </a>
            );
        });
        return elems;
    }

    render() {
        return (
            <Modal
                aria={{
                    modal: true,
                }}
                style={{
                    content: {
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        maxHeight: "95%",
                        maxWidth: "542px",
                        width: "96%",
                        overflow: "auto",
                        padding: "40px",
                    },
                }}
                contentLabel="Survey"
                portalClassName="locator"
                className="locations store-locator-survey modal"
                isOpen={this.props.isOpen}
                onRequestClose={this.props.onClose}
                role="dialog"
            >
                <div className="modal-inner">
                    <div
                        aria-label={gettext("Close")}
                        className="modal-close button--ghost"
                        onClick={this.props.onClose}
                    >
                        <div className="pdp-modal__close-icon modal-close__icon"></div>
                    </div>
                    <div className="send__content survey__carousel">
                        <h3
                            dangerouslySetInnerHTML={{
                                __html: gettext(
                                    "Your directions<br />are on the way…",
                                ),
                            }}
                        ></h3>
                        <p className="modal-intro">
                            {gettext(
                                "One quick question. How was your online experience today?",
                            )}
                        </p>
                        <form onSubmit={this.onSubmit}>
                            <fieldset>
                                <legend className="ada-screenreader-only">
                                    Rate your experience
                                </legend>
                                <div className="br-wrapper br-theme-bars-square">
                                    <div className="br-widget">
                                        {this.buildRatings()}
                                    </div>
                                </div>
                                <div className="form__field form__field--submit">
                                    <input
                                        id="survey-cell2-submit-email"
                                        type="submit"
                                        value="Continue"
                                        className="button al-store-locator__button--continue"
                                        disabled={!this.state.rating}
                                    />
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </Modal>
        );
    }
}

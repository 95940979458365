import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { IFormUUID, IReviewsProductTypeID } from "../../../models/nominals";
import { NOT_SURE_VARIANT_CHOICE_SLUG } from "../constants";
import {
    IWriteReviewTemplate,
    IReviewsProduct,
    IReviewsProductVariantChoices,
} from "../../../models/reviews.interfaces";
import { TStateMapper } from "../../reducers.interfaces";
import { FieldRowGroup } from "../models.interfaces";
import {
    getWriteReviewTemplateForForm,
    getWriteReviewFormSelectedProduct,
    getWriteReviewFormSelectedVariant,
} from "../selectors";
import { WriteReviewFormTextField } from "./WriteReviewFormTextField";
import { WriteReviewFormTextArea } from "./WriteReviewFormTextArea";
import { WriteReviewFormCheckboxField } from "./WriteReviewFormCheckboxField";
import { WriteReviewFormRadioField } from "./WriteReviewFormRadioField";
import { WriteReviewFormDropdownField } from "./WriteReviewFormDropdownField";
import { WriteReviewFormStarsField } from "./WriteReviewFormStarsField";

interface IOwnProps {
    formUUID: IFormUUID;
    displayedProductTypeIDWhitelist?: IReviewsProductTypeID[];
    brand?: string;
    isMultiReview?: boolean;
}

interface IReduxProps {
    selectedProduct: IReviewsProduct | null;
    selectedVariant: IReviewsProductVariantChoices;
    writeReviewTemplate: IWriteReviewTemplate;
}

interface IDispatchProps {}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {}

class WriteReviewFormFieldsComponent extends React.PureComponent<
    IProps,
    IState
> {
    private getFieldRowGroups(): FieldRowGroup[] {
        const fieldRowGroups: FieldRowGroup[] = [
            [["rating"]],
            [["headline"], ["comments"]],
            [["bottomline"]],
            [
                ["name", "location" /* 'merchant_user_email'*/],
                ["email_collection"],
            ],
        ];
        return fieldRowGroups;
    }

    private getStarsFieldTitle() {
        if (!this.props.selectedProduct) {
            return "Write a Review";
        }
        return interpolate(
            gettext(
                "%(prefix)s %(selectedProductName)s %(separator)s %(selectedVariantName)s",
            ),
            {
                prefix: this.props.isMultiReview
                    ? ""
                    : "Write a Review for your ",
                selectedProductName: `${this.props.selectedProduct.name}`,
                selectedVariantName: `${
                    this.props.selectedVariant &&
                    this.props.selectedVariant !== NOT_SURE_VARIANT_CHOICE_SLUG
                        ? this.props.selectedVariant.display_name
                        : ""
                }`,
                separator: this.props.isMultiReview ? ": " : "",
            },
            true,
        );
    }

    private buildField(fieldName: string) {
        const field = this.props.writeReviewTemplate[fieldName];
        if (!field) {
            return null;
        }
        const commonProps = {
            formUUID: this.props.formUUID,
            name: fieldName,
            field: field,
            isMultiReview: this.props.isMultiReview,
        };
        switch (field.input_type) {
            case "Stars":
                return (
                    <WriteReviewFormStarsField
                        {...commonProps}
                        title={this.getStarsFieldTitle()}
                        brand={this.props.brand}
                    />
                );

            case "TextBox":
                return <WriteReviewFormTextField {...commonProps} />;

            case "TextArea":
                return <WriteReviewFormTextArea {...commonProps} />;

            case "CheckboxVertical":
                return <WriteReviewFormCheckboxField {...commonProps} />;

            case "RadioHorizontal":
            case "RadioHorizontalLinked":
            case "RadioVertical":
                return <WriteReviewFormRadioField {...commonProps} />;

            case "Dropdown":
                return <WriteReviewFormDropdownField {...commonProps} />;

            default:
                console.error(
                    `Unsupported review field input type: name=${fieldName}, type=${field.input_type}`,
                );
        }

        return null;
    }

    private buildRow(row: string[]) {
        return row.map((fieldName) => {
            const classes = classNames({
                "write-review-form__field": true,
                [`write-review-form__field--${fieldName}`]: true,
            });
            return (
                <div key={fieldName} className={classes}>
                    {this.buildField(fieldName)}
                </div>
            );
        });
    }

    render() {
        const fieldRowGroups = this.getFieldRowGroups();
        return (
            <>
                {fieldRowGroups.map((rows, i) => (
                    <div key={i} className="write-review-form__field-row-group">
                        {rows.map((row, j) => (
                            <div
                                key={j}
                                className="write-review-form__field-row"
                            >
                                {this.buildRow(row)}
                            </div>
                        ))}
                    </div>
                ))}
            </>
        );
    }
}

const mapStateToProps: TStateMapper<"reviews", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.reviews;
    return {
        ...ownProps,
        selectedProduct: getWriteReviewFormSelectedProduct(state, ownProps),
        selectedVariant: getWriteReviewFormSelectedVariant(state, ownProps),
        writeReviewTemplate: getWriteReviewTemplateForForm(state, ownProps)
            .template,
    };
};

export const WriteReviewFormFields = connect(mapStateToProps)(
    WriteReviewFormFieldsComponent,
);

import React from "react";
import classNames from "classnames";
import { IProduct } from "../models/catalogue.interfaces";
import { IPrice } from "../models/prices.interfaces";
import { IWebPageURL, IMonths, isoMonths } from "../models/nominals";
import { FinancingPlans } from "../models/financing";
import { format, formatTermLength } from "../utils/format";
import { Dinero, getMonthlyPrice, getDinero } from "../utils/money";
import {
    PriceType,
    getProductPrice,
    isProductPriceDiscounted,
} from "../utils/catalogue";
import { ConfiguratorTypes } from "../constants";
import { CardFinancingModal } from "./CardFinancingModal";
import { Link } from "./Link";
import {
    filterFinancingPlans,
    getLongestFinancingPlan,
} from "../apps/checkout/utils";

interface IProps {
    price: IPrice;
    financingPlans?: FinancingPlans;
    variant: IProduct;
    financingLink?: IWebPageURL | null;
    configuratorType?: ConfiguratorTypes;
    showFinancingModal?: boolean;
    overrideFinancingCopy?: string;
    extraClasses?: string;
    alternatePriceView?: boolean;
    openNewWindow?: boolean;
}

interface IState {}

export class ConfiguratorFinancing extends React.PureComponent<IProps, IState> {
    private getLongestActivePlan() {
        if (this.props.financingPlans) {
            // Before retrieving the longest active financing plan, narrow down the selection to include only plans with a 0% APR
            const filteredFinancingPlans = filterFinancingPlans(
                this.props.financingPlans,
            );
            return getLongestFinancingPlan(filteredFinancingPlans);
        }
        return null;
    }

    render() {
        // Do not show financing in Configurator for products that are marked as in-store only
        if (
            this.props.variant.attributes.in_store_only &&
            this.props.variant.attributes.in_store_only.value
        ) {
            return null;
        }
        const totalPrice = getProductPrice(this.props.price, {
            priceType: PriceType.COSMETIC_EXCL_TAX,
            includePostDiscountAddons: true,
            quantity: 1,
        });
        if (!this.props.price.per_month_term_length) {
            if (!this.props.showFinancingModal) {
                return null;
            }
            return (
                <CardFinancingModal
                    price={totalPrice}
                    title={this.props.variant.title}
                    linkText={gettext(
                        "Special financing available with qualifying order",
                    )}
                />
            );
        }
        if (this.props.overrideFinancingCopy) {
            return (
                <span className="configurator__financing">
                    <Link
                        className="configurator__financing-link"
                        href={
                            this.props.financingLink
                                ? this.props.financingLink
                                : null
                        }
                        target={!this.props.openNewWindow ? "_self" : "_blank"}
                    >
                        <span className="configurator__financing--underline">
                            {this.props.overrideFinancingCopy}
                        </span>
                    </Link>
                </span>
            );
        }
        // As products are often not present in the basket when viewing their PDPs, we cannot directly utilize the financing details associated with
        // the variant's price object, as is done during the checkout process, for product-specific financing advertisement purposes. Therefore,
        // our initial step is to attempt to obtain the longest active financing option based on only the variant, rather than relying on the current basket status.
        let perMonthTermLength: IMonths | null | undefined;
        let pricePerMonth: Dinero | null | undefined;
        const longestActivePlan = this.getLongestActivePlan();
        if (
            longestActivePlan &&
            isoMonths.unwrap(longestActivePlan.term_months) > 0
        ) {
            perMonthTermLength = longestActivePlan.term_months;
            pricePerMonth = getMonthlyPrice(totalPrice, perMonthTermLength);
        } else {
            const hasVariantPriceFinancing =
                this.props.variant.price.enable_per_month_pricing &&
                this.props.variant.price.per_month &&
                this.props.variant.price.per_month_term_length;
            perMonthTermLength = hasVariantPriceFinancing
                ? this.props.variant.price.per_month_term_length
                : this.props.price.per_month_term_length;
            pricePerMonth = hasVariantPriceFinancing
                ? getDinero(this.props.variant.price.per_month)
                : getMonthlyPrice(
                      totalPrice,
                      this.props.price.per_month_term_length,
                  );
        }
        // Build class names for financing
        const financingClasses = classNames({
            "configurator__financing": true,
            [`configurator__financing--${this.props.extraClasses}`]:
                !!this.props.extraClasses,
            [`configurator__financing--${this.props.configuratorType}`]: true,
            "configurator__financing--alternate": this.props.alternatePriceView,
        });
        const financingOrClasses = classNames({
            "configurator__financing-or": true,
            "configurator__financing-or--alternate":
                this.props.alternatePriceView,
        });
        const financingLinkClasses = classNames({
            "configurator__financing-link": true,
            "configurator__financing-link--discounted":
                isProductPriceDiscounted(this.props.price),
            "configurator__financing-link--alternate":
                this.props.alternatePriceView,
        });
        const financingPaymentsClasses = classNames({
            "configurator__financing-payments": true,
            "configurator__financing-payments--alternate":
                this.props.alternatePriceView,
        });
        const financingContainerClasses = classNames({
            "configurator__price--financing": true,
            [`configurator__price--financing-${this.props.configuratorType}`]:
                true,
            "configurator__price--financing-alternate":
                this.props.alternatePriceView,
            "no-financing-text":
                (!this.props.price.per_month ||
                    !this.props.price.per_month_term_length) &&
                !this.props.showFinancingModal,
        });
        return (
            <>
                <div className={financingContainerClasses}>
                    <span className={financingOrClasses}>or </span>
                    <span className={financingClasses}>
                        <Link
                            className={financingLinkClasses}
                            href={
                                this.props.financingLink
                                    ? this.props.financingLink
                                    : null
                            }
                            target={
                                !this.props.openNewWindow ? "_top" : "_blank"
                            }
                        >
                            <span className="configurator__financing--underline">
                                {format.money(pricePerMonth)}/mo.
                            </span>
                            <sup>2</sup>{" "}
                            {this.props.alternatePriceView && <br />}
                            for{" "}
                            <span className="configurator__financing--underline">
                                {perMonthTermLength
                                    ? formatTermLength(perMonthTermLength)
                                    : ""}{" "}
                                months
                            </span>
                            <sup>1</sup> <br />
                            <span className={financingPaymentsClasses}>
                                (total payments of {format.money(totalPrice)})
                            </span>
                        </Link>
                    </span>
                </div>
            </>
        );
    }
}

import React from "react";
import classNames from "classnames";
import { connect } from "react-redux";
import { TStateMapper } from "../../reducers.interfaces";
import { IReduxFormState } from "../reducers.interfaces";
import { Step } from "../constants";

interface IOwnProps {
    onEdit?: (event?: React.MouseEvent<HTMLElement>) => void;
}

interface IReduxProps {
    form: IReduxFormState;
    showSummary: boolean;
}

interface IDispatchProps {}

interface IProps extends IOwnProps, IReduxProps, IDispatchProps {}

interface IState {}

class BillingAddressSummaryComponent extends React.Component<IProps, IState> {
    render() {
        const summaryClasses = classNames({
            "checkout-step__summary": true,
            "checkout-step__summary--collapsed": !this.props.showSummary,
        });
        return (
            <div className={summaryClasses}>
                <h3 className="checkout-step__label">{gettext("Bill to:")}</h3>
                <div className="checkout-step__billing-address">
                    {this.props.form.billing_first_name}{" "}
                    {this.props.form.billing_last_name}
                    <br />
                    {this.props.form.billing_line1}
                    <br />
                    {this.props.form.billing_line2 ? (
                        <div>{this.props.form.billing_line2}</div>
                    ) : null}
                    {this.props.form.billing_line4},{" "}
                    {this.props.form.billing_state}{" "}
                    {this.props.form.billing_postcode}
                    <br />
                    {this.props.form.billing_phone_number}
                </div>
                {this.props.onEdit && (
                    <a
                        className="checkout-step__back"
                        href="#"
                        onClick={this.props.onEdit}
                    >
                        {gettext("Edit")}
                    </a>
                )}
            </div>
        );
    }
}

const mapStateToProps: TStateMapper<"checkout", IReduxProps, IOwnProps> = (
    rootState,
    ownProps,
) => {
    const state = rootState.checkout;
    return {
        form: state.form,
        showSummary: state.form.current_step > Step.BILLING_ADDRESS,
        ...ownProps,
    };
};

export const BillingAddressSummary = connect(mapStateToProps)(
    BillingAddressSummaryComponent,
);

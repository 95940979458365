import React from "react";
import Modal from "react-modal";

// import SVG from 'react-inlinesvg';
// import classNames from 'classnames';

interface IProps {
    isOpen: boolean;
    onClose: () => void;
}

interface IState {}

export class SurveyModalThanks extends React.Component<IProps, IState> {
    render() {
        return (
            <Modal
                aria={{
                    modal: true,
                }}
                style={{
                    content: {
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        maxHeight: "95%",
                        maxWidth: "542px",
                        width: "96%",
                        overflow: "auto",
                        padding: "40px",
                    },
                }}
                contentLabel="Survey"
                portalClassName="locator"
                className="locations store-locator-survey modal"
                isOpen={this.props.isOpen}
                onRequestClose={this.props.onClose}
                role="dialog"
            >
                <div className="modal-inner">
                    <div
                        aria-label={gettext("Close")}
                        className="modal-close button--ghost"
                        onClick={this.props.onClose}
                    >
                        <div className="pdp-modal__close-icon modal-close__icon"></div>
                    </div>
                    <div className="send__content survey__carousel">
                        <div className="survey-carousel-cell">
                            <h3
                                dangerouslySetInnerHTML={{
                                    __html: gettext(
                                        "Your directions<br />are on the way…",
                                    ),
                                }}
                            ></h3>
                            <p className="modal-intro">
                                {gettext("Thank you.")}
                                <br />
                                <br />
                                <button className="survey-taken">
                                    {gettext("Continue to Results")}
                                </button>
                            </p>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

import update from "immutability-helper";
import { produce } from "immer";
import {
    ReducersMapObject,
    ThunkDispatch,
    UnknownAction,
    Dispatch,
} from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { createTransform } from "redux-persist";
import { IRootState } from "./reducers.interfaces";

// Defaults and constants
import { Step } from "./checkout/constants";
import { defaults as checkoutDefaults } from "./checkout/defaults";

// Common Reducers
import { reducers as checkoutReducers } from "./checkout/reducers";
import { reducers as commonReducers } from "./common/reducers";
import { reducers as compareReducers } from "./product-compare/reducers";
import { reducers as gridReducers } from "./product-grid2/reducers";
import { reducers as configuratorReducers } from "./configurator/reducers";
import { reducers as csrReducers } from "./csr/reducers";
import { reducers as mattressmatchReducers } from "./mattress-match/reducers";
import { financingSlice, financingDefaults } from "./financing";
import { reducers as retailReducers } from "./retail/reducers";
import { reducers as reviewsReducers } from "./reviews/reducer";

// Combine reducers into map
export const coreReducers: ReducersMapObject<IRootState> = {
    checkout: checkoutReducers,
    common: commonReducers,
    configurator: configuratorReducers,
    csr: csrReducers,
    mattressmatch: mattressmatchReducers,
    financing: financingSlice.reducer,
    productcompare: compareReducers,
    productgrid2: gridReducers,
    retail: retailReducers,
    reviews: reviewsReducers,
};

export type AppDispatch = ThunkDispatch<IRootState, undefined, UnknownAction> &
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    Dispatch<any>;

// Hooks
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<IRootState> = useSelector;

// Persist config
export const sanitizeCommonData = createTransform(
    (state: IRootState["common"]) => {
        return {
            ...state,
            user: null,
        };
    },
    (state: IRootState["common"]) => {
        return state;
    },
    {
        whitelist: ["common"],
    },
);

export const sanitizeCheckoutData = createTransform(
    (state: IRootState["checkout"]) => {
        state = update(state, {
            data: {
                assisted_user: { $set: null },
                order: { $set: null },
                deferredPaymentOrder: { $set: null },
            },
            form: {
                payment_methods: {
                    $set: checkoutDefaults.form.payment_methods,
                },
            },
            gifts: { $set: checkoutDefaults.gifts },
        });
        return state;
    },
    (state: IRootState["checkout"]) => {
        if (state.form.current_step >= Step.PLACING_ORDER) {
            state.form.current_step = Step.PAYMENT_METHODS;
        }
        return state;
    },
    {
        whitelist: ["checkout"],
    },
);

export const resetFinancingData = createTransform(
    (state: IRootState["financing"]) => {
        return produce(state, (s) => {
            s.modal = financingDefaults.modal;
            s.preQualApproval = financingDefaults.preQualApproval;
        });
    },
    (state: IRootState["financing"]) => {
        return state;
    },
    {
        whitelist: ["financing"],
    },
);

import React from "react";
import SVG from "react-inlinesvg";
import classNames from "classnames";
import { IViewport, BreakPoint } from "../../../models/screen.interfaces";
import {
    IProductCategoryID,
    IProductID,
    ISafeHTML,
    isoSafeHTML,
    IWebPageURL,
} from "../../../models/nominals";
import { Image } from "../../../common/Image";
import { RichText } from "../../../common/RichText";
import { SizeSelector } from "../containers/ProductCompareSizeSelector";
import { ProductCompareTileHeader } from "../elements/ProductCompareTileHeader";
import { ProductCompareTileCTA } from "../elements/ProductCompareTileCTA";
import { getSelectedVariant } from "../selectors";
import {
    IAttributeOptionGroup,
    ICompareTile,
    IProductTileAttributeRow,
} from "../../../models/product-compare.interfaces";
import { ITileID, isoTileID } from "../../../models/product-compare";
import { ProductCompareTileAttributeRow } from "../elements/ProductCompareTileAttributeRow";
import { ProductCompareTheme } from "../../../constants";
import { setShowStartingAt } from "../utility";
import { IProduct } from "../../../models/catalogue.interfaces";
import { loadCategory } from "../../../api/products";

import styles from "./ProductCompareGrid.module.scss";
import iconCircleClose from "../../../../img/icons/circle-close.svg";
import iconCarouselArrowLeft from "../../../../img/icons/carousel-arrow-left.svg";
import iconCarouselArrowRight from "../../../../img/icons/carousel-arrow-right.svg";
import emptyState from "../../../../img/product-compare/empty-state.jpg";

interface IProps {
    header: ISafeHTML;
    tiles: ICompareTile[];
    basketLink: IWebPageURL;
    financingLink: IWebPageURL;
    preselectedSize: string | null;
    sizeAttributeOptionGroup: IAttributeOptionGroup | null;
    priceSelectValue: string;
    pageRootProduct: IProductID | null;
    theme: ProductCompareTheme;
    urlParams: URLSearchParams;
    viewport: IViewport;
    showFirstAvailablePrice?: boolean;
    showRating?: boolean;
    showStartingAt?: boolean;
    rootProducts: IProduct[];
    setRootProducts: (rootProducts: IProduct[]) => void;
    isMinimal?: boolean;
}

interface IState {
    currentPosition: number;
    selectedTileIDs: ITileID[];
    selectedTiles: ICompareTile[];
    tilesPerSlide: number;
    hideProductHeaderRow: boolean;
    accordionRows: {
        [id: number]: boolean;
    };
}

export class ProductCompareGrid extends React.Component<IProps, IState> {
    public state: IState = {
        currentPosition: 0,
        selectedTileIDs: [],
        selectedTiles: this.props.tiles,
        tilesPerSlide: 5,
        hideProductHeaderRow: true,
        accordionRows: {},
    };

    private readonly compareTiles = React.createRef<HTMLDivElement>();

    componentDidMount() {
        if (this.props.urlParams.get("tiles")) {
            this.buildSelectedTiles();
        } else {
            const tileIDs = this.props.tiles.map(
                (tile: ICompareTile) => tile.product_compare_tile.id,
            );

            const products = this.props.tiles.map((tile: ICompareTile) => {
                const { category_id } = tile.product_compare_tile;
                if (category_id) {
                    return this.loadProducts(
                        tile.product_compare_tile.category_id,
                    );
                }
                return Promise.reject(
                    `Missing product category for the product compare tile ${tile.product_compare_tile.name}`,
                );
            });

            if (products.length > 0) {
                Promise.all(products)
                    .then((rootProducts) => {
                        this.props.setRootProducts(rootProducts.flat());
                    })
                    .catch((err) => {
                        console.warn(err);
                    });
            }

            this.setState({
                selectedTileIDs: tileIDs,
            });
        }

        this.setAccordionRows();

        this.calculateTilesPerSlide();
        window.addEventListener("resize", this.calculateTilesPerSlide);
        window.addEventListener("scroll", this.handleProductHeaderVisibility);
    }

    private async loadProducts(productCategoryID: IProductCategoryID) {
        return await loadCategory(productCategoryID);
    }

    private readonly handleProductHeaderVisibility = () => {
        const targetLine = this.compareTiles.current;
        if (targetLine) {
            this.setState({
                hideProductHeaderRow: targetLine.getClientRects()[0].bottom > 0,
            });
        }
    };

    private readonly onAccordionTriggerClick = (
        event: React.MouseEvent<HTMLElement>,
        rowID: number,
    ) => {
        event.preventDefault();

        const accordionRows = this.state.accordionRows;
        accordionRows[rowID] = !this.state.accordionRows[rowID];

        this.setState({
            accordionRows: accordionRows,
        });
    };

    private readonly onRemoveButtonClick = (
        e: React.MouseEvent<HTMLElement>,
        tileID: ITileID,
    ) => {
        e.preventDefault();

        const newTileIDs = this.state.selectedTileIDs.filter(
            (id) => id !== tileID,
        );
        const newTiles: ICompareTile[] = [];

        this.state.selectedTiles.map((tile: ICompareTile) => {
            if (newTileIDs.includes(tile.product_compare_tile.id)) {
                newTiles.push(tile);
            }
        });

        this.setState({
            selectedTileIDs: newTileIDs,
            selectedTiles: newTiles,
        });

        if (this.props.urlParams.get("return_url")) {
            if (newTileIDs.length > 0) {
                this.props.urlParams.set("tiles", newTileIDs.join(","));
                const newURL = `${window.location.origin}${
                    window.location.pathname
                }?${this.props.urlParams.toString()}`;
                window.history.replaceState(history.state, "", newURL);
            } else {
                this.props.urlParams.delete("tiles");
                const newURL = `${window.location.origin}${
                    window.location.pathname
                }?${this.props.urlParams.toString()}`;
                window.history.replaceState(history.state, "", newURL);
            }
        }
    };

    private readonly onPrevSlideClick = () => {
        this.setState({
            currentPosition: this.state.currentPosition - 1,
        });
    };

    private readonly onNextSlideClick = () => {
        this.setState({
            currentPosition: this.state.currentPosition + 1,
        });
    };

    private readonly calculateTilesPerSlide = () => {
        if (this.props.viewport.width > BreakPoint.LARGE) {
            this.setState({
                tilesPerSlide: 5,
            });
        }

        if (this.props.viewport.width <= BreakPoint.LARGE) {
            this.setState({
                tilesPerSlide: 2,
            });
        }
    };

    private buildSelectedTiles() {
        const selectedTileIDs = decodeURIComponent(
            this.props.urlParams.get("tiles") || "",
        )
            .split(",")
            .map(Number)
            .map(isoTileID.wrap);
        const selectedTiles: ICompareTile[] = [];

        this.props.tiles.map((tile: ICompareTile) => {
            if (selectedTileIDs.includes(tile.product_compare_tile.id)) {
                selectedTiles.push(tile);
            }
        });

        this.setState({
            selectedTileIDs: selectedTileIDs,
            selectedTiles: selectedTiles,
        });
    }

    private setAccordionRows() {
        const accordionRows = this.state.accordionRows;
        if (this.state.selectedTiles.length > 0) {
            this.state.selectedTiles[0].rows.map(
                (row: IProductTileAttributeRow) => {
                    accordionRows[row.row_type.id] = true;
                },
            );
        }
        this.setState({
            accordionRows: accordionRows,
        });
    }

    private buildViewableTiles(): ICompareTile[] {
        const increment = this.state.currentPosition + this.state.tilesPerSlide;

        if (
            this.state.selectedTiles.length - this.state.currentPosition ===
            1
        ) {
            return this.state.selectedTiles.slice(
                this.state.currentPosition - 1,
                increment,
            );
        }
        return this.state.selectedTiles.slice(
            this.state.currentPosition,
            increment,
        );
    }

    private buildCompareTiles() {
        const viewableTiles = this.buildViewableTiles();

        return viewableTiles.map((tile: ICompareTile) => {
            const variant = getSelectedVariant(
                tile,
                this.props.priceSelectValue,
            );
            const showDescription =
                this.props.theme !== ProductCompareTheme.STEP_UP &&
                this.props.theme !== ProductCompareTheme.SELECT
                    ? false
                    : true;

            const tileClasses = classNames({
                tile: true,
                [styles.tile]: this.props.theme === ProductCompareTheme.DEFAULT,
                [styles.tileThemeSelect]:
                    this.props.theme === ProductCompareTheme.SELECT,
                [styles.tileThemeStepUp]:
                    this.props.theme === ProductCompareTheme.STEP_UP,
            });
            return (
                <div
                    aria-label="product"
                    role="group"
                    className={tileClasses}
                    key={`${tile.product_compare_tile.id}`}
                >
                    {this.props.urlParams.get("return_url") &&
                        !this.props.isMinimal && (
                            <button
                                type="button"
                                className={`${styles.xButton} al-compareresults-xoutproduct`}
                                onClick={(e) => {
                                    this.onRemoveButtonClick(
                                        e,
                                        tile.product_compare_tile.id,
                                    );
                                }}
                            >
                                <SVG
                                    className="basket-savings__title-icon"
                                    aria-hidden="true"
                                    src={iconCircleClose}
                                />
                                <span className="ada-screenreader-only">
                                    {gettext("Remove product from compare")}
                                </span>
                            </button>
                        )}
                    <ProductCompareTileHeader
                        data={tile}
                        selectedVariant={variant}
                        pageRootProduct={this.props.pageRootProduct}
                        theme={this.props.theme}
                        showDescription={showDescription}
                        showRating={
                            this.props.showRating !== undefined
                                ? this.props.showRating
                                : true
                        }
                    />
                    {this.props.theme !== ProductCompareTheme.STEP_UP &&
                        this.props.theme !== ProductCompareTheme.SELECT && (
                            <ProductCompareTileCTA
                                data={tile}
                                selectedVariant={variant}
                                financingLink={this.props.financingLink}
                                showStartingAt={
                                    this.props.showStartingAt
                                        ? this.props.showStartingAt
                                        : setShowStartingAt(
                                              tile,
                                              this.props
                                                  .sizeAttributeOptionGroup,
                                          )
                                }
                                theme={this.props.theme}
                            />
                        )}
                </div>
            );
        });
    }

    private buildAttributeRows() {
        return this.state.selectedTiles[0].rows.map(
            (row: IProductTileAttributeRow) => {
                const rowCounter =
                    this.state.selectedTiles[0].rows.indexOf(row);
                const attributeRowContainerClasses = classNames({
                    [styles.attributeRowContainer]: true,
                    [styles.attributeRowContainerThemeStepUp]:
                        this.props.theme === ProductCompareTheme.STEP_UP,
                    [styles.richtext]: row.row_type.row_type === "richtext",
                    [styles.attributeGrid]:
                        row.row_type.row_type === "attribute-grid",
                    [styles.attributeOptionList]:
                        row.row_type.row_type === "attribute-option-list",
                    [styles.shopNow]: row.row_type.row_type === "shop-now",
                    [styles.microconfigurator]:
                        row.row_type.row_type === "microconfigurator",
                    [styles.productSelect]:
                        row.row_type.row_type === "product-select",
                    [styles.upgrade]: row.row_type.row_type === "upgrade",
                    [styles.dynamicPrice]:
                        row.row_type.row_type === "dynamic-price",
                });
                const accordionButtonClasses = classNames({
                    "accordion": true,
                    "accordion--is-active":
                        this.state.accordionRows[row.row_type.id],
                    [styles.accordionRowTriggerButton]: true,
                });
                const accordionTargetClasses = classNames({
                    "accordion-target": true,
                    "accordion-target--is-active":
                        this.state.accordionRows[row.row_type.id],
                });

                if (
                    this.props.theme === ProductCompareTheme.STEP_UP ||
                    this.props.theme === ProductCompareTheme.SELECT
                ) {
                    return (
                        <div
                            key={row.row_type.id}
                            className={attributeRowContainerClasses}
                        >
                            <h4>{row.row_type.title}</h4>
                            <div>{this.buildAttributeRow(rowCounter)}</div>
                        </div>
                    );
                }

                return (
                    <div
                        key={row.row_type.id}
                        className={attributeRowContainerClasses}
                    >
                        <button
                            className={accordionButtonClasses}
                            aria-expanded={
                                this.state.accordionRows[row.row_type.id]
                            }
                            onClick={(e) => {
                                this.onAccordionTriggerClick(
                                    e,
                                    row.row_type.id,
                                );
                            }}
                        >
                            <h4>{row.row_type.title}</h4>
                        </button>
                        <div
                            className={accordionTargetClasses}
                            aria-hidden={
                                !this.state.accordionRows[row.row_type.id]
                            }
                        >
                            {this.buildAttributeRow(rowCounter)}
                            {this.buildChildRows(row.children)}
                        </div>
                    </div>
                );
            },
        );
    }

    private buildChildRows(rows: IProductTileAttributeRow[]) {
        return rows.map((row: IProductTileAttributeRow) => {
            const attributeRowContainerClasses = classNames({
                [styles.childRow]: true,
                [styles.attributeRowContainer]: true,
                [styles.richtext]: row.row_type.row_type === "richtext",
                [styles.attributeGrid]:
                    row.row_type.row_type === "attribute-grid",
                [styles.attributeOptionList]:
                    row.row_type.row_type === "attribute-option-list",
                [styles.shopNow]: row.row_type.row_type === "shop-now",
                [styles.microconfigurator]:
                    row.row_type.row_type === "microconfigurator",
                [styles.productSelect]:
                    row.row_type.row_type === "product-select",
                [styles.upgrade]: row.row_type.row_type === "upgrade",
                [styles.dynamicPrice]:
                    row.row_type.row_type === "dynamic-price",
            });

            return (
                <div
                    className={attributeRowContainerClasses}
                    key={`${row.row_type.id}-child-row`}
                >
                    {this.buildChildRow(row)}
                </div>
            );
        });
    }

    private buildChildRow(row: IProductTileAttributeRow) {
        const viewableTiles = this.buildViewableTiles();
        return viewableTiles.map((tile: ICompareTile) => (
            <ProductCompareTileAttributeRow
                key={`${tile.product_compare_tile.id}-${row.row_type.id}`}
                row={row}
                data={tile}
                theme={this.props.theme}
                basketLink={this.props.basketLink}
                financingLink={this.props.financingLink}
                priceSelectValue={this.props.priceSelectValue}
                rootProducts={this.props.rootProducts}
                showStartingAt={this.props.showStartingAt}
                sizeAttributeOptionGroup={this.props.sizeAttributeOptionGroup}
            />
        ));
    }

    private buildAttributeRow(rowCounter: number) {
        const viewableTiles = this.buildViewableTiles();
        return viewableTiles.map((tile: ICompareTile) => (
            <ProductCompareTileAttributeRow
                key={`${tile.product_compare_tile.id}-${tile.rows[rowCounter].row_type.id}`}
                row={tile.rows[rowCounter]}
                data={tile}
                theme={this.props.theme}
                basketLink={this.props.basketLink}
                financingLink={this.props.financingLink}
                priceSelectValue={this.props.priceSelectValue}
                showFirstAvailablePrice={this.props.showFirstAvailablePrice}
                rootProducts={this.props.rootProducts}
                showStartingAt={this.props.showStartingAt}
                sizeAttributeOptionGroup={this.props.sizeAttributeOptionGroup}
            />
        ));
    }

    private buildHeaderContent(): ISafeHTML {
        let href = decodeURIComponent(
            this.props.urlParams.get("return_url") || "",
        );
        if (href && !this.props.isMinimal) {
            if (this.props.urlParams.get("tiles")) {
                href = `${href}?tiles=${encodeURIComponent(
                    this.state.selectedTileIDs.join(","),
                )}`;
            }
            const link = `<a class="al-compareresults-changeselection" href="${href}">${gettext(
                "Add/Change Selection",
            )}</a>`;
            return isoSafeHTML.wrap(`${this.props.header}${link}`);
        }
        return this.props.header;
    }

    private readonly buildStickyProductHeaderRow = () => {
        const viewableTiles = this.buildViewableTiles();

        return viewableTiles.map((tile: ICompareTile) => {
            return (
                <div
                    className={styles.stickyHeaderTile}
                    key={`header-row-${tile.product_compare_tile.id}`}
                >
                    <RichText html={tile.product_compare_tile.title} />
                </div>
            );
        });
    };

    private readonly buildShopMattressesLink = () => {
        const href = decodeURIComponent(
            this.props.urlParams.get("return_url") || "",
        );

        return (
            <a href={href} className="button">
                Shop Mattresses
            </a>
        );
    };

    private renderSlideControls() {
        if (this.props.viewport.width > BreakPoint.LARGE) {
            return null;
        }
        if (this.state.selectedTiles.length <= 2) {
            return null;
        }
        return (
            <nav className={styles.nav}>
                <button
                    aria-label={gettext("Previous")}
                    disabled={this.state.currentPosition === 0}
                    onClick={this.onPrevSlideClick}
                >
                    <SVG aria-hidden="true" src={iconCarouselArrowLeft} />
                </button>
                <button
                    aria-label={gettext("next")}
                    disabled={
                        this.state.currentPosition + this.state.tilesPerSlide >=
                        this.state.selectedTiles.length
                    }
                    onClick={this.onNextSlideClick}
                >
                    <SVG aria-hidden="true" src={iconCarouselArrowRight} />
                </button>
            </nav>
        );
    }

    componentDidUpdate() {
        // check if tiles are being removed from the end of the array
        if (
            this.state.selectedTiles.length - this.state.currentPosition ===
            1
        ) {
            // if they are, update this.state.currentPosition
            this.setState({
                currentPosition: this.state.currentPosition - 1,
            });
        }
    }

    render() {
        const rootClasses = classNames({
            [styles.root]: true,
            [styles.rootThemeCompareAndSelect]:
                this.props.theme === ProductCompareTheme.SELECT,
        });
        const headerClasses = classNames({
            [styles.header]: true,
            [styles.headerThemeStepUp]:
                this.props.theme === ProductCompareTheme.STEP_UP,
        });
        const productHeaderRowClasses = classNames({
            "product-header-row": true,
            [styles.productHeaderRow]:
                this.props.theme === ProductCompareTheme.DEFAULT,
            [styles.productHeaderRowThemeSelect]:
                this.props.theme === ProductCompareTheme.SELECT,
            [styles.productHeaderRowThemeStepUp]:
                this.props.theme === ProductCompareTheme.STEP_UP,
        });

        const controlsClasses = classNames({
            [styles.controls]: true,
            ["hidden"]: this.props.isMinimal,
        });

        if (this.state.selectedTiles.length > 0) {
            return (
                <div className={rootClasses}>
                    <header className={headerClasses}>
                        <RichText html={this.buildHeaderContent()} />
                    </header>
                    {this.renderSlideControls()}
                    {this.props.theme !== ProductCompareTheme.STEP_UP &&
                        this.props.theme !== ProductCompareTheme.SELECT && (
                            <div className={controlsClasses}>
                                {this.props.sizeAttributeOptionGroup && (
                                    <SizeSelector
                                        sizeAttributeOptionGroup={
                                            this.props.sizeAttributeOptionGroup
                                        }
                                        preselectedSize={
                                            this.props.preselectedSize
                                        }
                                        showSizeSelector={!this.props.isMinimal}
                                    />
                                )}
                                <button
                                    onClick={() => {
                                        window.print();
                                    }}
                                >
                                    <span>Print Comparison</span>
                                </button>
                            </div>
                        )}
                    <div
                        className={productHeaderRowClasses}
                        aria-hidden={this.state.hideProductHeaderRow}
                    >
                        {this.buildStickyProductHeaderRow()}
                    </div>
                    <div className={styles.tiles} ref={this.compareTiles}>
                        {this.buildCompareTiles()}
                    </div>
                    {this.buildAttributeRows()}
                </div>
            );
        } else {
            return (
                <div className={styles.root}>
                    <header className={styles.header}>
                        <RichText html={this.buildHeaderContent()} />
                    </header>
                    <div className={styles.emptyState}>
                        <Image src={emptyState} alt="" />
                        <h3>
                            You currently do not have
                            <br />
                            any mattresses to compare
                        </h3>
                        {this.buildShopMattressesLink()}
                    </div>
                </div>
            );
        }
    }
}

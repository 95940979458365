import * as t from "io-ts";
import { pipe } from "fp-ts/lib/function";
import { chain } from "fp-ts/lib/Either";
import { NumberFromString } from "io-ts-types/lib/NumberFromString";
import { PartialRecord, nullable, StringFromBoolean } from "./utils";
import {
    IProductAPIURL,
    ProductID,
    ProductUUID,
    ProductUPC,
    ProductSKU,
    ProductAPIURL,
    ProductListAPIURL,
    ProductClassAPIURL,
    ProductCategoryID,
    ProductCategoryAPIURL,
    ProductBundlesListAPIURL,
    ProductImageID,
    ReviewsBrandID,
    ReviewsProductTypeID,
    ReviewsProductID,
    ReviewsProductVariantID,
    ImageURL,
    SafeHTML,
    WebPageURL,
    BasketLineAPIURL,
    isoProductClassAPIURL,
    isoProductCategoryAPIURL,
    isoProductCategoryID,
    isoImageURL,
} from "./nominals";
import { Price } from "./prices";
import { sortProductVariants } from "../utils/sorting";
import { notEmpty } from "../utils/functional";
import defaultSquare from "../../img/products/default-square.png";

// ============================================================================
// Product Options
// ============================================================================
export const MattressSize = t.keyof({
    "Twin": null,
    "Twin Long": null,
    "Double": null,
    "Queen": null,
    "King": null,
    "Split King": null,
    "CA King": null,
    "Split CA King": null,
    "Select Size": null,
});

export const OptionCode = t.keyof({
    option_advanced_pressure_relief: null,
    option_advanced_support: null,
    option_chill: null,
    option_color: null,
    option_color_hex: null,
    option_coolingfeel: null,
    option_feel: null,
    option_height: null,
    option_level: null,
    option_model: null,
    option_profile: null,
    option_size: null,
    option_top: null,
});

export const OptionSingleValue = t.string;
export const OptionMultiValue = t.array(OptionSingleValue);
export const OptionValue = t.union([OptionSingleValue, OptionMultiValue]);
export const OptionValues = PartialRecord(OptionCode, OptionValue);

export const OptionInputType = t.keyof({
    "dropdown": null,
    "radio": null,
    "dropdown-with-pricing": null,
    "radio-with-pricing": null,
});

export const OptionLabel = t.interface({
    label: t.string,
    values: t.array(t.string),
});

export const OptionInputTypeSet = PartialRecord(OptionCode, OptionInputType);

export const OptionLabelSet = PartialRecord(OptionCode, t.array(OptionLabel));

// ============================================================================
// Product Attributes
// ============================================================================
export const JSONAttributeValue = <T extends t.Mixed>(shape: T) => {
    const AttributeValue = new t.Type<t.TypeOf<T>, string, unknown>(
        "AttributeValue",
        shape.is,
        (val, context) => {
            return pipe(
                t.string.validate(val, context),
                chain((str) => {
                    try {
                        return t.success(JSON.parse(str));
                    } catch (e) {
                        return t.failure(str, context);
                    }
                }),
            );
        },
        (data) => {
            return JSON.stringify(data);
        },
    );
    return AttributeValue;
};

export const ProductCategory = t.interface({
    name: t.string,
    value: t.string,
});

export const ProductAttribute = <T extends t.Mixed>(valueCodec: T) => {
    return t.interface({
        name: t.string,
        code: t.string,
        value: valueCodec,
    });
};

export const JSONProductAttribute = <T extends t.Mixed>(valueCodec: T) => {
    return ProductAttribute(JSONAttributeValue(valueCodec));
};

export const StringAttribute = ProductAttribute(t.string);
export const NullableStringAttribute = ProductAttribute(nullable(t.string));
export const MultiStringAttribute = ProductAttribute(t.array(t.string));
export const SingleOrMultiStringAttribute = t.union([
    StringAttribute,
    MultiStringAttribute,
]);
export const NumberAttribute = ProductAttribute(
    t.union([t.number, NumberFromString]),
);
export const StringFromBooleanAttribute = ProductAttribute(StringFromBoolean);
export const BooleanAttribute = ProductAttribute(t.boolean);

export const ProductOptionsAttribute = JSONProductAttribute(
    t.readonlyArray(OptionCode),
);
export const ProductOptionInputTypesAttribute =
    JSONProductAttribute(OptionInputTypeSet);
export const ProductOptionLabelsAttribute =
    JSONProductAttribute(OptionLabelSet);

const AnyAttribute = t.union([
    StringAttribute,
    NullableStringAttribute,
    MultiStringAttribute,
    NumberAttribute,
    BooleanAttribute,
    ProductOptionsAttribute,
    ProductOptionInputTypesAttribute,
    ProductOptionLabelsAttribute,
]);

const KnownProductAttributes = t.partial({
    product_options: ProductOptionsAttribute,
    product_option_input_types: ProductOptionInputTypesAttribute,
    product_option_labels: ProductOptionLabelsAttribute,
    option_advanced_pressure_relief: StringFromBooleanAttribute,
    option_advanced_support: StringFromBooleanAttribute,
    option_chill: StringFromBooleanAttribute,
    option_color: StringAttribute,
    option_color_hex: StringAttribute,
    option_coolingfeel: StringAttribute,
    option_feel: SingleOrMultiStringAttribute,
    option_height: StringAttribute,
    option_level: StringAttribute,
    option_model: StringAttribute,
    option_profile: StringAttribute,
    option_size: StringAttribute,
    option_top: StringAttribute,

    // SKU sent to CCH when computing taxes
    cch_product_sku: StringAttribute,

    // Other misc product data
    // all_position_sleeper: BooleanAttribute,
    back_sleeper: BooleanAttribute,
    // best_seller: BooleanAttribute,
    brand_color: StringAttribute,
    // clearance: BooleanAttribute,
    // closeout: BooleanAttribute,
    // cooling: BooleanAttribute,
    feel: StringAttribute,
    // highest_rated: BooleanAttribute,
    in_store_only: BooleanAttribute,
    product_callout: StringAttribute,
    // product_model: StringAttribute,
    // promo_badge: StringAttribute,
    // side_sleeper: BooleanAttribute,
    // stomach_sleeper: BooleanAttribute,
    // travel: BooleanAttribute,
    // warranty: StringAttribute,
    upgrade_option_title: StringAttribute,
    firmness_rank: NumberAttribute,
    budget_rank: NumberAttribute,
    configurator_root_product_selector_icon: NullableStringAttribute,
});

const OtherProductAttributes = t.record(
    t.string,
    t.union([
        ProductAttribute(t.array(OptionCode)),
        ProductAttribute(OptionInputTypeSet),
        ProductAttribute(OptionLabelSet),
        StringAttribute,
        NullableStringAttribute,
        MultiStringAttribute,
        NumberAttribute,
        BooleanAttribute,
    ]),
);

export const ProductAttributes = t.intersection([
    KnownProductAttributes,
    OtherProductAttributes,
]);

export const ProductAttributesFromArray = new t.Type<
    t.TypeOf<typeof ProductAttributes>,
    string,
    unknown
>(
    "ProductAttributesFromArray",
    ProductAttributes.is,
    (val, context) => {
        return pipe(
            t.array(AnyAttribute).validate(val, context),
            chain((attrList) => {
                try {
                    const attrs: {
                        [code: string]: t.TypeOf<typeof AnyAttribute>;
                    } = {};
                    for (const attr of attrList) {
                        attrs[attr.code] = attr;
                    }
                    return ProductAttributes.validate(attrs, context);
                } catch (e) {
                    return t.failure(attrList, context);
                }
            }),
        );
    },
    (data) => {
        return JSON.stringify(Object.values(data));
    },
);

// ============================================================================
// Product Class
// ============================================================================
export const ProductClass = t.interface({
    id: t.number,
    url: ProductClassAPIURL,
    name: t.string,
    slug: t.string,
    requires_shipping: t.boolean,
    track_stock: t.boolean,
    products: t.string,
});
export const ProductClasses = t.array(ProductClass);

export const ProductAttributeWithDescription = t.interface({
    id: t.number,
    option: t.string,
    description: nullable(t.string),
    icon_image_url: nullable(ImageURL),
});

export const ProductAttributeOptionGroup = t.interface({
    id: t.number,
    name: t.string,
    options: t.array(ProductAttributeWithDescription),
});

export const ProductAttributeOptionGroups = t.interface({
    id: t.number,
    name: t.string,
    code: t.string,
    type: t.string,
    required: t.boolean,
    option_group: nullable(ProductAttributeOptionGroup),
});

/**
 * See serializer: tsicommon.api.serializers.catalogue.VerboseProductClassSerializer
 */
export const VerboseProductClass = t.intersection([
    ProductClass,
    t.interface({
        products: ProductListAPIURL,
        attributes: t.array(ProductAttributeOptionGroups),
    }),
]);

// ============================================================================
// Misc Product Sub Objects
// ============================================================================
export const ProductImageRole = t.keyof({
    "Product Tile": null,
    "Product Feed": null,
    "PLP Config": null,
    "Product Feed Additional": null,
});

export const Image = t.intersection([
    t.interface({
        role: ProductImageRole,
        original: ImageURL,
    }),
    t.partial({
        id: ProductImageID,
        caption: t.string,
        display_order: t.number,
        date_created: t.string,
        product: ProductID,
    }),
]);

export const ValueProp = t.intersection([
    t.interface({
        name: t.string,
        description: t.string,
    }),
    t.partial({
        image_url: ImageURL,
    }),
]);

export const ProductAvailability = t.intersection([
    t.interface({
        is_available_to_buy: t.boolean,
        code: t.string,
        short_message: t.string,
        message: t.string,
    }),
    t.partial({
        num_available: t.number,
    }),
]);

// ============================================================================
// Products. This is split into multiple types for a few reasons: (1) products
// are recursive (containing their parent and a list child children); (2) we add
// a few extra fields that aren't in the API, like the product_class_slug.
// ============================================================================
const APIBaseProduct = t.intersection([
    t.interface({
        id: ProductID,
        url: ProductAPIURL,
        product_class: ProductClassAPIURL,
        categories: t.array(ProductCategoryAPIURL),
        category_names: t.array(t.string),
        attributes: ProductAttributesFromArray,
        images: t.array(Image),
        price: Price,
        availability: ProductAvailability,
        title: t.string,
        slug: t.string,
        description: SafeHTML,
        bundles: ProductBundlesListAPIURL,
        link: nullable(WebPageURL),
        uuid: nullable(ProductUUID),
        upc: ProductUPC,
        rating: nullable(t.number),
        num_reviews: t.number,
        reviews_brand_id: nullable(ReviewsBrandID),
        reviews_product_type_id: nullable(ReviewsProductTypeID),
        reviews_product_id: nullable(ReviewsProductID),
        reviews_product_variant_ids: t.array(ReviewsProductVariantID),
        subtitle: nullable(t.string),
    }),
    t.partial({
        alternate_links: t.array(
            t.interface({
                type: t.string,
                link: WebPageURL,
            }),
        ),
        show_in_site_search: t.boolean,
        value_props: t.array(ValueProp),
        skus: t.array(ProductSKU),
        enable_predicted_delivery_date: t.boolean,
        date_created: t.string,
        date_updated: t.string,
        promo_callout: nullable(t.string),
        // Attributes for cache TTL debugging
        _cache_now: t.number,
        _cache_expires: t.number,
        _cache_built: t.number,
    }),
]);

const BaseProduct = t.intersection([
    APIBaseProduct,
    t.interface({
        skus: t.array(ProductSKU),
        product_class_slug: t.string,
        category_ids: t.array(ProductCategoryID),
        enable_predicted_delivery_date: t.boolean,
    }),
]);

type APIBaseProduct = t.TypeOf<typeof APIBaseProduct>;
type BaseProduct = t.TypeOf<typeof BaseProduct>;

interface APIProduct extends APIBaseProduct {
    parent: APIProduct | IProductAPIURL | null;
    children: APIProduct[];
}

export interface InternalProduct extends BaseProduct {
    parent: InternalProduct | null;
    children: InternalProduct[];
}

const APIProduct: t.Type<APIProduct> = t.recursion("APIProduct", () => {
    const _type: t.Type<APIProduct> = t.intersection([
        APIBaseProduct,
        t.type({
            parent: t.union([APIProduct, ProductAPIURL, t.null]),
            children: t.array(APIProduct),
        }),
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    ]) as any;
    return _type;
});

const InternalProduct: t.Type<InternalProduct> = t.recursion(
    "InternalProduct",
    () => {
        const _type: t.Type<InternalProduct> = t.intersection([
            BaseProduct,
            t.type({
                parent: nullable(InternalProduct),
                children: t.array(InternalProduct),
            }),
            // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        ]) as any;
        return _type;
    },
);

const convertProductFromAPI = (apiProduct: APIProduct): InternalProduct => {
    // Build parent
    const parent: InternalProduct["parent"] =
        apiProduct.parent && !ProductAPIURL.is(apiProduct.parent)
            ? convertProductFromAPI(apiProduct.parent)
            : null;
    // Get the product class slug
    const productClassSlug = isoProductClassAPIURL
        .unwrap(apiProduct.product_class)
        .split("/")
        .slice(-2)[0];
    // Always use the parent's rating
    const rating = parent ? parent.rating : apiProduct.rating;
    // Add dummy image if needed
    const hasDirectImgs = apiProduct.images && apiProduct.images.length;
    const hasParentImgs = parent && parent.images && parent.images.length > 0;
    const images: InternalProduct["images"] = apiProduct.images;
    if (!hasDirectImgs && !hasParentImgs) {
        images.push({
            original: isoImageURL.wrap(defaultSquare),
            role: "Product Tile",
        });
    }
    // Get category IDs
    const category_ids = apiProduct.categories
        .map((categoryURL) => {
            const groups = isoProductCategoryAPIURL
                .unwrap(categoryURL)
                .match(/\/(\d+)\/$/);
            return groups
                ? isoProductCategoryID.wrap(parseInt(groups[1], 10))
                : null;
        })
        .filter(notEmpty);
    // Build output product
    const product: InternalProduct = {
        ...apiProduct,
        product_class_slug: productClassSlug,
        rating: rating,
        images: images,
        parent: parent,
        children: apiProduct.children.map(convertProductFromAPI),
        skus: apiProduct.skus || [],
        category_ids: category_ids,
        enable_predicted_delivery_date:
            apiProduct.enable_predicted_delivery_date || false,
    };
    // Sort the product's children
    product.children = sortProductVariants(product);
    return product;
};

export const Product = new t.Type<InternalProduct, InternalProduct, unknown>(
    "Product",
    InternalProduct.is,
    (val, context) => {
        return pipe(
            APIProduct.validate(val, context),
            chain((apiProduct) => {
                try {
                    const product = convertProductFromAPI(apiProduct);
                    return t.success(product);
                } catch (e) {
                    return t.failure(val, context);
                }
            }),
        );
    },
    (data) => data,
);
export const Products = t.array(Product);

// Shortened version of product data returned by some list endpoints.
export const ConciseProduct = t.intersection([
    t.interface({
        id: ProductID,
        url: ProductAPIURL,
        product_class: ProductClassAPIURL,
        categories: t.array(ProductCategoryAPIURL),
        category_names: t.array(t.string),
        title: t.string,
        slug: t.string,
        bundles: ProductBundlesListAPIURL,
        parent: nullable(ProductAPIURL),
        children: t.array(ProductAPIURL),
    }),
    t.partial({
        skus: t.array(ProductSKU),
        _cache_now: t.number,
        _cache_expires: t.number,
        _cache_built: t.number,
    }),
]);
export const ConciseProducts = t.array(ConciseProduct);

// ============================================================================
// Mattress Matches
// ============================================================================
export const MattressMatchPreferenceSet = t.interface({
    preferred_feel: t.string,
    height: t.string,
    weight: t.string,
    cooling: t.boolean,
    price_level: t.string,
});

export const MattressMatchResult = t.interface({
    product: Product,
    copy: t.string,
    image_url: t.string,
    order: t.number,
});

// Ensure that the array always contains exactly two items
export const MattressMatchResults = t.tuple([
    MattressMatchResult,
    MattressMatchResult,
]);

// ============================================================================
// Bundles
// ============================================================================
export const BundleGroup = t.interface({
    id: t.number,
    bundle_type: t.string,
    name: t.string,
    description: t.string,
    headline: t.string,
    image: nullable(ImageURL),
});

const AbstractConcreteBundle = <T extends t.Mixed>(SuggestedProduct: T) => {
    return t.interface({
        id: t.number,
        bundle_group: BundleGroup,
        triggering_product: ProductID,
        suggested_products: t.array(SuggestedProduct),
    });
};
export const APIConcreteBundle = AbstractConcreteBundle(ProductID);
export const APIConcreteBundles = t.array(APIConcreteBundle);
export const ConcreteBundle = AbstractConcreteBundle(Product);

const AbstractUserConfigurableBundle = <T extends t.Mixed>(
    SuggestedProduct: T,
) => {
    return t.interface({
        id: t.number,
        bundle_group: BundleGroup,
        triggering_product: ProductID,
        suggested_range: t.number,
        suggested_range_products: t.array(SuggestedProduct),
        quantity: t.number,
    });
};
export const APIUserConfigurableBundle =
    AbstractUserConfigurableBundle(ProductID);
export const APIUserConfigurableBundles = t.array(APIUserConfigurableBundle);
export const UserConfigurableBundle = AbstractUserConfigurableBundle(Product);

// ============================================================================
// Basket
// ============================================================================
export const BasketModificationLogEntry = t.interface({
    user_id: nullable(t.number),
    user_name: nullable(t.string),
    user_is_staff: nullable(t.boolean),
    total_excl_tax_before: t.string,
    total_excl_tax_after: t.string,
    message: t.string,
    action_time: t.string,
});

export const BasketLinkShippingMethod = t.interface({
    code: t.string,
    price: t.string,
    name: t.string,
    description: t.string,
});

export const BasketAdvertistingContent = t.interface({
    content: SafeHTML,
    group_priority: t.number,
    offer_priority: t.number,
});

export const BasketLineDiscount = t.interface({
    amount: t.string,
    offer_name: t.string,
    offer_description: t.string,
    voucher_name: nullable(t.string),
    voucher_code: nullable(t.string),
});

const APIBasketLine = t.intersection([
    t.interface({
        url: BasketLineAPIURL,
        product: Product,
        quantity: t.number,
        attributes: t.array(
            t.interface({
                url: t.string,
                option: t.string,
                value: t.string,
            }),
        ),
        price_currency: t.string,
        modification_log_entries: t.array(BasketModificationLogEntry),
        price_excl_tax: t.string,
        price_incl_tax: t.string,
        price_incl_tax_excl_discounts: t.string,
        price_excl_tax_excl_discounts: t.string,
        discount_value: t.string,
        discounts: t.array(BasketLineDiscount),
        line_price_excl_tax_incl_discounts: t.string,
        is_tax_known: t.boolean,
        warning: nullable(t.string),
        basket: t.string,
        stockrecord: t.string,
        date_created: t.string,
    }),
    t.partial({
        advertising_content: t.array(BasketAdvertistingContent),
    }),
]);

const InternalBasketLine = t.intersection([
    APIBasketLine,
    t.interface({
        bundles: t.array(ConcreteBundle),
        shipping_methods: t.array(BasketLinkShippingMethod),
    }),
]);

type InternalBasketLine = t.TypeOf<typeof InternalBasketLine>;

export type IBasketLineDiscount = t.TypeOf<typeof BasketLineDiscount>;

export const BasketLine = new t.Type<
    InternalBasketLine,
    InternalBasketLine,
    unknown
>(
    "BasketLine",
    InternalBasketLine.is,
    (val, context) => {
        return pipe(
            APIBasketLine.validate(val, context),
            chain((apiBasketLine) => {
                try {
                    const line: InternalBasketLine = {
                        ...apiBasketLine,
                        bundles: [],
                        shipping_methods: [],
                    };
                    return t.success(line);
                } catch (e) {
                    return t.failure(val, context);
                }
            }),
        );
    },
    (data) => data,
);

export const Voucher = t.interface({
    name: t.string,
    code: t.string,
    start_datetime: t.string,
    end_datetime: t.string,
});

export const OfferDiscount = t.intersection([
    t.type({
        index: t.number,
        name: t.string,
        short_name: t.string,
        description: t.string,
        amount: t.string,
        is_hidden: t.boolean,
    }),
    // These can be changed to mandatory after the r2024.08.20 release is in prod.
    t.partial({
        freq: t.number,
        desktop_image: nullable(ImageURL),
        mobile_image: nullable(ImageURL),
        benefit_type: nullable(t.string),
        benefit_value: nullable(t.string),
    }),
]);

export const VoucherDiscount = t.intersection([
    OfferDiscount,
    t.interface({
        voucher: Voucher,
    }),
]);

export const Discount = t.union([OfferDiscount, VoucherDiscount]);

export const Basket = t.intersection([
    t.interface({
        id: t.number,
        encoded_basket_id: t.string,
        owner: t.string,
        owner_id: t.number,
        last_modified_timestamp: nullable(t.string),
        status: t.string,
        lines: t.array(BasketLine),
        url: t.string,
        total_excl_tax: t.string,
        total_excl_tax_excl_discounts: t.string,
        total_incl_tax: t.string,
        total_incl_tax_excl_discounts: t.string,
        total_tax: t.string,
        currency: nullable(t.string),
        offer_discounts: t.array(OfferDiscount),
        voucher_discounts: t.array(VoucherDiscount),
        offer_post_order_actions: t.array(OfferDiscount),
        voucher_post_order_actions: t.array(VoucherDiscount),
        is_tax_known: t.boolean,
        tax_details: t.record(t.string, t.number),
        is_frozen: t.boolean,
        vouchers: t.array(Voucher),
        merged_from: t.array(t.number),
    }),
    t.partial({
        date_created: t.string,
        date_merged: t.string,
        date_submitted: t.string,
    }),
]);

export const WishListLine = t.interface({
    list_type: t.string,
    product: Product,
    url: t.string,
});
export const WishListLines = t.array(WishListLine);

export const BasketLastModifiedTimestamp = t.interface({
    id: t.number,
    last_modified_timestamp: nullable(t.string),
});

export const Addon = t.interface({
    productID: ProductID,
    quantity: t.number,
});

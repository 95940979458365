import React from "react";
import { IUser } from "../../../models/user.interfaces";

interface IProps {
    user: IUser | null;
}

export const CSRHeader = (props: IProps) => {
    if (!props.user || !props.user.is_csr) {
        return null;
    }
    return (
        <p>
            {interpolate(
                gettext(
                    "Welcome, %s. Please complete the checkout form with the information of the customer you are assisting.",
                ),
                [props.user.first_name || props.user.email],
            )}
        </p>
    );
};

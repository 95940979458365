import React from "react";
import classNames from "classnames";
import { isoProductID } from "../../../models/nominals";
import { IProduct } from "../../../models/catalogue.interfaces";
import { RatingGraphic } from "../../../common/RatingGraphic";
import { RichText } from "../../../common/RichText";
import { DiscountDisplayMode } from "../constants";
import { IModularConfiguratorOptionSet } from "../models.interfaces";
import { ConfiguratorProductPrice } from "../../../common/ConfiguratorProductPrice";
import { PriceType, getProductPrice } from "../../../utils/catalogue";
import { Image } from "../../../common/Image";

import styles from "./PanelRootProductSelectorOption.module.scss";

interface IProps {
    optionSet: IModularConfiguratorOptionSet;
    rootProduct: IProduct;
    selectedRootProduct: IProduct;
    onSelect: () => void;
    onClick: () => void;
    enableRatings: boolean;
}

interface IState {}

export class PanelRootProductSelectorOption extends React.PureComponent<
    IProps,
    IState
> {
    private readonly onChange = (e: React.FormEvent<HTMLInputElement>) => {
        e.preventDefault();
        this.props.onSelect();
    };

    private readonly onClick = (
        e: React.FormEvent<HTMLInputElement> | React.MouseEvent<Element>,
    ) => {
        e.preventDefault();
        this.props.onClick();

        if (e.currentTarget.className !== "stars-rating-link") {
            return;
        }

        // Scroll and focus on the next tick since closing the modal will change focus also. We
        // need to make sure this happens after that.
        setTimeout(() => {
            const reviews = document.querySelector<HTMLElement>(
                ".customer-review__button",
            );
            reviews?.scrollIntoView(true);
            reviews?.focus();
        }, 0);
    };

    private getImage() {
        if (this.props.rootProduct.images.length <= 0) {
            return null;
        }
        const image =
            this.props.rootProduct.images.length > 1
                ? this.props.rootProduct.images.find(
                      (img) => img.role === "PLP Config",
                  )
                : this.props.rootProduct.images[0];
        return image || null;
    }

    private getLowestPriceVariant() {
        // get lowest priced variant that is available to buy
        const variant = this.props.rootProduct.children.reduce((prev, curr) => {
            if (
                !prev.availability.is_available_to_buy &&
                curr.availability.is_available_to_buy
            ) {
                return curr;
            }
            if (!curr.availability.is_available_to_buy) {
                return prev;
            }
            const currPrice = getProductPrice(curr.price, {
                priceType: PriceType.COSMETIC_EXCL_TAX,
                includePostDiscountAddons: true,
                quantity: 1,
            });
            const prevPrice = getProductPrice(prev.price, {
                priceType: PriceType.COSMETIC_EXCL_TAX,
                includePostDiscountAddons: true,
                quantity: 1,
            });
            return currPrice.lessThan(prevPrice) ? curr : prev;
        }, this.props.rootProduct.children[0]);
        return variant;
    }

    private areAllVariantsOutOfStock() {
        const firstVariantAvailability =
            this.props.rootProduct.children[0].availability.is_available_to_buy;
        if (firstVariantAvailability) {
            return false;
        } else {
            return this.props.rootProduct.children.every(function (element) {
                return (
                    element.availability.is_available_to_buy ===
                    firstVariantAvailability
                );
            });
        }
    }

    private buildAboveRatingContent(): React.ReactNode {
        if (
            this.props.rootProduct.attributes
                .configurator_panel_above_rating_text
        ) {
            return (
                <RichText
                    html={`${this.props.rootProduct.attributes.configurator_panel_above_rating_text.value}`}
                />
            );
        }
        if (this.props.rootProduct.description) {
            return <RichText html={this.props.rootProduct.description} />;
        }
        return null;
    }

    private buildBelowRatingContent(): React.ReactNode {
        if (
            this.props.rootProduct.attributes
                .configurator_panel_below_rating_text
        ) {
            return (
                <RichText
                    html={`${this.props.rootProduct.attributes.configurator_panel_below_rating_text.value}`}
                />
            );
        }
        if (
            this.props.rootProduct.attributes.title_richtext &&
            this.props.rootProduct.attributes.title_richtext.value
        ) {
            return (
                <RichText
                    html={`${this.props.rootProduct.attributes.title_richtext.value}`}
                    tagName="h2"
                />
            );
        }
        return null;
    }

    render() {
        const optionSelected =
            this.props.rootProduct.id === this.props.selectedRootProduct?.id;
        const optionClass = classNames({
            [styles.radioOption]: true,
            [styles.activeOption]: optionSelected,
        });
        const optionContainerClass = classNames({
            "configurator__radio-option-container": true,
            "configurator__radio-option-container--option-model": true,
            "configurator__radio-option-container--disable":
                this.areAllVariantsOutOfStock(),
            [styles.optionContainer]: true,
        });
        const variant = this.getLowestPriceVariant();
        const image = this.getImage();
        return (
            <li>
                {this.props.rootProduct.attributes.tile_header &&
                    this.props.rootProduct.attributes.tile_header.value && (
                        <RichText
                            className="rich-text configurator__radio-option-container--tile-header"
                            html={`${this.props.rootProduct.attributes.tile_header.value}`}
                            tagName="h3"
                        />
                    )}
                <div className={optionContainerClass}>
                    <div className={styles.ratingContainer}>
                        {this.buildAboveRatingContent()}
                        {this.props.rootProduct.rating &&
                            this.props.enableRatings && (
                                <button
                                    onClick={this.onClick}
                                    className={styles.ratingButton}
                                >
                                    <RatingGraphic
                                        rating={this.props.rootProduct.rating}
                                        numReviews={
                                            this.props.rootProduct.num_reviews
                                        }
                                        onlyReviewsNum={true}
                                        cardClass={"panel-model"}
                                        cardSize={"small"}
                                        starRatingClass={styles.ratingStars}
                                        reviewNumberClass={styles.reviewNumber}
                                    />
                                </button>
                            )}
                    </div>
                    <input
                        type="radio"
                        id={`${this.props.rootProduct.id}`}
                        name={"option_model"}
                        onChange={this.onChange}
                        onClick={this.onClick}
                        value={isoProductID.unwrap(this.props.rootProduct.id)}
                        checked={optionSelected}
                    />
                    <label
                        htmlFor={`${this.props.rootProduct.id}`}
                        className={optionClass}
                        aria-selected={optionSelected}
                    >
                        <div className={styles.imgContainer}>
                            {image && (
                                <Image
                                    src={image.original}
                                    className="responsive-img"
                                    alt={this.props.rootProduct.title}
                                />
                            )}
                        </div>
                        <div className={styles.contentContainer}>
                            {this.buildBelowRatingContent()}
                            <ConfiguratorProductPrice
                                configuratorType={
                                    this.props.optionSet.layout.design
                                }
                                strikeThroughMSRP={false}
                                discountDisplayMode={
                                    DiscountDisplayMode.DISABLED
                                }
                                actualPriceStyle={""}
                                price={variant.price}
                                pricingMessage={gettext("Starting at")}
                                calloutCopy={
                                    this.props.rootProduct.attributes
                                        .product_callout?.value
                                }
                                actualPriceClass={styles.priceActual}
                                messageClass={styles.priceMessage}
                                retailPriceClass={styles.priceRetail}
                            />
                        </div>
                    </label>
                </div>
            </li>
        );
    }
}

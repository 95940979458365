import React from "react";
import ReactTooltip from "react-tooltip";
import SVG from "react-inlinesvg";
import { Trans } from "../../../common/Trans";

import styles from "./BasketHeader.module.scss";
import iconQuestionMarkCircleFilled from "../../../../img/icons/question-mark-circle-filled.svg";

interface IProps {
    hideBasketID: boolean;
    phoneLink: string | null;
    phoneNumber: string | null;
    basketID?: string;
    isMobile: boolean;
    totalItems: number;
}

interface IState {
    isKeyDown: boolean;
}

export class BasketHeader extends React.Component<IProps, IState> {
    public state: IState = {
        isKeyDown: false,
    };

    private getItemsLabel(qty: number) {
        return qty === 1 ? "item" : "items";
    }

    private readonly keyDownHandler = (
        event: React.KeyboardEvent<HTMLElement>,
    ) => {
        switch (event.key) {
            case "Escape":
                ReactTooltip.hide();
                break;
            case "Enter":
                if (!this.state.isKeyDown) {
                    this.setState({
                        isKeyDown: true,
                    });
                    return;
                }
                ReactTooltip.hide();
                break;
        }
    };

    private buildCartReferenceNumber(isMobile: boolean) {
        if (this.props.hideBasketID) {
            return null;
        }
        const tipId = "basket-lines__tooltip-cart-ref";
        return (
            <div className="basket-lines__header-cart-ref">
                <div className="basket-lines__header-cart-ref--first-line">
                    {gettext("Cart Reference Number:")}
                </div>
                <div className="basket-lines__header-cart-ref--second-line">
                    <span className="basket-lines__header-cart-ref--bold">
                        {this.props.basketID}
                    </span>
                    {this.props.phoneLink && this.props.phoneNumber && (
                        <div className="tooltip-container">
                            <div
                                tabIndex={0}
                                aria-describedby={tipId}
                                className="basket-lines__header-cart-ref-icon-container"
                                data-delay-update={100}
                                data-iscapture="true"
                                data-tip={true}
                                data-for={tipId}
                                data-delay-hide={200}
                                title={gettext(
                                    "Have an issue completing your order?",
                                )}
                                onKeyDown={(event) => {
                                    this.keyDownHandler(event);
                                }}
                                onMouseOver={() => {
                                    this.setState({ isKeyDown: false });
                                }}
                            >
                                <SVG
                                    aria-hidden="true"
                                    className="basket-lines__header-cart-ref-icon"
                                    src={iconQuestionMarkCircleFilled}
                                />
                                <span className="ada-screenreader-only">
                                    Open Tooltip
                                </span>
                            </div>
                            <ReactTooltip
                                aria-label={gettext(
                                    "Question about your order?",
                                )}
                                class="basket-lines__tooltip basket-lines__tooltip--cart-ref tooltip"
                                clickable={true}
                                event={"mouseover focus"}
                                eventOff="scroll mousewheel mouseleave mouseout blur"
                                id={tipId}
                                isCapture={true}
                                place={isMobile ? "left" : "bottom"}
                                role="tooltip"
                            >
                                <strong>
                                    {gettext(
                                        "Have an issue completing your order?",
                                    )}
                                </strong>
                                <br />
                                <Trans
                                    fmtString={gettext(
                                        "Call <PhoneNumber></PhoneNumber> and provide your cart ID for assistance.",
                                    )}
                                    data={{
                                        PhoneNumber: () => (
                                            <React.Fragment key="1">
                                                {this.props.phoneNumber}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            </ReactTooltip>
                        </div>
                    )}
                </div>
            </div>
        );
    }

    render() {
        return (
            <header className={styles.header}>
                <h1 className={styles.title}>
                    {gettext("Your Cart")}{" "}
                    <span className={styles.itemNumber}>
                        ({this.props.totalItems}{" "}
                        {this.getItemsLabel(this.props.totalItems)})
                    </span>
                </h1>
                {this.buildCartReferenceNumber(this.props.isMobile)}
            </header>
        );
    }
}

import React from "react";
import styles from "./CancelError.module.scss";

export const CancelError = () => {
    return (
        <p className={styles.root}>
            {gettext(
                "You are outside of the cancellation window. This order can no longer be cancelled or modified.",
            )}
        </p>
    );
};

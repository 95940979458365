import React from "react";
import Trans from "../../../common/Trans";
import { useAppDispatch } from "../../reducers";
import { closeModal } from "../reducers";

import styles from "./index.module.scss";

export const FinancingFullExistingCardholder = () => {
    const dispatch = useAppDispatch();
    return (
        <div className={styles.wrapper}>
            <h3 className={styles.title}>
                {gettext(
                    "Thank you for your interest in the Tempur-Pedic® Credit Card.",
                )}
            </h3>
            <p>
                <Trans
                    fmtString={gettext(
                        "Our systems indicate that you have an existing Fortiva Retail Credit account. To view your credit card number, please visit <A>www.myfortiva.com/my-account</A> and click “View Virtual Card.”",
                    )}
                    data={{
                        A: (content) => (
                            <a
                                href="https://www.myfortiva.com/my-account"
                                target="_blank"
                                rel="noreferrer"
                            >
                                {content}
                            </a>
                        ),
                    }}
                />
            </p>
            <button
                className="button"
                onClick={(e) => {
                    e.preventDefault();
                    dispatch(closeModal());
                }}
            >
                {gettext("Return to Shopping")}
            </button>
        </div>
    );
};
